const actionBarStyles = {
    label: {
        margin: 0,
        marginTop: 3,
        marginRight: 5,
        fontWeight: 'bold',
    }
};

export default actionBarStyles;
