/* eslint-disable consistent-return */
import hexColorRegex from 'hex-color-regex';

const $ = window.jQuery;

class ColorField {
    constructor() {
        this.$colorField = null;
        this.$colorInput = null;
    }

    validateHexCol($obj) {
        if (hexColorRegex({ strict: true }).test($obj.val()) && $obj.val().length === 7) {
            $obj.parent().find('.js-color-i-check').removeClass('fa-exclamation-triangle text-danger');
            $obj.parent().find('.js-color-i-check').addClass('fa-check-square text-success');
        } else {
            $obj.parent().find('.js-color-i-check').removeClass('fa-check-square text-success');
            $obj.parent().find('.js-color-i-check').addClass('fa-exclamation-triangle text-danger');
        }
    }

    normalizeColVal($obj) {
        const colVal = $obj.val();
        if (!colVal.includes('#')) {
            $obj.val(`#${$obj.val()}`);
        }
    }

    syncValues(mode, $obj) {
        this.normalizeColVal($obj);
        this.validateHexCol($obj);
        if (mode === 'fieldToInput') {
            $obj.parent().find('.js-color-input').val($obj.val());
        }
        if (mode === 'inputToField') {
            $obj.parent().find('.js-color-field').val($obj.val());
        }
    }

    init() {
        $(document).ready(() => {
            this.$colorField = $('.js-color-field');
            if (this.$colorField.length === 0) return false;

            this.$colorField.removeClass('form-control');
            this.$colorField.attr('style', `
                height: 31px !important;
                width: 31px;
                padding: 0;
                margin: 0;
                border: 1px;
                border-style: solid;
                background: none;
                line-height: 0;
                vertical-align: bottom;
            `);
            this.$colorField.parent().prepend('<i style="margin-left: -17px; margin-right: 6px;" class="js-color-i-check fas"></i>');
            this.$colorField.parent().prepend('<input style="width: auto; display: inline;" placeholder="#000000" class="js-color-input form-control" minlength="7" maxlength="7" type="text">');
            this.$colorInput = $('.js-color-input');

            this.$colorField.each((index, element) => {
                this.syncValues('fieldToInput', $(element));
            });

            this.$colorField.on('change', (e) => {
                this.syncValues('fieldToInput', $(e.currentTarget));
            });

            this.$colorInput.on('keyup', (e) => {
                this.syncValues('inputToField', $(e.currentTarget));
            });
        });
    }
}

export default new ColorField();
