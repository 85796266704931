const deleteModalStyles = {
    content: {
        top: '49%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -55%)',
        width: '90vw',
        maxWidth: '515px',
        height: '79%',
        maxHeight: '170px',
        padding: 0,
        boxShadow: '0px 0px 19px rgb(0 0 0 / 36%)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    overlay: {
        zIndex: 90,
        background: 'rgb(255 255 255 / 75%)',
        backdropFilter: 'blur(3px) grayscale(100%)',
        marginTop: 45,
    }
};

export default deleteModalStyles;
