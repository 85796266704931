// Import Regenerator Runtime
import 'regenerator-runtime/runtime';

// Import React
import React from 'react';
import { createRoot } from 'react-dom/client';

// Import Styles
import './index.scss';

// Import AdminBundle
import '../bundles/AdminBundle/js/outputListForConfigCreator';

// Import BusinessPartnerBundle
import MiofairContactPerson from '../bundles/BusinessPartnerBundle/js/miofair.contact.person';
import '../bundles/BusinessPartnerBundle/js/dataTableConfigs/business_partner/business_partner';

// Import ContractBundle
import MiofairOrderPosition from '../bundles/ContractBundle/js/miofair.order.position';
import Shipping from '../bundles/ContractBundle/js/order-shipping';
import ShowMessage from '../bundles/ContractBundle/js/miofair.contract.show_message';
import Search from '../bundles/ContractBundle/js/Search/Search';
import '../bundles/ContractBundle/js/datatable-fixed-header-scrollbar';
import Rejection from '../bundles/ContractBundle/js/rejection';
import '../bundles/ContractBundle/js/dataTableConfigs/contract/order_management/order_position';
import '../bundles/ContractBundle/js/dataTableConfigs/contract/order_management/contract';
import '../bundles/ContractBundle/js/dataTableConfigs/contract/order_management/ticket';
import '../bundles/ContractBundle/js/dataTableConfigs/contract/order';
import '../bundles/ContractBundle/js/dataTableConfigs/contract/order/order_position';
import '../bundles/ContractBundle/js/dataTableConfigs/contract/order_position';
import '../bundles/ContractBundle/js/dataTableConfigs/contract/order/order_position/ticket';
import '../bundles/ContractBundle/js/newOrderPosition';

// Import EventBundle
import EventChangeLegend from '../bundles/EventBundle/js/miofair.event.change_legend';
import EventBundleConfirmationButtons from '../bundles/EventBundle/js/confirmation_buttons';
import QuotaCalculation from '../bundles/EventBundle/js/miofair.event.quota_calculation';
import '../bundles/EventBundle/js/SubEventDetails/SubEventDetailsInit';

// Import LayoutBundle
import LoadingIndicatorMain from '../bundles/LayoutBundle/js/loading_indicator';
import SidebarMain from '../bundles/LayoutBundle/js/miofair.sidebar';
import FormsOrderPositions from '../bundles/LayoutBundle/js/forms';
import InfoArea from '../bundles/LayoutBundle/js/InfoArea';
import ToggleType from '../bundles/LayoutBundle/js/ToggleType';
import DataTableActions from '../bundles/LayoutBundle/js/DataTableActions/DataTableActions';
import ColorField from '../bundles/LayoutBundle/js/colorField';
import GeoField from '../bundles/LayoutBundle/js/geoField';
import '../bundles/LayoutBundle/js/EmailPills/EmailPills';

/* eslint-disable camelcase */
import DataTableActions_new from '../bundles/LayoutBundle/js/DataTableActions_new/DataTableActions_new';
/* eslint-enable camelcase */

import MiofairConfirm from '../bundles/LayoutBundle/js/miofair.confirm';
import QueryBuilder from '../bundles/LayoutBundle/js/querybuilder/querybuilder';
import '../bundles/LayoutBundle/js/scroll-to-top';
import '../bundles/LayoutBundle/js/dataTablesKeepFocus';
import '../bundles/LayoutBundle/js/SearchSelect/SearchSelectInit';

import '../bundles/LayoutBundle/js/MediaManager/MediaManagerModal';

import '../bundles/LayoutBundle/js/copyValueBtn';
import '../bundles/LayoutBundle/js/resetValueBtn';

// Import ProductBundle
import PriceCalculation from '../bundles/ProductBundle/js/miofair.product.price_calculation';
import CodePool from '../bundles/ProductBundle/js/miofair.product.codepool';
import ProductVariant from '../bundles/ProductBundle/js/miofair.product.variant';
import '../bundles/ProductBundle/js/dataTableConfigs/variant';

// OnlineShopBackOffice
import OnlineShopBackOffice from '../bundles/ShopApiBundle/js/miofair.shop.backoffice';
import '../bundles/ShopApiBundle/js/dataTableConfigs/online_shop/transaction';

// Import TemplateBundle
import '../bundles/TemplateBundle/js/PlaceholderTextarea/PlaceholderTextareaInit';
import '../bundles/TemplateBundle/js/CodeHighlightingTextarea/CodeHighlightingTextareaInit';
import '../bundles/TemplateBundle/js/removeInheritance';

// Utilities Bundle
import '../bundles/TransactionBundle/js/DataSheet/Init';

// Utilities Bundle
import '../bundles/UtilitiesBundle/js/PdfTest/PdfTest';
import '../bundles/UtilitiesBundle/js/copy_button';

// Init BusinessPartnerBundle
MiofairContactPerson.init();

// Init ContractBundle / OrderPosition address selects / Show Message after change contact Person
MiofairOrderPosition.init();
Shipping.init();
ShowMessage.init();
Rejection.init();
const reactHostSearchPosition = document.getElementById('js-search-position');
if (reactHostSearchPosition != null) {
    const root = createRoot(reactHostSearchPosition);
    root.render(<Search />);
}

// Init EventBundle
EventChangeLegend.init();
QuotaCalculation.init();
EventBundleConfirmationButtons.init();

// Init LayoutBundle
LoadingIndicatorMain.init();
SidebarMain.init();
FormsOrderPositions.init();
InfoArea.init();
window.DataTableActions = DataTableActions;
DataTableActions.init();

/* eslint-disable camelcase */
window.DataTableActions_new = DataTableActions_new;
DataTableActions_new.init();
/* eslint-enable camelcase */

MiofairConfirm.init();
QueryBuilder.init();
ColorField.init();
GeoField.init();
ToggleType.init();

// Init ProductBundle
PriceCalculation.init();
CodePool.init();
ProductVariant.init();

// Init ShopApiBundle
OnlineShopBackOffice.init();
