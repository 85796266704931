import $ from 'jquery';

const Translator = window.Translator;

export class MiofairOrderPosition {
    constructor() {
        /**
         * Set the JS selectors for further reference
         */
        this.orderPositionContainer = 'div.order-position-entry';
        this.orderPositionFreeAmountRowWrapper = 'div.js-order-position-free-amounts-row-wrapper';
        //
        this.fieldAmount = 'input.js-order-position-amount';
        this.fieldFreeAmountSum = 'input.js-order-position-free-amount-sum';
        //
        this.collectionFieldFreeAmount = 'input.js-order-position-free-amount';
        this.collectionButtonAdd = 'a.btn-secondary';
        this.collectionButtonDelete = 'a.btn-danger';
    }

    /**
     * The single-point-method which can be called from within the event handlers which will take care of
     * - calculating the freeAmount total
     * - enable/disable "free amount add" button
     *
     * @param {String} action Name of the action that occurred, either "update" or "delete".
     * @param {Object} element JQuery Object of the element that triggered the update/delete event.
     */
    onUpdate(action, element) {
        this.calculateFreeAmountSum(action, element);
        this.enableDisableButtons(element);
    }

    /**
     * Calculate a total over all existing FreeAmount-collection items; field freeAmount; and put the total into the
     * field named freeAmountSum.
     *
     * If the even-action is identified (applied) as 'delete', then get the value from the freeAmount field from the
     * to-be-deleted freeAmount collection item and subtract that amount from the freeAmountSum field on top of the form.
     *
     * If the event-action is identified (applied) as 'update', then calculate a total sum over all freeAmount
     * collection items; field freeAmount; and put the result into the freeAmountSum field on top of the form.
     *
     * @param {String} action Name of the action that occurred, either "update" or "delete".
     * @param {Object} element JQuery Object of the element that triggered the update/delete event.
     */
    calculateFreeAmountSum(action, element) {
        const orderPositionContainer = $(element).closest(this.orderPositionContainer);
        const fieldFreeAmountSum = $(orderPositionContainer).find(this.fieldFreeAmountSum);
        let total = 0;

        if (action === 'update') {
            $(orderPositionContainer).find(this.collectionFieldFreeAmount).each(function () {
                total += parseInt($(this).val(), 10) || 0;
            });
        } else if (action === 'delete') {
            const fieldDeletedFreeAmount = $(element).closest('.form-group').find(this.collectionFieldFreeAmount);
            total = $(fieldFreeAmountSum).val() - (parseInt($(fieldDeletedFreeAmount).val(), 10) || 0);
        }

        $(fieldFreeAmountSum).val(total);
    }

    /**
     * Based upon the values in the fields amount and freeAmountSum enable or disable any available
     * freeAmount-collection add-buttons.
     *
     * @param {Object} element JQuery Object of the element that triggered the update/delete event.
     */
    enableDisableButtons(element) {
        const orderPositionContainer = $(element).closest(this.orderPositionContainer);
        const fieldAmountSum = $(orderPositionContainer).find(this.fieldAmount);
        const fieldFreeAmountSum = $(orderPositionContainer).find(this.fieldFreeAmountSum);

        const amountSum = parseInt(fieldAmountSum.val(), 10) || 0;
        const freeAmountSum = parseInt(fieldFreeAmountSum.val(), 10) || 0;

        // remove any existing warning-message that might exist
        // $(orderPositionContainer).find('.invalid-feedback').each(function () {
        //     if (!$(this).closest('.form-group').hasClass('show-warnings')) {
        //         $(this).remove();
        //     }
        // });

        if (freeAmountSum > amountSum) {
            // add an error message if freeAmount exceeds amount!
            $(element).parent().append(`
                <div class="invalid-feedback">
                    <i class="fas fa-exclamation-triangle" aria-hidden="true"></i>
                    ${Translator.trans('contract.order_position.freeAmountLimit')}
                </div>`);
        }

        $(this.orderPositionFreeAmountRowWrapper).each(function () {
            if ($(this).find('.js-order-position-free-amount-readonly').length > 0) {
                $(this)
                    .find('a.order_position_freeAmounts-collection-remove')
                    .css('pointer-events', 'none')
                    .css('opacity', '0.4');
            }
        });
    }

    /**
     * init() is being called from within index.js and registers various events on DOM-elements.
     * Those elements are described below.
     */
    init() {
        const self = this;
        $(document).ready(() => {
            // register onchange event for "amount" field
            $(self.orderPositionContainer).on('change', self.fieldAmount, function () {
                self.onUpdate('update', $(this));
            });

            // in freeAmount collection: register onchange event for freeAmount field
            $(self.orderPositionContainer).on('change', self.collectionFieldFreeAmount, function () {
                self.onUpdate('update', $(this));
            });

            // in freeAmount collection: register click on the delete-button
            $(self.orderPositionContainer).on('click', self.collectionButtonDelete, function () {
                self.onUpdate('delete', $(this));
            });

            // in freeAmount collection: register click on the add-button
            $(self.orderPositionContainer).on('click', self.collectionButtonAdd, function () {
                self.onUpdate('update', $(this));
            });

            // fire a change-event please on page load in order to init button states.
            // do this by searching any existing container. for each container fetch the field "amount" and trigger
            // the update action, which will re-calculate and enable/disable add-buttons.
            $(document.body).find(self.fieldAmount).each(function () {
                $(this).trigger('change');
            });
        });
    }
}

export default new MiofairOrderPosition();
