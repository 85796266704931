import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isPending: false,
    configIsPending: false,
    errors: [],
    clientId: null,
    eventId: null,
    config: {
        variants: [],
    },
};

export const shellReducer = createSlice({
    name: 'shell',
    initialState,
    reducers: {
        setIsPending: (state, action) => {
            state.isPending = action.payload;
        },
        setConfigIsPending: (state, action) => {
            state.configIsPending = action.payload;
        },
        setConfig: (state, action) => {
            state.config = action.payload;
        },
        setErrors: (state, action) => {
            state.errors = action.payload;
        },
        setClientId: (state, action) => {
            state.clientId = action.payload;
        },
        setEventId: (state, action) => {
            state.eventId = action.payload;
        },
    },
});

export const {
    setIsPending,
    setConfigIsPending,
    setErrors,
    setErrorsFields,
    setClientId,
    setEventId,
    setConfig,
} = shellReducer.actions;

export default shellReducer.reducer;
