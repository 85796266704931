import React from 'react';
import { useSelector } from 'react-redux';

const Errors = () => {
    const errors = useSelector(state => state.shell.errors);

    if (!errors || !errors.global || errors.global.length === 0) {
        return null;
    }

    return (
        <div className="row">
            <div className="col">
                <div className="card border border-danger text-danger fw-bold">
                    <div className="card-header text-white bg-danger">
                        <i className="fas fa-exclamation-triangle" /> {window.Translator.trans('sub_event.errors.global_default')}
                    </div>
                    <div className="card-body">
                        <ul className="mb-0 pb-0">
                            {errors.global.map(error => (
                                <li key={error}>{window.Translator.trans(error)}</li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Errors;
