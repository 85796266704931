/* eslint-disable */
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    setDisplayMode
} from '../ActionBar/actionBarReducer';
import {
    deleteTimeTable,
    changeTimeTable,
    changeNewTimeTable,
    changeNewTimeTableCount,
    addNewTimeTable,
    addNewTimeTableByCount,
    changeAllTimeTables,
    setGlobalQuota,
} from './planningReducer';
import { setErrors } from '../SubEventDetailsShell/shellReducer';

const TimeTable = () => {
    const dispatch = useDispatch();
    const inEditMode = useSelector(state => state.actionBar.inEditMode);
    const timeTables = useSelector(state => state.planning.timeTables);
    const newTimeTable = useSelector(state => state.planning.newTimeTable);
    const newTimeTableCount = useSelector(state => state.planning.newTimeTableCount);
    const timeZone = useSelector(state => state.planning.timeZone);
    const errors = useSelector(state => state.shell.errors);
    const durationType = useSelector(state => state.planning.durationType);
    const globalQuota = useSelector(state => state.planning.globalQuota);
    const displayMode = useSelector(state => state.actionBar.displayMode);
    const isAliasVisible = useSelector(state => state.actionBar.isAliasVisible);

    return (
        <div>
            {durationType === 'multi_day' && (
                <div className="row mb-3">
                    <div className="col-3">
                        <span className="fw-bold">
                            {window.Translator.trans('sub_event.planning.quota')}
                        </span>
                    </div>
                    {inEditMode ? (
                        <React.Fragment>
                            <div className="col">
                                <input
                                    onChange={(e)=> {
                                        dispatch(changeAllTimeTables({key: 'quota', value: e.target.value}));
                                        dispatch(setGlobalQuota(e.target.value));
                                     }}
                                    value={globalQuota}
                                    className={`me-2 rounded d-block w-100 ${inEditMode ? 'w-100 border border-primary rounded' : 'border-0'}`}
                                    type="number"
                                    required
                                    min="0"
                                />
                            </div>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <div className="col">
                                <span>
                                    {timeTables.length > 0 && timeTables[0].quota}
                                </span>
                            </div>
                        </React.Fragment>
                    )}
                </div>
            )}

            <div className="row mb-3">
                <div className="col-3">
                        <span className="fw-bold">
                            {window.Translator.trans('sub_event.planning.displayMode')}
                        </span>
                </div>
                {inEditMode ? (
                    <React.Fragment>
                        <div className="col-4 pe-0">
                            <select
                                className="me-2 border border-primary rounded d-block w-100"
                                onChange={e => dispatch(setDisplayMode(parseInt(e.target.value, 10)))}
                                value={displayMode}
                            >
                                <option value="1"> {window.Translator.trans('sub_event.planning.displayModes.1')} </option>
                                <option value="4"> {window.Translator.trans('sub_event.planning.displayModes.4')} </option>
                                <option value="2"> {window.Translator.trans('sub_event.planning.displayModes.2')} </option>
                                <option value="3"> {window.Translator.trans('sub_event.planning.displayModes.3')} </option>
                            </select>
                        </div>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <div className="col-4 pe-0">
                            <span>
                                {window.Translator.trans(`sub_event.planning.displayModes.${displayMode}`)}
                            </span>
                        </div>
                    </React.Fragment>
                )}
            </div>
            <hr />
            <div className="row mb-3">
                <div className="col">
                    <span className="fw-bold">
                        {window.Translator.trans('sub_event.planning.times')}
                    </span>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col">
                    <table className="table bg-white m-b-0" style={{ zIndex: 9 }}>
                        <thead>
                            <tr><th style={{fontWeight: 'normal'}} width="40">{window.Translator.trans('sub_event.planning.active')}</th>
                                {isAliasVisible && (<th style={{fontWeight: 'normal'}}>{window.Translator.trans('sub_event.planning.alias')}</th>) }
                                <th style={{fontWeight: 'normal'}} width="75">{window.Translator.trans('sub_event.planning.date')}</th>
                                <th style={{fontWeight: 'normal'}} width="80">{window.Translator.trans('sub_event.planning.time')}</th>
                                <th style={{fontWeight: 'normal'}}>{window.Translator.trans('sub_event.planning.additional_info')}</th>
                                <th style={{fontWeight: 'normal'}} width="60">{window.Translator.trans('sub_event.planning.quota')}</th>
                                {!inEditMode && (
                                    <th style={{fontWeight: 'normal'}} width="60">{window.Translator.trans('sub_event.planning.quota_left')}</th>
                                )}
                                {inEditMode && (<th></th>)}
                            </tr>
                        </thead>
                        <tbody>
                            {timeTables.length > 0 ? timeTables.map((timeTable, idx) => (
                                <React.Fragment key={timeTable.uuid}>
                                    <tr>
                                        <td>
                                            {inEditMode ? (
                                                <input
                                                    checked={timeTable.enabled}
                                                    onChange={(e)=> dispatch(changeTimeTable({uuid: timeTable.uuid, key: 'enabled', value: e.target.checked}))}
                                                    className="w-100 border border-primary rounded"
                                                    type="checkbox"
                                                />
                                            ) : (
                                                <span title={timeTable.id}>{timeTable.enabled? (<i className="far fa-check-square"/>) : (<i className="far fa-square"/>)}</span>
                                            )}
                                        </td>
                                        {isAliasVisible ? (
                                            <td>
                                                {inEditMode ? (
                                                    <input
                                                        onChange={(e)=> dispatch(changeTimeTable({uuid: timeTable.uuid, key: 'alias', value: e.target.value}))}
                                                        value={timeTable.alias}
                                                        className="w-100 border border-primary rounded"
                                                        type="text"
                                                    />
                                                ) : (
                                                    timeTable.alias
                                                )}
                                            </td>
                                        ) : (
                                            ''
                                        )}
                                        <td>
                                            <input
                                                onChange={(e)=> dispatch(changeTimeTable({uuid: timeTable.uuid, key: 'date', value: e.target.value}))}
                                                value={timeTable.date}
                                                className={`me-2 rounded d-block w-100 ${inEditMode ? 'w-100 border border-primary rounded' : 'border-0'}`}
                                                type="date"
                                                required
                                                readOnly={!inEditMode}
                                                tabIndex={inEditMode ? 0 : -1}
                                            />
                                        </td>
                                        <td>
                                            {inEditMode ? (
                                                <React.Fragment>
                                                    <input
                                                        onChange={(e)=> dispatch(changeTimeTable({uuid: timeTable.uuid, key: 'start', value: e.target.value}))}
                                                        value={timeTable.start}
                                                        min="00:00"
                                                        max="23:59"
                                                        className="w-100 border border-primary rounded"
                                                        type="time"
                                                        required
                                                    />  -
                                                    <br />
                                                    <input
                                                        onChange={(e)=> dispatch(changeTimeTable({uuid: timeTable.uuid, key: 'end', value: e.target.value}))}
                                                        value={timeTable.end}
                                                        min={timeTable.start}
                                                        max="23:59"
                                                        className="w-100 border border-primary rounded"
                                                        type="time"
                                                        required
                                                    />
                                                </React.Fragment>
                                            ) : (
                                                <span>
                                                    {timeTable.start} -<br />
                                                    {timeTable.end}
                                                </span>
                                            )}
                                        </td>
                                        <td>
                                            {inEditMode ? (
                                                <input
                                                    onChange={(e)=> dispatch(changeTimeTable({uuid: timeTable.uuid, key: 'additional_info', value: e.target.value}))}
                                                    value={timeTable.additional_info}
                                                    className="w-100 border border-primary rounded"
                                                    type="text"
                                                />
                                            ) : (
                                                timeTable.additional_info
                                            )}
                                        </td>
                                        <td>
                                            {inEditMode && durationType === 'day' ? (
                                                <input
                                                    onChange={(e)=> dispatch(changeTimeTable({uuid: timeTable.uuid, key: 'quota', value: e.target.value}))}
                                                    value={timeTable.quota}
                                                    className="w-100 border border-primary rounded"
                                                    type="number"
                                                    min="0"
                                                />
                                            ) : (
                                                timeTable.quota
                                            )}
                                        </td>
                                        {!inEditMode && (
                                            <td>
                                                {timeTable.quota_left}
                                            </td>
                                        )}

                                        {inEditMode && (
                                            <td>
                                                <button
                                                    className="btn text-danger "
                                                    onClick={() => {
                                                        dispatch(deleteTimeTable(timeTable.uuid));
                                                        dispatch(setErrors([]));
                                                    }}
                                                > <i className="fas fa-trash-alt" />
                                                </button>
                                            </td>
                                        )}
                                    </tr>
                                    {errors[`sub_event_plannings[${idx}]`] && (
                                        <tr>
                                            <td colSpan="6" className="p-l-0 p-r-0 p-t-0">
                                                <div className="card border border-danger text-danger fw-bold">
                                                    <div className="card-header text-white bg-danger">
                                                        <i className="fas fa-exclamation-triangle" /> {window.Translator.trans('sub_event.errors.global_default')}
                                                    </div>
                                                    <div className="card-body">
                                                        <ul style={{paddingLeft: '15px'}}>
                                                            {errors[`sub_event_plannings[${idx}]`].start_date && (
                                                                <li>
                                                                    {window.Translator.trans(errors[`sub_event_plannings[${idx}]`].start_date)}
                                                                </li>
                                                            )}
                                                            {errors[`sub_event_plannings[${idx}]`].end_date && (
                                                                <li>
                                                                    {window.Translator.trans(errors[`sub_event_plannings[${idx}]`].end_date)}
                                                                </li>
                                                            )}
                                                            {errors[`sub_event_plannings[${idx}]`].alias && (
                                                                <li>
                                                                    {window.Translator.trans(errors[`sub_event_plannings[${idx}]`].alias)}
                                                                </li>
                                                            )}
                                                            {errors[`sub_event_plannings[${idx}]`].additional_info && (
                                                                <li>
                                                                    {window.Translator.trans(errors[`sub_event_plannings[${idx}]`].additional_info)}
                                                                </li>
                                                            )}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    )}
                                </React.Fragment>
                            )) : (
                                <tr>
                                    <td colSpan="4" className="font-italic">
                                        {window.Translator.trans('sub_event.planning.please_add_plannings')}
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>

            {inEditMode && (
                <React.Fragment>
                    <div className="row mb-3">
                        <div className="col-3">
                            <span className="fw-bold">
                                Zeitzone
                            </span>
                        </div>
                        <div className="col">
                            {timeZone}
                        </div>
                    </div>

                    <div className="row mb-3">
                        <div className="col-3">
                            <span className="fw-bold">
                                {window.Translator.trans('sub_event.planning.date')}
                            </span>
                        </div>
                        <div className="col-3">
                            <input
                                onChange={(e)=> dispatch(changeNewTimeTable({key: 'startDate', value: e.target.value}))}
                                value={newTimeTable.startDate}
                                className="w-100 border border-primary rounded"
                                type="date"
                            />
                        </div>
                        <div className="col-3">
                            <input
                                onChange={(e)=> dispatch(changeNewTimeTable({key: 'endDate', value: e.target.value}))}
                                value={newTimeTable.endDate}
                                className="w-100 border border-primary rounded"
                                type="date"
                            />
                        </div>

                        <div className="col-3">
                            <button
                                onClick={()=> {
                                    dispatch(addNewTimeTable());
                                    dispatch(setErrors([]));
                                }}
                                className="btn btn-primary btn-sm mb-2 float-end"
                            >
                                <i className="fa fa-plus" />  {window.Translator.trans('sub_event.planning.ad_planning')}
                            </button>
                        </div>
                    </div>


                    <div className="row mb-3">
                        <div className="col-3">
                            <span className="fw-bold">
                                {window.Translator.trans('sub_event.planning.add_amount')}
                            </span>
                        </div>
                        <div className="col-6">
                            <input
                                onChange={(e)=> dispatch( changeNewTimeTableCount(e.target.value))}
                                value={newTimeTableCount}
                                min={0}
                                max={99}
                                className="w-100 border border-primary rounded"
                                type="number"
                            />
                        </div>

                        <div className="col-3">
                            <button
                                onClick={()=> {
                                    dispatch(addNewTimeTableByCount());
                                    dispatch(setErrors([]));
                                }}
                                className="btn btn-primary btn-sm mb-2 float-end"
                            >
                                <i className="fa fa-plus" />  {window.Translator.trans('sub_event.planning.ad_planning')}
                            </button>
                        </div>
                    </div>

                </React.Fragment>
            )}
            <hr />
        </div>
    );
};

export default TimeTable;
