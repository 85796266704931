/* eslint-disable consistent-return */
import createToolbarButtons from './dTcreateToolbarButtons';
import createDateRangeSearch from './dTcreateDateRangeSearch';
import createNumberRangeSearch from './dTcreateNumberRangeSearch';
import createToolTips from './dTcreateToolTips';
import focusRow from './dTfocusRow';
import dTkeepInputFocus from './dTkeepInputFocus';
import htmlspecialchars from './../js/utils/htmlspecialchars';
import { detailedDiff } from 'deep-object-diff';

const $ = window.jQuery;

const Translator = typeof window.Translator !== 'undefined' ? window.Translator : { trans: key => key };
const Routing = typeof window.Routing !== 'undefined' ? window.Routing : { generate: route => route };

const dataTablesMainInit = (dTsettings) => {
    $(document).ready(() => {
        // Hide event column if we are in an event context and the event column is present.
        const eventColumn = dTsettings.columns.columns.find(column => column.name === 'event');
        if (typeof eventColumn !== 'undefined') {
            eventColumn.visible = window.miofair.currentEventId === 0;
        }
        const currenUrl = new URL(window.location.href);
        const urlKey = currenUrl.href.replace(currenUrl.origin + '/', ''); 
        let dataTable;

        let savedChangesCounter = parseInt(sessionStorage.getItem(`${urlKey}_DataTables_${dTsettings.tableName}_amount_of_changes`), 10) || 0;

        const defaults = {
            stickyHeader: true,
            keys: true,
            dom: `<'row' <'col' l<"js-${dTsettings.tableName}-toolbar"> fr > > <'row' <'col' <'scroll-container' t > ip > >`,
            colReorder: {
                fixedColumnsLeft: 1 /* = checkbox col. not reorderable */
            },

            stateSave: true,
            stateSaveCallback(set, newState) {
                // Init clean version of the state to compare with later
                const cleanState = JSON.parse(sessionStorage.getItem(`${urlKey}_DataTables_${set.sInstance}_clean_state`));

                if (cleanState === null) {
                    sessionStorage.setItem(`${urlKey}_DataTables_${set.sInstance}_clean_state`, JSON.stringify(newState));
                } else{
                    const diffDataDetailed = detailedDiff(cleanState.columns, newState.columns);

                    const columns = diffDataDetailed.updated;                    ;

                    const amountOfChanges = Object.keys(columns).length || 0;
                    if (amountOfChanges > 0){
                        sessionStorage.setItem(`${urlKey}_DataTables_${set.sInstance}_changed_state`, JSON.stringify(newState));

                        const newAmountOfChanges = savedChangesCounter > 0 ? savedChangesCounter : amountOfChanges;
                        sessionStorage.setItem(`${urlKey}_DataTables_${set.sInstance}_amount_of_changes`, newAmountOfChanges);

                        $(`.js-actives-filters-count-${dTsettings.tableName}`).text(newAmountOfChanges);
                        $(`.js-reset-${dTsettings.tableName}`).removeClass('disabled').removeClass('btn-secondary').addClass('btn-primary');
                    } else {
                        sessionStorage.setItem(`${urlKey}_DataTables_${set.sInstance}_amount_of_changes`, 0);
                        $(`.js-actives-filters-count-${dTsettings.tableName}`).text(0);
                    }
                }

               
            },
            stateLoadCallback(set) {
                const ret = JSON.parse(sessionStorage.getItem(`${urlKey}_DataTables_${set.sInstance}_changed_state`));
                return ret;
            },
        };
        const language = {
            language: {
                sEmptyTable: 'Keine Daten in der Tabelle vorhanden',
                sInfo: '_START_ bis _END_ von _TOTAL_ Einträgen',
                sInfoEmpty: '0 bis 0 von 0 Einträgen',
                sInfoFiltered: '(gefiltert von _MAX_ Einträgen)',
                sInfoPostFix: '',
                sInfoThousands: '.',
                sLengthMenu: '_MENU_ Einträge anzeigen',
                sLoadingRecords: 'Wird geladen...',
                sProcessing: 'Bitte warten...',
                sSearch: '',
                sSearchPlaceholder: 'Suche',
                sZeroRecords: 'Keine Einträge vorhanden.',
                oPaginate: {
                    sFirst: 'Erste',
                    sPrevious: 'Zurück',
                    sNext: 'Nächste',
                    sLast: 'Letzte'
                },
                oAria: {
                    sSortAscending: ': aktivieren, um Spalte aufsteigend zu sortieren',
                    sSortDescending: ': aktivieren, um Spalte absteigend zu sortieren'
                },
            }
        };

        const ajax = {
            serverSide: true,
            ajax: $.fn.dataTable.pipeline({
                type: 'POST',
                pages: 20,
            }),
        };

        const renderFilters = () => {
            $(dataTable.header()).find('.filter_row').remove();
            $('<tr class="filter_row"/>')
                .appendTo(dataTable.header())
            ;
            const dtFilters = window.dataTableInstances[dTsettings.tableName].filter;
            const dtColumns = dTsettings.columns.columns;

            dataTable.colReorder.order().forEach((columnIndex) => {
                const column = dataTable.column(dataTable.colReorder.transpose(columnIndex, 'toCurrent'));

                if (!column.visible()) {
                    return;
                }

                const filterColumn = $('<th/>').appendTo($(dataTable.header()).find('.filter_row'));

                if (typeof dtFilters[dtColumns[columnIndex].name] === 'undefined') {
                    return;
                }

                const filter = dtFilters[dtColumns[columnIndex].name];
                let filterElement;

                const styles = {
                    standardInput: /* css */`
                        min-width: 80px;
                    `,
                };

                if (filter.type === 'term') {
                    filterElement = $(`<select class="form-control form-control-sm" style="${styles.standardInput}"/>`)
                        .on('change', function () {
                            column
                                .search($(this).val())
                                .clearPipeline()
                                .draw();
                        })
                    ;
                    $('<option value=""/>')
                        .html(Translator.trans('filter.all', 'datatable'))
                        .appendTo(filterElement);
                }
                if (filter.type === 'terms') {
                    filterElement = $(`<select multiple="multiple" class="multiselect" style="${styles.standardInput}"/>`)
                        .on('change', function () {
                            column
                                .search($(this).val().join(','))
                                .clearPipeline()
                                .draw();
                        })
                    ;
                }
                if (filter.type === 'query' || filter.type === 'float') {
                    let filterTimeout = '';
                    filterElement = $(`<input type="text" id="${filter.name}" class="form-control form-control-sm" style="${styles.standardInput}"/>`)
                        .attr('placeholder', $(column.header()).text())
                        .attr('value', filter.value)
                        .on('keyup change', function (event) {
                            if (event.type === 'keyup' && (
                                event.keyCode === 37 /* arrow left */
                                || event.keyCode === 38 /* arrow up */
                                || event.keyCode === 39 /* arrow right */
                                || event.keyCode === 40 /* arrow down */
                                || event.keyCode === 16 /* shift */
                                || event.keyCode === 17 /* ctrl */
                                || event.keyCode === 18 /* alt */
                                || event.keyCode === 36 /* pos1 */
                                || event.keyCode === 35 /* end */
                                || event.keyCode === 9 /* tab */
                            )) {
                                return;
                            }

                            // needed to free arrow keys from table and make usabele in input, in case a row was focused
                            dataTable.cell.blur();

                            const $inputEle = $(this);

                            let minCharsForSearch = 3;
                            if ($inputEle.attr('id').includes('product_no')) {
                                minCharsForSearch = 2;
                            }

                            if ($inputEle.val().length > 0 && $inputEle.val().length < minCharsForSearch) {
                                return;
                            }

                            // debounce search
                            clearTimeout(filterTimeout);
                            filterTimeout = setTimeout(() => {
                                column
                                    .search($inputEle.val())
                                    .clearPipeline()
                                    .draw();
                            }, 1300);
                        })
                    ;
                }
                if (filter.type === 'date_range') {
                    filterElement = $(/* html */`
                        <div
                            data-from-display="${filter.from_display}"
                            data-to-display="${filter.to_display}"
                            data-col-idx="${column.index()}"
                            class="js-date-range-search"
                        />`
                    );
                }

                if (filter.type === 'range') {
                    filterElement = $(/* html */`
                        <div
                            data-min-display="${filter.min_display}"
                            data-max-display="${filter.max_display}"
                            data-col-idx="${column.index()}"
                            class="js-number-range-search"
                        />`
                    );
                }

                if (filterElement !== undefined) {
                    filterElement.appendTo(filterColumn);
                }

                if (filter.type === 'term' || filter.type === 'terms') {
                    if (filter.name === 'country') {
                        filter.values.sort((country, countryToCompare) => {
                            if (country.value < countryToCompare.value) {
                                return -1;
                            }
                            return 1;
                        });
                    }
                    $.each(filter.values, (index, data) => {
                        let translatedValue = data.value;
                        let inputValue = data.identifier;

                        if (data.value === 'true') {
                            inputValue = data.value;
                            translatedValue = Translator.trans('filter.yes', 'datatable');
                        } else if (data.value === 'false') {
                            inputValue = data.value;
                            translatedValue = Translator.trans('filter.no', 'datatable');
                        } else if (data.value !== null
                            && data.value !== ''
                            && typeof dtColumns[columnIndex].translationPrefix !== 'undefined'
                            && dtColumns[columnIndex].translationPrefix !== null
                            && dtColumns[columnIndex].translationPrefix !== ''
                        ) {
                            const translationKey = dtColumns[columnIndex].translationPrefix + data.value;
                            const tmpTranslatedValue = Translator.trans(translationKey, 'datatable');
                            if (translationKey !== tmpTranslatedValue) {
                                translatedValue = tmpTranslatedValue;
                            }
                        }

                        const option = $('<option/>')
                            .attr('value', inputValue)
                            .html(translatedValue)
                        ;

                        if (data.is_active) {
                            option.attr('selected', 'selected');
                        }

                        option.appendTo(filterElement);
                    });
                }
            });

            $('select.multiselect').multiselect({
                nonSelectedText: Translator.trans('filter.multiselect.none_selected', 'datatable'),
                numberDisplayed: 1
            });

            createDateRangeSearch(dTsettings.tableName);
            createNumberRangeSearch(dTsettings.tableName);
        };

        const callbacks = {
            drawCallback: function draw() {
                renderFilters();
                window.DataTableActions_new.dataTableDrawCallBack();
                dTkeepInputFocus();
            },
            initComplete() {
                $('[data-bs-toggle="popover"]').popover({ html: true, trigger: 'hover' });

                $(`.js-actives-filters-count-${dTsettings.tableName}`).text(savedChangesCounter);

            },
        };

        function createDatatable(language) {
            $.extend(defaults, language);
            $.extend(defaults, ajax);
            $.extend(defaults, dTsettings.options);
            $.extend(defaults, callbacks);
            $.extend(defaults, dTsettings.extensions);
            $.extend(defaults, dTsettings.columns);

            if (!$.fn.dataTable.isDataTable(dTsettings.selector)) {
                dataTable = $(dTsettings.selector)
                    .DataTable(defaults)
                    .on('draw.dt', () => {
                        createToolTips(dTsettings.columns);
                    })
                ;

                if (typeof window.dataTableInstances === 'undefined') {
                    window.dataTableInstances = {};
                }
                window.dataTableInstances[dTsettings.tableName] = { api: dataTable };

                if (typeof dTsettings.multiActions !== 'undefined') {
                    const multiActions = [...dTsettings.multiActions];
                    if (Array.isArray(multiActions)) {
                        window.dataTableInstances[dTsettings.tableName].multiActions = multiActions.map((a) => {
                            a.actionTitle = Translator.trans(a.actionTitle);
                            a.actionProceedUrl = Routing.generate(a.actionProceedUrl);
                            a.actionValidateUrl = Routing.generate(a.actionValidateUrl);
                            return a;
                        });
                    }
                }

                if (typeof dTsettings.options.columnFilters !== 'undefined' && dTsettings.options.columnFilters === false) {
                    return;
                }

                dataTable.on('column-visibility.dt', () => {
                    renderFilters();
                });
                dataTable.on('xhr.dt', (e, settings, json) => {
                    if (typeof window.dataTableInstances[dTsettings.tableName] !== 'undefined') {
                        window.dataTableInstances[dTsettings.tableName].filter = { ...json.filter };
                    }
                });

                const $globalSearchInput = $('.dataTables_filter input');

                $globalSearchInput.addClass('h-no-clear');

                $(/* HTML */`
                    <span class="js-dt__reset-global-search btn btn-primary h-cursor-pointer m-l-5"
                        style="vertical-align: top;"
                        title="Suche zurücksetzen"
                    >
                        <i class="fas fa-times"></i>
                    </span>
                `).insertAfter($globalSearchInput);

                $(/* HTML */`
                    <span class="js-dt__trigger-global-search btn btn-primary h-cursor-pointer" style="vertical-align: top;">
                        <i class="fas fa-search"></i>
                    </span>
                `).insertAfter($globalSearchInput);


                $globalSearchInput.attr('style', 'height: 32px');
                // unbind because general over all filter should trigger on pressing enter instead of change
                $globalSearchInput.unbind();
                $globalSearchInput.on('keyup', function (e) {
                    if (e.keyCode === 13) {
                        dataTable.search(this.value).draw();
                    }
                });

                $('.js-dt__trigger-global-search').on('click', () => {
                    dataTable.search($globalSearchInput.val()).draw();
                });

                $('.js-dt__reset-global-search').on('click', () => {
                    if ($globalSearchInput.val().trim() !== '') {
                        $globalSearchInput.val('');
                        dataTable.search('').draw();
                    }
                });
            }

            createToolbarButtons(dTsettings.tableName, dataTable, language);

            if (dTsettings.keys === true) {
                focusRow(dataTable);
            }
        }

        createDatatable(language);
    });

    if (dTsettings.stickyHeader === true) {
        $(dTsettings.selector).addClass('has-sticky-header');
    }
};

/* since we get dirty data from some third party noobs, we have to clean it our self's to prevent html rendering */
export const renderWithoutHTML = (string, type) => {
    if (type !== 'display' || string === null) {
        return string;
    }

    return htmlspecialchars(string);
};

export function renderLink(data, type) {
    if (type !== 'display' || data === null || typeof data !== 'object') {
        return data;
    }

    if (typeof data.link !== 'undefined' && typeof data.name !== 'undefined') {
        return `<a href="${data.link}">${data.name}</a>`;
    }

    if (typeof data.name !== 'undefined') {
        return data.name;
    }

    return '';
}

export default dataTablesMainInit;
