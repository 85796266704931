import React from 'react';
import { createRoot }from 'react-dom/client';
import SearchSelectApp from './SearchSelectApp';
import { v4 as uuidv4 } from 'uuid';

const hostEles = document.querySelectorAll('.js-search-select__container');
if (hostEles.length > 0) {
    const allSearchSelects = [];

    hostEles.forEach((hostEle) => {
        const configData = JSON.parse(hostEle.dataset.config);
        const inputWrapper = hostEle.querySelectorAll('.js-search-select__input')[0];
        configData.inputEle = inputWrapper.querySelectorAll('input')[0];
        const isRequired = configData.inputEle.required;
        const uuid = uuidv4();
        allSearchSelects.push({ uuid, isRequired });
    });

    hostEles.forEach((hostEle, i) => {
        const appHost = hostEle.querySelectorAll('.js-search-select__host')[0];
        const configData = JSON.parse(hostEle.dataset.config);
        const inputWrapper = hostEle.querySelectorAll('.js-search-select__input')[0];
        configData.inputEle = inputWrapper.querySelectorAll('input')[0];
        configData.formBtnNext = document.querySelector('button[type="submit"]');
        configData.isRequired = configData.inputEle.required;
        configData.uuid = allSearchSelects[i].uuid;
        configData.allSearchSelects = allSearchSelects;

        if (typeof configData === 'undefined') {
            console.error('SEARCH SELECT FIELDS: "PLEASE PROVIDE THE CONFIG DATA-ATTR !!!"');
        } else {
            const root = createRoot(appHost);
            root.render(
                <SearchSelectApp config={configData} />
            );
        }
    });
}