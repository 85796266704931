/* like the php function of the same name it
displays strings with html as normal text instead of rendering the html */

const htmlspecialchars = str =>
    String(str)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;')
;

export default htmlspecialchars;
