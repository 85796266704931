/* eslint-disable */
export class Cookie {
	create (name,value,days) {
		if (days) {
			var date = new Date();
			date.setTime(date.getTime()+(days*24*60*60*1000));
            var expires = "; expires="+date.toGMTString();
		} else {
			var expires = "";
        }
		document.cookie = name + "=" + value + expires + "; path=/";
	}
	read (name) {
		var nameEQ = name + "=";
		var ca = document.cookie.split(';');
		for(var i=0;i < ca.length;i++) {
			var c = ca[i];
			while (c.charAt(0)==' ') c = c.substring(1,c.length);
			if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
		}
		return null;
	}
	erase (name) {
		this.create(name,"",-1);
	}
}

/**
 * Export an instance of this class as default for easy use of this es6 module.
 * If needed one is still able to import the class itself as well, since it's "export"
 * flagged to.
 */
export default new Cookie();
