import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import CodeMirror from '@uiw/react-codemirror';
import { html as htmlLang } from "@codemirror/lang-html";
import { EditorView } from "@codemirror/view";

const copyBtnTxt = window.Translator.trans('template_bundle.placeholder_textarea.copy_value');

const Button = styled.span.attrs(props => ({
    className: props.disabledBtn ? 'btn btn-secondary disabled btn-sm' : 'btn btn-primary btn-sm',
    role: 'button'
})
)`
    margin-right: 5px;
    margin-bottom: 5px;
    &:last-child {
        margin-right: 0;
    }
`;

const styleWrapper = {
    background: '#F6F8F9',
    padding: '5px',
    borderTadius: '5px',
    borderColor: 'rgba(0, 0, 0, 0.125)',
};

const noAutoEscapeTagList = [
    'header',
    'footer',
    'styles',
    'mail_body'
];

const PlaceholderEditor = (props)=> {
    const [editorValue, setEditorValue] = useState('');
    const [editorBlurred, setEditorBlurred] = useState('');
    const [placeholders, setPlaceholders] = useState([]);
    const [copyValue, setCopyValue] = useState(null);
    const [labelValue, setLabelValue] = useState(null);
    const [editorIsExpanded, setEditorIsExpanded] = useState(false);

    const editorRef = useRef(null);

    const getConfig = () => {
        const confDataParsed = JSON.parse(decodeURIComponent(props.config));
        const copyValueProp = props.copyValue;
        const labelValueProp = props.labelValue;
        setPlaceholders(confDataParsed.placeholders);
        if (copyValueProp) {
            setCopyValue(copyValueProp);
        }
        if (labelValueProp) {
            setLabelValue(labelValueProp);
        }
    }

    useEffect(()=> {
        getConfig();
        setEditorValue(props.textareaEle.value);
    }, []);

    useEffect(()=> {
        props.textareaEle.value = editorValue;
    }, [editorValue]);


    const handlePlaceholderClick = (tag) => {
        const cursorPosition = editorRef.current.view.state.selection.main.head;
        const view = editorRef.current.view;

        let newVal = `{{${tag}}}`;
        if (noAutoEscapeTagList.includes(tag)) {
            newVal = `{% autoescape false %}{{${tag}}}{% endautoescape %}`;
        }

        // little hack, since in the moment of the click, the button gets focused, and the editor loses focus
        setTimeout(() => {
            editorRef.current.view.focus();
        }, 100);

        view.dispatch({
            changes: { 
                from: cursorPosition, 
                to: cursorPosition, 
                insert: newVal },
            selection: {
                anchor: cursorPosition + newVal.length,
                head: cursorPosition + newVal.length,
              },
          });
    }

    const editorOnChange = React.useCallback((val, viewUpdate) => {
        setEditorValue(val);
        props.textareaEle.value = val;
      }, []);
    
    return (
        <div style={styleWrapper}>

            {placeholders.length > 0 && placeholders.map(placeholder => (
                <Button
                    disabledBtn={editorBlurred}
                    onMouseDown={evt => handlePlaceholderClick(placeholder.value)}
                    key={placeholder.value}
                    title={`{{${placeholder.value}}}`}
                >
                    {placeholder.label}
                </Button>
            ))}
            
            <CodeMirror 
                value={editorValue} 
                maxHeight={editorIsExpanded ? 'auto' : '400px'} 
                minHeight='200px' 
                ref={editorRef} 
                extensions={[
                    htmlLang(), 
                    EditorView.lineWrapping
                ]}
                onBlur={() => setEditorBlurred(true)}
                onFocus={() => setEditorBlurred(false)}
                onChange={editorOnChange}
                /* HTML placeholder like attribute, NOT the placeholders this component is about! ;) */
                placeholder={labelValue}
            />
                
            <div className="clearfix">
                {copyValue !== null && (
                    <span role="button" 
                        tabIndex={-1} 
                        onClick={() => setEditorValue(copyValue)} 
                        className="btn btn-sm btn-primary">
                            <i className="fas fa-angle-up" /> {copyBtnTxt}
                    </span>
                )} 

                <span role="button" 
                    tabIndex={-2}
                    onClick={() => setEditorIsExpanded(!editorIsExpanded)} 
                    className="btn btn-outline-secondary btn-sm float-end">
                        <i className={`fas ${editorIsExpanded ? 'fa-minimize' : 'fa-maximize'}`} />
                </span>
            </div>

        </div>
    );
    
}

export default PlaceholderEditor;
