/**
 * Import libraries and dependendies to use in this JS class
 */
import $ from 'jquery';

export class PriceCalculation {
    constructor() {
        /**
         * Set the CSS class names for further reference
         */
        this.selectorVatFactor = '.js-calculation-vat-factor';
        this.selectorGrossAmount = '.js-calculation-gross-amount';
        this.selectorNetAmount = '.js-calculation-net-amount';
        this.selectorInlineEditNet = '.form-inline-editing-text#netAmount';
        this.currencyFormat = 'de-DE';

        this.selectorAlternativeGrossAmount = '.js-calculation-alternative-gross-amount';
        this.selectorAlternativeNetAmount = '.js-calculation-alternative-net-amount';
        this.selectorAlternativeSapArticleNo = '.js-alternative-sap-article-no';
        this.selectorAlternativeAmountSwitch = '.js-alternative-amount-switch';
        this.selectorAlternativeAmountDay = '.js-alternative-amount-day';
        this.selectorAlternativeWrapper = '.js-alternative-amount-group';

        this.selectorFlatrateGrossAmount = '.js-calculation-flatrate-gross-amount';
        this.selectorFlatrateNetAmount = '.js-calculation-flatrate-net-amount';
        this.selectorFlatrateAmountSwitch = '.js-flatrate-amount-switch';
        this.selectorFlatrateWrapper = '.js-flatrate-amount-group';
        this.selectorFlatrateSapArticleNo = '.js-flatrate-sap-article-no';

        this.initTmpNetValue();
    }

    /**
     * Check are classes for price calculation available
     */
    init() {
        $(document).ready(() => {
            this.handleAlternativeAmountSwitchChange();
            this.handleFlatrateAmountSwitchChange();

            // check if our js-classes exist in dom. otherwise exit.
            if ($(this.selectorVatFactor).length > 0 && $(this.selectorGrossAmount).length > 0 && $(this.selectorNetAmount).length > 0) {
                // calculate the net amount of the inherited gross amount
                this.calculateInheritedNet();
                // register change events on form fields vat, gross and net
                this.registerEvents();
                // trigger an initial re-calculation
                this.updateAllNet();
            }
        });
    }

    /**
     * register events on VatID-Select, net-input and gross-input
     */
    registerEvents() {
        $(this.selectorVatFactor).on('change keyup blur', () => {
            this.updateAllNet();
        });

        $(this.selectorGrossAmount).on('change keyup blur', () => {
            this.updateNet(this.selectorGrossAmount, this.selectorNetAmount);
        });

        $(this.selectorNetAmount).on('change keyup blur', () => {
            this.updateGross(this.selectorGrossAmount, this.selectorNetAmount);
        });

        $(this.selectorAlternativeGrossAmount).on('change keyup blur', () => {
            this.updateNet(this.selectorAlternativeGrossAmount, this.selectorAlternativeNetAmount);
        });

        $(this.selectorAlternativeNetAmount).on('change keyup blur', () => {
            this.updateGross(this.selectorAlternativeGrossAmount, this.selectorAlternativeNetAmount);
        });

        $(this.selectorFlatrateGrossAmount).on('change keyup blur', () => {
            this.updateNet(this.selectorFlatrateGrossAmount, this.selectorFlatrateNetAmount);
        });

        $(this.selectorFlatrateNetAmount).on('change keyup blur', () => {
            this.updateGross(this.selectorFlatrateGrossAmount, this.selectorFlatrateNetAmount);
        });

        $(this.selectorAlternativeAmountSwitch).on('change', this.handleAlternativeAmountSwitchChange.bind(this));
        $(this.selectorFlatrateAmountSwitch).on('change', this.handleFlatrateAmountSwitchChange.bind(this));
        $(document).on('inlineEditingReset', this.updateAllNet.bind(this));
    }

    updateGross(grossSelector, netSelector) {
        const vatValue = this.getVatValueFromFormField();
        let netValue = this.parseArbitraryNumberToFloat($(netSelector).val());

        netValue = this.getTmpNetValue(vatValue, netValue);
        const grossValue = netValue * (1 + vatValue);

        $(grossSelector).val(this.formatAsCurrency(grossValue));
    }

    updateNet(grossSelector, netSelector) {
        const vatValue = this.getVatValueFromFormField();
        const grossValue = this.getValueFromFormField(grossSelector);
        const netValue = grossValue / (1 + vatValue);
        this.initTmpNetValue();
        this.setTmpNetValue(vatValue, netValue);
        $(netSelector).val(this.formatAsCurrency(netValue));
    }

    updateAllNet() {
        this.updateNet(this.selectorGrossAmount, this.selectorNetAmount);
        if ($(this.selectorAlternativeAmountSwitch).is(':checked')) {
            this.updateNet(this.selectorAlternativeGrossAmount, this.selectorAlternativeNetAmount, true);
        }
        if ($(this.selectorFlatrateAmountSwitch).is(':checked')) {
            this.updateNet(this.selectorFlatrateGrossAmount, this.selectorFlatrateNetAmount, true);
        }
    }

    /**
     * Calculates the net amount of the inherited gross value for usage with inline editing fields.
     */
    calculateInheritedNet() {
        if ($(this.selectorInlineEditNet).length > 0) {
            const grossValue = parseFloat($(this.selectorGrossAmount).attr('placeholder'));
            const vatValue = parseFloat($(this.selectorVatFactor).attr('data-inherited'));
            const netValue = Number(grossValue / (1 + vatValue));
            this.setTmpNetValue(vatValue, netValue);
            const netValueFormatted = Number(netValue).toLocaleString(this.currencyFormat, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            });
            $(this.selectorNetAmount).attr('placeholder', netValueFormatted);
            $(this.selectorInlineEditNet).find('span').text(netValueFormatted);
        }
    }

    /**
     * get the vat value from the corresponding select form field. there is a special case when we inherit
     * the value, we must use the value from the data-inherited attribute instead of the form-elements value.
     *
     * @returns {number}
     */
    getVatValueFromFormField() {
        const attr = $(this.selectorVatFactor).attr('data-inherited');

        // value is empty and attribute data-inherited exists and is not empty
        if ($(this.selectorVatFactor).val() === '' && this.isDefined(attr) && attr !== '') {
            return parseFloat(attr);
        }
        // value is empty and data-inherited is empty
        if ($(this.selectorVatFactor).val() === '' && this.isDefined(attr) && attr === '') {
            return 0.0;
        }

        return parseFloat($(this.selectorVatFactor).val());
    }

    getValueFromFormField(selector) {
        const attr = $(selector).attr('data-inherited');

        // value is empty and attribute data-inherited exists and is not empty
        if ($(selector).val() === '' && this.isDefined(attr) && attr !== '') {
            return (parseFloat(attr) / 100);
        }
        // value is empty and data-inherited is empty
        if ($(selector).val() === '' && this.isDefined(attr) && attr === '') {
            return 0.0;
        }

        return this.parseArbitraryNumberToFloat($(selector).val());
    }

    /**
     * convert any string/number to float
     *
     * @param {number|string} number
     * @returns {number}
     */
    parseArbitraryNumberToFloat(number) {
        if (typeof number === 'string') {
            if (number.indexOf('.') !== -1 && number.indexOf(',') !== -1) {
                number = number.replace('.', '').replace(',', '.');
            } else if (number.indexOf(',') !== -1) {
                number = number.replace(',', '.');
            }
        }

        return parseFloat(number);
    }

    /**
     * formats a given number as localized (de) number
     *
     * @param {number|string} number
     * @returns {string}
     */
    formatAsCurrency(number) {
        if (isNaN(number)) {
            number = 0;
        }

        return new Intl.NumberFormat('de-DE', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
            useGrouping: false
        }).format(number);
    }

    /**
     * Checks whether a given variable is valid and not false
     * @param {*} val
     * @returns {boolean}
     */
    isDefined(val) {
        return typeof val !== 'undefined' && val !== false;
    }

    initTmpNetValue() {
        this.tmpNetValues = {};
    }

    /**
     * @param {number|string} vat
     * @param {number|string} amount
     */
    setTmpNetValue(vat, amount) {
        if (!this.tmpNetValues[vat]) {
            this.tmpNetValues[vat] = {};
        }
        if (!isNaN(amount) && amount !== 0) {
            const amountFormatted = this.formatAsCurrency(amount);
            this.tmpNetValues[vat][amountFormatted] = parseFloat(parseFloat(amount).toFixed(4));
        }
    }

    /**
     * @param {number|string} vat
     * @param {number|string} amount
     * @returns {number}
     */
    getTmpNetValue(vat, amount) {
        if (!isNaN(amount) && amount !== 0) {
            const amountFormatted = this.formatAsCurrency(amount);

            if (this.tmpNetValues[vat] && this.tmpNetValues[vat][amountFormatted]) {
                return this.tmpNetValues[vat][amountFormatted];
            }
        }

        return amount;
    }

    handleAlternativeAmountSwitchChange() {
        if ($(this.selectorAlternativeAmountSwitch).is(':checked')) {
            this.enableAlternativeAmount();
        } else {
            this.disableAlternativeAmount();
        }
    }

    handleFlatrateAmountSwitchChange() {
        if ($(this.selectorFlatrateAmountSwitch).is(':checked')) {
            this.enableFlatrateAmount();
        } else {
            this.disableFlatrateAmount();
        }
    }

    enableAlternativeAmount() {
        $(this.selectorAlternativeWrapper).slideDown('slow');
        $(this.selectorAlternativeGrossAmount).prop('required', true);
        $(this.selectorAlternativeNetAmount).prop('required', true);
    }

    disableAlternativeAmount() {
        $(this.selectorAlternativeWrapper).slideUp('slow');

        $(this.selectorAlternativeGrossAmount).val('');
        $(this.selectorAlternativeNetAmount).val('');
        $(this.selectorAlternativeSapArticleNo).val('');
        $(this.selectorAlternativeAmountDay).prop('checked', false);

        $(this.selectorAlternativeGrossAmount).prop('required', false);
        $(this.selectorAlternativeNetAmount).prop('required', false);
    }

    enableFlatrateAmount() {
        $(this.selectorFlatrateWrapper).slideDown('slow');
        $(this.selectorFlatrateGrossAmount).prop('required', true);
        $(this.selectorFlatrateNetAmount).prop('required', true);
    }

    disableFlatrateAmount() {
        $(this.selectorFlatrateWrapper).slideUp('slow');

        $(this.selectorFlatrateGrossAmount).val('');
        $(this.selectorFlatrateNetAmount).val('');
        $(this.selectorFlatrateSapArticleNo).val('');

        $(this.selectorFlatrateGrossAmount).prop('required', false);
        $(this.selectorFlatrateNetAmount).prop('required', false);
    }
}

/**
 * Export an instance of this class as default for easy use of this es6 module.
 * If needed one is still able to import the class itself as well, since it's "export"
 * flagged to.
 */
export default new PriceCalculation();
