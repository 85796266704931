import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import store from './store/store';
import SubEventDetailsShell, { propTypes, defaultProps } from './components/SubEventDetailsShell/SubEventDetailsShell';

function SubEventDetailsInit(props) {
    return (
        <Provider store={store}>
            <SubEventDetailsShell
                clientId={props.clientId}
                eventId={props.eventId}
                subEventId={props.subEventId}
                editMode={props.editMode}
                locales={props.locales}
            />
        </Provider>
    );
}

SubEventDetailsInit.propTypes = propTypes;
SubEventDetailsInit.defaultProps = defaultProps;

document.querySelectorAll('.js-sub-event-details').forEach((hostEle) => {

    const root = createRoot(hostEle);

    const clientId = parseInt(hostEle.dataset.clientId, 10);
    const eventId = parseInt(hostEle.dataset.eventId, 10);
    const subEventId = parseInt(hostEle.dataset.subEventId, 10);
    const editMode = hostEle.dataset.editMode || '';
    const locales = hostEle.dataset.locales.split(',').map(l => l.trim());

    root.render(
        <SubEventDetailsInit
            clientId={clientId}
            eventId={eventId}
            subEventId={subEventId}
            editMode={editMode}
            locales={locales}
        />);
});