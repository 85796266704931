import React from 'react';
import { createRoot }from 'react-dom/client';
import PlaceholderTextarea from './PlaceholderTextarea';

document.querySelectorAll('.js-placeholder-textarea').forEach((instance) => {
    const host = instance.querySelectorAll('.js-placeholder-textarea_host')[0];
    const textareaWrapper = instance.querySelectorAll('.js-placeholder-textarea_textarea')[0];
    const textarea = textareaWrapper.querySelectorAll('textarea')[0];
    const config = instance.dataset.config;
    let copyValue = null;
    if (typeof instance.dataset.copyvalue !== 'undefined') {
        copyValue = instance.dataset.copyvalue;
    }
    let labelValue = null;
    if (typeof instance.dataset.labelvalue !== 'undefined') {
        labelValue = instance.dataset.labelvalue;
    }
    const root = createRoot(host);
    root.render(
        <PlaceholderTextarea
            textareaEle={textarea}
            config={config}
            labelValue={labelValue}
            copyValue={copyValue}
        />);
});