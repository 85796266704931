/* eslint-disable prefer-arrow-callback */

const $ = window.jQuery;
const Routing = typeof window.Routing !== 'undefined' ? window.Routing : { generate: route => route };

export function renderTags(tags, type) {
    if (type !== 'display') {
        return tags;
    }

    let html = '';

    $.each(tags, (index, tag) => {
        html = `${html}<div class="tag" style="background-color: ${tag.color}; text-align: center; font-weight: bold; font-size: 0.75em; border-radius: 5px;">${tag.name}</div>`;
    });

    return html;
}

export function rendererBusinessPartner(businessPartner, type) {
    if (type !== 'display' || businessPartner === null) {
        return businessPartner;
    }

    const urlParams = new URLSearchParams(window.location.search);
    const cid = urlParams.get('_cid');
    const eid = urlParams.get('_eid');

    const url = Routing.generate('business_partner_show_contracts', {
        businessPartner: businessPartner.id,
        _cid: cid,
        _eid: eid
    });

    let html = `<a href="${url}">${businessPartner.name}</a>`;

    if (businessPartner.name2 !== '') {
        html += `<br>${businessPartner.name2}`;
    }

    if (businessPartner.name3 !== '') {
        html += `<br>${businessPartner.name3}`;
    }

    if (businessPartner.name4 !== '') {
        html += `<br>${businessPartner.name4}`;
    }

    return html;
}

export function rendererSourceId(source, type) {
    if (type !== 'display' || source === null) {
        return source;
    }

    return source.replace(' ', '<br>');
}
