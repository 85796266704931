/**
 * RESULT ITEM DETAILS SECTION TEMPLATE
 */

import React from 'react';
import PropTypes from 'prop-types';

const Translator = window.Translator;

const resultItemDetails = (props) => {
    let value = ' - ';
    if (props.value && props.value !== 'null') {
        value = String(props.value).split('\\n').map((item, i) => (
            //  dangerouslySetInnerHTML is necessary to display the <mark> tag we have added to the result items data.
            <p key={props.field + i} className="m-b-0" dangerouslySetInnerHTML={{ __html: item }} />
        ));
    }
    return (
        <li className="list-group-item bg-white">
            <div className="row">
                <div className="col-sm-3 fw-bold" title={props.field}>
                    {Translator.trans(`contract.rebook.search_result.${props.field}`)}
                </div>
                <div className="col">
                    {value}
                </div>
            </div>
        </li>
    );
};

resultItemDetails.propTypes = {
    field: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool
    ])
};

resultItemDetails.defaultProps = {
    value: ' - '
};

export default resultItemDetails;
