import React, { useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import NumberFormat from 'react-number-format';
import Card from '../Card/Card';
import { useSelector, useDispatch } from 'react-redux';
import {
    setSearchTerm,
    addVariant,
    deleteVariant,
    changeGrossAmount,
} from './productVariantsReducer';

const CardProductVariants = () => {
    const [variantsDropDownActive, setVariantsDropDownActive] = useState(false);
    const dispatch = useDispatch();
    const configIsPending = useSelector(state => state.shell.configIsPending);
    const searchTerm = useSelector(state => state.productVariants.searchTerm);
    const configVariants = useSelector(state => state.shell.config.variants);
    const variants = useSelector(state => state.productVariants.variants);
    const inEditMode = useSelector(state => state.actionBar.inEditMode);

    const addBtnIsDisabled = v => variants.some(x => x.variant_id === v.id);

    const filteredConfigVariants = () => configVariants.filter(cv =>
        `${cv.name} ${cv.product_no}`
            .toLowerCase()
            .includes(searchTerm.toLowerCase()));

    if (configIsPending) {
        return <Skeleton height={39} />;
    }

    return (
        <Card label="Produktvarianten">
            {configVariants.length > 0 ? (<React.Fragment>
                <div>
                    <table className="table bg-white m-b-0" style={{ zIndex: 9 }}>
                        <thead>
                            <tr>
                                <th width="50" title={window.Translator.trans('sub_event.product_variants.product_number')}>
                                    {window.Translator.trans('sub_event.product_variants.nr')}
                                </th>
                                <th>{window.Translator.trans('sub_event.product_variants.name')}</th>
                                <th width="80">{window.Translator.trans('sub_event.product_variants.price')}</th>
                                {inEditMode && (<th>{'\u00A0'}</th>)}
                            </tr>
                        </thead>
                        <tbody>
                            {variants.length > 0 ? variants.map(v => (
                                <tr key={v.variant_id}>
                                    <td>{v.product_no}</td>
                                    <td>{v.name}</td>
                                    <td>
                                        <NumberFormat
                                            readOnly={!inEditMode}
                                            className={`me-2 rounded d-block w-100 ${inEditMode ? 'border border-primary' : 'border-0'}`}
                                            tabIndex={inEditMode ? 0 : -1}
                                            value={v.gross_amount / 100}
                                            thousandSeparator="."
                                            decimalSeparator=","
                                            suffix={' €'}
                                            onValueChange={(values) => {
                                                const { floatValue } = values;
                                                dispatch(changeGrossAmount({
                                                    variant_id: v.variant_id,
                                                    gross_amount:
                                                    floatValue * 100
                                                }));
                                            }}
                                        />

                                    </td>
                                    {inEditMode && (
                                        <td>
                                            <button
                                                className="btn text-danger "
                                                onClick={() => dispatch(deleteVariant(v))}
                                            > <i className="fas fa-trash-alt" />
                                            </button>
                                        </td>
                                    )}
                                </tr>
                            )) : (
                                <tr>
                                    <td colSpan="4" className="font-italic">
                                        {window.Translator.trans('sub_event.product_variants.please_select_variant')}
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>

                {inEditMode && (
                    <React.Fragment>
                        <div className="position-relative">
                            <button
                                style={{
                                    left: 0,
                                    top: 0,
                                    zIndex: 12,
                                    padding: '4px 7px',
                                    lineHeight: 1.2,
                                }}
                                className="btn btn-sm btn-primary position-absolute"
                                onClick={() => setVariantsDropDownActive(!variantsDropDownActive)}
                            >
                                {variantsDropDownActive ? (
                                    <i className="fas fa-caret-up" />
                                ) : (
                                    <i className="fas fa-caret-down" />
                                )}
                            </button>
                            <input
                                style={{
                                    paddingLeft: '28px',
                                    paddingRight: '28px',
                                }}
                                className="me-2 border border-primary rounded d-block w-100"
                                onChange={e => dispatch(setSearchTerm(e.target.value))}
                                value={searchTerm}
                                onFocus={() => setVariantsDropDownActive(true)}
                            />
                            {searchTerm.length > 0 && (
                                <button
                                    className="btn text-primary position-absolute"
                                    onClick={() => dispatch(setSearchTerm(''))}
                                    style={{
                                        right: '-3px',
                                        top: '-3px',
                                        zIndex: 10,
                                    }}
                                >
                                    <i className="fas fa-times" />
                                </button>
                            )}
                        </div>

                        <div
                            className="card h-transe-250"
                            style={variantsDropDownActive ? {
                                overflowX: 'auto',
                                maxHeight: '300px',
                                boxShadow: 'inset 2px 2px 4px 0px rgb(0 0 0 / 33%)',
                            } : {
                                boxShadow: 'inset 0px 0px 0px 0px rgb(0 0 0 / 33%)',
                                maxHeight: '0px',
                                overflow: 'hidden',
                            }}
                        >
                            <table className="table" style={{ zIndex: 9 }}>
                                <thead>
                                    <tr>
                                        <th title={window.Translator.trans('sub_event.product_variants.product_number')}>
                                            {window.Translator.trans('sub_event.product_variants.nr')}
                                        </th>
                                        <th>{window.Translator.trans('sub_event.product_variants.name')}</th>
                                        <th>{'\u00A0'}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredConfigVariants().map(v => (
                                        <tr key={v.id} className={`${addBtnIsDisabled(v) ? 'text-muted' : null}`}>
                                            <td>{v.product_no}</td>
                                            <td>{v.name}</td>
                                            <td>
                                                <button
                                                    className={`ms-2 btn ${addBtnIsDisabled(v) ? 'text-secondary' : 'text-primary'}`}
                                                    disabled={addBtnIsDisabled(v)}
                                                    onClick={() => dispatch(addVariant(v))}
                                                >
                                                    <i className="fas fa-plus-circle" />
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </React.Fragment>
                )}
            </React.Fragment>) : (
                <div className="text-center">
                    <p className="font-italic m-b-0">
                        {window.Translator.trans('sub_event.product_variants.no_variant_available')}
                    </p>
                </div>
            )}
        </Card>
    );
};

export default CardProductVariants;
