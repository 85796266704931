/**
 * Import libraries and dependendies to use in this JS class
 */

const Translator = window.Translator;
const $ = window.jQuery;

export class ChangeLegend {
    constructor() {
        /**
         * Set the CSS class names for further reference
         */
        this.classEventReceiptConfigCollection = '.js-event-receipt-config-collection';
    }

    /**
     * Check are classes for change Legend available
     */
    init() {
        $(document).ready(() => {
            // check if our js-classes exist in dom. otherwise exit.
            if ($(this.classEventReceiptConfigCollection).length > 0) {
                this.addCollection();
                this.addListener();
            }
        });
    }

    /**
     * Add collection to the receipt block
     */
    addCollection() {
        $(this.classEventReceiptConfigCollection).collection({
            up: null,
            down: null
        });
    }

    /**
     * Add listener on select field
      */
    addListener() {
        $(this.classEventReceiptConfigCollection).on('change', (event) => {
            let legend;
            /* eslint-disable indent */
            switch (event.target.value) {
                case 'receipt_config_type_cash_payment':
                    legend = Translator.trans('event.event.legend.receipt_config_type_cash_payment');
                    break;
                case 'receipt_config_type_card_payment':
                    legend = Translator.trans('event.event.legend.receipt_config_type_card_payment');
                    break;
                case 'receipt_config_type_default':
                    legend = Translator.trans('event.event.legend.default');
                    break;
                default:
                    legend = Translator.trans('event.event.legend.default');
            }
            /* eslint-enable indent */

            $(event.target).closest('fieldset').find('legend').html(legend);
        });
    }
}

/**
 * Export an instance of this class as default for easy use of this es6 module.
 * If needed one is still able to import the class itself as well, since it's "export"
 * flagged to.
 */
export default new ChangeLegend();
