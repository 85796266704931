/* eslint-disable consistent-return */
/* jshint -W079 */

import dataTablesMainInit, {
    renderLink,
    renderWithoutHTML
} from '../../../../../LayoutBundle/js/dataTablesMainInit';
import {
    renderContract,
    rendererBusinessPartner,
    renderOrderPosition,
    renderDateTime,
    renderProductVariant,
    renderTicket,
    renderProductVariantProductNumber
} from '../../dToutputRenderer';

const $ = window.jQuery;

const Translator = typeof window.Translator !== 'undefined' ? window.Translator : { trans: key => key };
const trans = rootKey => key => Translator.trans(`${rootKey}.${key}`, 'datatable');

const t = trans('ticket');

$(document).ready(() => {
    const dTsettings = {};

    dTsettings.tableName = 'contract_order_management_ticket_datatable';
    dTsettings.selector = `#${dTsettings.tableName}`;

    if ($(dTsettings.selector).length === 0) return false;

    dTsettings.options = {
        order: [[1, 'desc']],
        orderCellsTop: true,
    };

    dTsettings.extensions = {
        colReorder: {
            fixedColumnsLeft: 1
        },
    };

    dTsettings.columns = {
        columns: [
            {
                title: t('title.event'),
                searchable: true,
                orderable: true,
                data: 'event',
                name: 'event',
                render: renderLink,
            },
            {
                title: t('title.id'),
                searchable: true,
                orderable: true,
                data: 'id',
                name: 'id',
                render: renderTicket,
            },
            {
                title: t('title.business_partner'),
                searchable: true,
                orderable: true,
                data: 'business_partner',
                name: 'business_partner',
                render: rendererBusinessPartner,
            },
            {
                title: t('title.sap_id'),
                searchable: true,
                orderable: true,
                data: 'sap_id',
                name: 'sap_id',
            },
            {
                title: t('title.contract'),
                searchable: true,
                orderable: true,
                data: 'contract',
                name: 'contract',
                render: renderContract,
            },
            {
                title: t('title.order_position'),
                searchable: true,
                orderable: true,
                data: 'order_position',
                name: 'order_position',
                render: renderOrderPosition,
            },
            {
                title: t('title.product_variant'),
                searchable: true,
                orderable: true,
                data: 'product_variant',
                name: 'product_variant.name',
                render: renderProductVariant,
            },
            {
                title: t('title.product_variant_product_number'),
                searchable: true,
                orderable: true,
                data: 'product_variant_product_number',
                name: 'product_variant.product_no',
                render: renderProductVariantProductNumber,
            },
            {
                title: t('title.registration_code'),
                searchable: true,
                orderable: true,
                data: 'registration_code',
                name: 'registration_code',
            },
            {
                title: t('title.short_code'),
                searchable: true,
                orderable: true,
                data: 'short_code',
                name: 'short_code',
            },
            {
                title: t('title.consecutive_number'),
                searchable: true,
                orderable: true,
                data: 'consecutive_number',
                name: 'consecutive_number',
            },
            {
                title: t('title.owner_first_name'),
                searchable: true,
                orderable: true,
                data: 'owner_first_name',
                name: 'owner_first_name',
                render: renderWithoutHTML,
            },
            {
                title: t('title.owner_last_name'),
                searchable: true,
                orderable: true,
                data: 'owner_last_name',
                name: 'owner_last_name',
                render: renderWithoutHTML,
            },
            {
                title: t('title.owner_email'),
                searchable: true,
                orderable: true,
                data: 'owner_email',
                name: 'owner_email',
                render: renderWithoutHTML,
            },
            {
                title: t('title.owner_company'),
                searchable: true,
                orderable: true,
                data: 'owner_company',
                name: 'owner_company',
                render: renderWithoutHTML,
            },
            {
                title: t('title.buyer_first_name'),
                searchable: true,
                orderable: true,
                data: 'buyer_first_name',
                name: 'buyer_first_name',
                render: renderWithoutHTML,
            },
            {
                title: t('title.buyer_last_name'),
                searchable: true,
                orderable: true,
                data: 'buyer_last_name',
                name: 'buyer_last_name',
                render: renderWithoutHTML,
            },
            {
                title: t('title.buyer_email'),
                searchable: true,
                orderable: true,
                data: 'buyer_email',
                name: 'buyer_email',
                render: renderWithoutHTML,
            },
            {
                title: t('title.buyer_company'),
                searchable: true,
                orderable: true,
                data: 'buyer_company',
                name: 'buyer_company',
                render: renderWithoutHTML,
            },
            {
                title: t('title.invoice_number'),
                searchable: true,
                orderable: true,
                data: 'invoice_number',
                name: 'invoice_number',
            },
            {
                title: t('title.order_id'),
                searchable: true,
                orderable: true,
                data: 'order_id',
                name: 'order_id',
            },
            {
                title: t('title.created_at'),
                searchable: true,
                orderable: true,
                data: 'created_at',
                name: 'created_at',
                render: renderDateTime,
            },
            {
                title: t('title.status'),
                searchable: true,
                orderable: false,
                data: 'status',
                name: 'status',
            },
        ]
    };

    dataTablesMainInit(dTsettings);
});
