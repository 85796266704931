import { createSlice } from '@reduxjs/toolkit';
import createDateRange from './createDateRange';
import { v4 as uuidv4 } from 'uuid';
import { dateToUI } from './dateFormatter';

const initialState = {
    entranceTimeOffset: '',
    entranceTimeInheritedOffset: 0,
    participationType: 'offline',
    durationType: 'day',
    timeTables: [],
    newTimeTable: {
        id: null,
        enabled: true,
        startDate: '',
        endDate: '',
    },
    newTimeTableCount: 0,
    globalQuota: 0,
    timeZone: 'Europe/Berlin',
    startDate: {
        utcString: '',
        date: '',
        timeHM: '',
    },
    endDate: {
        utcString: '',
        date: '',
        timeHM: '',
    },
};

const castNegativeToZero = (val) => {
    const valInt = parseInt(val, 10);
    if (!isNaN(valInt) && valInt < 0) {
        return 0;
    }

    return val;
};

export const planningReducer = createSlice({
    name: 'planning',
    initialState,
    reducers: {
        setParticipationType: (state, action) => {
            state.participationType = action.payload;
        },
        setDurationType: (state, action) => {
            state.durationType = action.payload;
            const { globalQuota } = state;
            if (state.timeTables.length > 0 && action.payload === 'multi_day') {
                state.timeTables = state.timeTables.map((timeTable) => {
                    timeTable.quota = globalQuota;
                    return timeTable;
                });
            } else {
                state.timeTables = state.timeTables.map((timeTable) => {
                    timeTable.quota = 0;
                    return timeTable;
                });
            }
        },
        setTimeTables: (state, action) => {
            action.payload.sub_event_plannings = action.payload.sub_event_plannings.map((p) => {
                const startDate = dateToUI(p.start_date, action.payload.event.timeZone);
                const endDate = dateToUI(p.end_date, action.payload.event.timeZone);
                return ({
                    id: p.id,
                    uuid: p.uuid,
                    enabled: p.enabled,
                    additional_info: p.additional_info,
                    quota: p.quota,
                    quota_left: p.quota_left,
                    alias: p.alias,
                    date: startDate.date,
                    start: startDate.timeHM,
                    end: endDate.timeHM,
                });
            });

            state.timeTables = action.payload.sub_event_plannings;
        },
        addTimeTable: (state, action) => {
            state.timeTables.push({
                ...action.payload
            });
        },
        deleteTimeTable: (state, action) => {
            const idx = state.timeTables.findIndex(t => t.uuid === action.payload);
            state.timeTables.splice(idx, 1);
        },
        changeTimeTable: (state, action) => {
            const { uuid, key } = action.payload;
            const value = castNegativeToZero(action.payload.value);
            const toEdit = state.timeTables.find(t => t.uuid === uuid);
            toEdit[key] = value;
        },
        changeAllTimeTables: (state, action) => {
            const { key } = action.payload;
            const value = castNegativeToZero(action.payload.value);
            state.timeTables = state.timeTables.map((timeTable) => {
                timeTable[key] = value;
                return timeTable;
            });
        },
        changeNewTimeTable: (state, action) => {
            const { key, value } = action.payload;
            state.newTimeTable[key] = value;
        },
        addNewTimeTable: (state) => {
            const { durationType, globalQuota } = state;
            const endDate = state.newTimeTable.endDate ? state.newTimeTable.endDate : state.newTimeTable.startDate;

            const dateRange = createDateRange(state.newTimeTable.startDate, endDate);
            dateRange.forEach((date) => {
                if (!state.timeTables.some(t => t.date === date)) {
                    state.timeTables.push({
                        id: null,
                        enabled: true,
                        start: '00:00',
                        end: '23:59',
                        additional_info: '',
                        quota: durationType === 'multi_day' ? globalQuota : 0,
                        uuid: uuidv4(),
                        date,
                    });
                }
            });

            state.newTimeTable = {
                ...initialState.newTimeTable,
                startDate: { ...state.startDate }.date,
                endDate: { ...state.endDate }.date
            };
        },
        changeNewTimeTableCount: (state, action) => {
            state.newTimeTableCount = action.payload;
        },
        addNewTimeTableByCount: (state) => {
            const { durationType, globalQuota } = state;

            for (let count = 0; count < state.newTimeTableCount; count++) {
                state.timeTables.push({
                    id: null,
                    enabled: true,
                    start: '00:00',
                    end: '23:59',
                    additional_info: '',
                    quota: durationType === 'multi_day' ? globalQuota : 0,
                    uuid: uuidv4(),
                    date: '',
                });
            }

            state.newTimeTableCount = 0;
        },
        setTimeZone: (state, action) => {
            state.timeZone = action.payload;
        },
        setStartDate: (state, action) => {
            const dateObj = dateToUI(action.payload, state.timeZone);
            state.startDate = dateObj;
            state.newTimeTable.startDate = dateObj.date;
        },
        setEndDate: (state, action) => {
            const dateObj = dateToUI(action.payload, state.timeZone);
            state.endDate = dateObj;
            state.newTimeTable.endDate = dateObj.date;
        },
        setGlobalQuota: (state, action) => {
            const pl = castNegativeToZero(action.payload);
            state.globalQuota = parseInt(pl, 10);
        },
        setEntranceTimeOffset: (state, action) => {
            state.entranceTimeOffset = action.payload === null ? '' : action.payload;
        },
        setEntranceTimeInheritedOffset: (state, action) => {
            state.entranceTimeInheritedOffset = action.payload;
        },
        resetEntranceTimeOffset: (state) => {
            state.entranceTimeOffset = '';
        },
    },
});

export const {
    setParticipationType,
    setDurationType,
    setTimeTables,
    addTimeTable,
    deleteTimeTable,
    changeTimeTable,
    changeAllTimeTables,
    changeNewTimeTable,
    changeNewTimeTableCount,
    addNewTimeTable,
    addNewTimeTableByCount,
    setTimeZone,
    setStartDate,
    setEndDate,
    setGlobalQuota,
    setEntranceTimeOffset,
    setEntranceTimeInheritedOffset,
    resetEntranceTimeOffset,
} = planningReducer.actions;

export default planningReducer.reducer;
