import $ from 'jquery';

const resetBtnTxt = window.Translator.trans('template_bundle.placeholder_textarea.reset_value');

$(document).ready(() => {
    const $removeFields = $('.js-remove-value-button');

    $removeFields.each(function () {
        const removeFieldId = $(this).attr('id');
        $(this)
            .parent()
            .removeClass('col-sm-10')
            .addClass('col p-r-0')
            .parent()
            .append(`
                    <div class="col-sm-3 p-l-0">
                        <span class="js-remove-btn btn btn-small btn-primary w-100" data-bs-targetid="${removeFieldId}">
                            <i class="fas fa-angle-left"></i> ${resetBtnTxt}
                        </span>
                    </div>
                `)
        ;
    });

    $(document).on('click', '.js-remove-btn', function () {
        const targetId = $(this).data('bsTargetid');
        const $target = $(`#${targetId}`);
        const entranceTimeVal = $target.data('entrance-time-offset-value');
        const entranceTimePlaceholder = window.Translator.trans('template_bundle.data.entrance_time_offset', {
            entrance_time_offset: entranceTimeVal.toString()
        });
        $target.val(null);
        $target.attr('placeholder', entranceTimePlaceholder);
    });
});
