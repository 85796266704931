/* eslint-disable*/
import $ from 'jquery';

export class ToggleType {

    constructor() {
        this.selector = '.js-toggle-switch';
    }

    init() {
        $(document).ready(() => {
            $(this.selector).each(function(i, obj) {
                ToggleType.handleChange(obj);
            });
            $(this.selector).on('change', function (event) {
                ToggleType.handleChange(this);
            });
        });
    }

    static handleChange(selector) {
        let text = '';
        if ($(selector).is(':checked')) {
            text = $(selector).data('on-label');
        } else {
            text = $(selector).data('off-label');
        }

        $($("#" + $(selector).attr('id') + "_label")).text(text);
    }
}

export default new ToggleType();
