import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    description: {},
    displayName: {},
};

export const displayReducer = createSlice({
    name: 'display',
    initialState,
    reducers: {
        setDescription: (state, action) => {
            if (action.payload.lang && action.payload.description) {
                state.description[action.payload.lang] = action.payload.description;
            } else {
                console.error('props missing!');
            }
        },
        setDisplayName: (state, action) => {
            if (action.payload.lang && action.payload.displayName) {
                state.displayName[action.payload.lang] = action.payload.displayName;
            } else {
                console.error('props missing!');
            }
        },
    },
});

export const {
    setDescription,
    setDisplayName
} = displayReducer.actions;

export default displayReducer.reducer;
