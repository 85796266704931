import moment from 'moment-timezone';

/* eslint-disable no-mixed-operators */
export const dateToUI = (utcString, timeZone = 'Europe/Berlin') => {
    const dateOBJ = moment.utc(utcString).tz(timeZone);

    const dateLocal = dateOBJ.format('DD.MM.YYYY');
    const date = dateOBJ.format('YYYY-MM-DD');
    const timeHM = dateOBJ.format('HH:mm');
    const dateTime = dateOBJ.format('YYYY-MM-DDTHH:mm');

    return ({ utcString, date, dateLocal, timeHM, dateTime });
};

// normalize time zone date to utc
export const dateToAPI = (date, time, timeZone = 'Europe/Berlin') =>
    moment.tz(`${date} ${time}`, timeZone).utc().format('YYYY-MM-DDTHH:mm:00+00:00');
