/* eslint-disable camelcase */


const $ = window.jQuery;

const createToolbarButtons = (sg_datatables_view_name, table, language) => {
    const currenUrl = new URL(window.location.href);
    const urlKey = currenUrl.href.replace(currenUrl.origin + '/', '');

    const $fullScreenWrapper = $('.js-fullscreen-wrapper');
    const iconExpand = 'fas fa-expand';
    const iconCompress = 'fas fa-compress';
    const iconFilter = 'fas fa-filter';
    const classExpand = 'has-fullscreen-mode';
    const classBtnIcon = 'js-btn-icon';

    /* eslint-disable no-new */
    new $.fn.dataTable.Buttons(table, {
        name: 'Button Bar',
        buttons: [
            {
                text: '<i class="fas fa-eye"></i>',
                extend: 'colvis',
            },
            {
                text: `<i class="${classBtnIcon} ${iconExpand}"></i>`,
                className: 'btn btn-primary',
                action(e) {
                    if ($fullScreenWrapper.length !== 0) {
                        const $body = $('body');
                        const $clickedBtn = $(e.currentTarget);
                        const $btnIcon = $clickedBtn.find(`.${classBtnIcon}`);

                        // Fullscreen active
                        if ($fullScreenWrapper.hasClass(classExpand)) {
                            $btnIcon.removeClass(iconCompress).addClass(iconExpand);
                            $fullScreenWrapper.removeClass(classExpand);
                            $body.removeClass('h-overflow-auto');

                        // Fullscreen inactiv
                        } else {
                            $btnIcon.removeClass(iconExpand).addClass(iconCompress);
                            $fullScreenWrapper.addClass(classExpand);
                            $body.addClass('h-overflow-auto');
                        }
                    } else {
                        console.error('Wrapper for fullscreenmode is missing :(');
                    }
                }
            },
            {
                text: `<i class="${classBtnIcon} ${iconFilter}"></i> <i class="fas fa-solid fa-xmark""</i> <span class="js-actives-filters-count-${sg_datatables_view_name} position-absolute top-0 start-100 translate-middle badge rounded-pill border bg-info">0</span>`,
                title: language.language.btnResetFilters,
                className: `btn js-reset-${sg_datatables_view_name} ${
                    parseInt(sessionStorage.getItem(`${urlKey}_DataTables_${sg_datatables_view_name}_amount_of_changes`), 10) > 1 ? 'btn-primary' : 'disabled'
                }`,
                action() {
                    sessionStorage.removeItem(`${urlKey}_DataTables_${sg_datatables_view_name}`);
                    sessionStorage.removeItem(`${urlKey}_DataTables_${sg_datatables_view_name}_amount_of_changes`);
                    sessionStorage.removeItem(`${urlKey}_DataTables_${sg_datatables_view_name}_changed_state`);

                    window.location.reload()
                }
            },

        ],
        dom: {
            button: {
                tag: 'a',
                active: 'is-active'
            },
            buttonLiner: {
                tag: null
            }
        }
    });
    /* eslint-enable no-new */

    table.buttons(0, null).containers().appendTo(`.js-${sg_datatables_view_name}-toolbar`);
};

export default createToolbarButtons;
