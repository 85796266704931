import $ from 'jquery';

const dTkeepInputFocus = (tableName = 'dataTable') => {
    const lastDtInputFocus = sessionStorage.getItem(`${tableName}-lastDtInputFocus`);
    const $filterRow = $('body').find('.dataTable .filter_row');
    if (lastDtInputFocus !== null && lastDtInputFocus !== '#') {
        const $lastDtInputFocus = $(`[id="${lastDtInputFocus.replace('#', '')}"]`);
        const existingVal = $lastDtInputFocus.val();
        $lastDtInputFocus.focus().val('').val(existingVal);
    }

    $filterRow.find('input').on('click', function () {
        if (typeof this.id !== 'undefined') {
            sessionStorage.setItem(`${tableName}-lastDtInputFocus`, `#${this.id}`);
        }
    });

    $('.dataTables_wrapper').on('click', (e) => {
        const ele = e.target.nodeName;
        if (ele.toLowerCase() !== 'input' || e.target.type === 'search') {
            sessionStorage.removeItem(`${tableName}-lastDtInputFocus`);
        }
    });
};

export default dTkeepInputFocus;
