import React, { Fragment } from 'react';
import { ThreeDots }  from 'react-loader-spinner' ;
import { useSelector } from 'react-redux';
import { loaderWrapper, loader } from './LoadingIndicatorStyles';

const LoadingIndicator = () => {
    const isPending = useSelector(state => state.shell.isPending);

    return (
        <Fragment>
            {isPending && (
                <div style={loaderWrapper}>
                    <div style={loader}>
                        <ThreeDots
                            color="#93dcfc"
                            height="100"
                            width="100"
                        />
                    </div>
                </div>
            )}
        </Fragment>
    );
};

export default LoadingIndicator;