/* eslint-disable camelcase */

/**
 * About:
 * This class manages the multiselection for the sg datatable. It uses Index DB to persist the selected rows and some jquery to sync the UI
 * with the Index DB. Also the Action Handler got initialized in this class, which is a react component that provides the action button, modal, and further
 * action handling.
 */

/**
 * Import dependencies - third party.
 */
import React from 'react';
import { createRoot } from 'react-dom/client';
import Dexie from 'dexie';
import axios from 'axios';
import $ from 'jquery';

/**
 * Import dependencies - react components.
 */
import ActionHandlerComponent from './ActionHandler/ActionHandler';

export class DataTableActions_new {
    constructor() {
        /**
         * Static data we like to use.
         */
        this.hostElement = document.getElementById('js-datatable-actionbar_new');
        this.root = null;
        if (this.hostElement) {
            this.datatableName = this.hostElement.getAttribute('data-datatableName');
            this.datatableUrlToFetchAllIds = this.hostElement.getAttribute('data-urlToFetchAllIds');

            this.classNameToolbar = `.js-${this.datatableName}-datatable-toolbar_new`;
            this.classNameInputSelectRow = '.js-multiselect-checkbox';
            this.classNameSelectedRowsCount = '.js-datatable-selected-rows-counter';

            this.classNameSelectAll = '.js-datatable-select-all';
            this.classNameSelectVisible = '.js-datatable-select-visible';
            this.classNameRemoveVisible = '.js-datatable-remove-visible';
            this.classNameSelectReset = '.js-datatable-select-reset';

            this.indexDb = new Dexie(`dataTableActions_${this.datatableName}`);
            this.indexDb.version(1).stores({ selected: 'rowId, rowSelected' });
            this.indexDbRowSelectStore = this.indexDb.selected;

            this.actions = null;
            this.countOfSelectedRows = 0;

            this.key = {
                shift: {
                    code: 16,
                    isPressed: false,
                },
                enter: {
                    code: 13,
                }
            };

            this.shiftSelection = {
                indexStart: null,
                indexEnd: null,
                indexSelection: [],
                idSelection: []
            };

            this.allIdsReceived = false;
        }
    }

    handleKeyDown = (e) => {
        if (!e.repeat && e.keyCode === this.key.shift.code) {
            e.preventDefault();
            this.key.shift.isPressed = true;
            $(`#${this.datatableName}`).addClass('h-user-select-none');
        }

        /* Select focused row with enter */
        if (!e.repeat && e.keyCode === this.key.enter.code) {
            e.preventDefault();
            $(`#${this.datatableName}`).find('.focused-row').find(this.classNameInputSelectRow).click();
        }
    };

    handleKeyUp = (e) => {
        if (!e.repeat && e.keyCode === this.key.shift.code) {
            e.preventDefault();
            this.key.shift.isPressed = false;
            $(`#${this.datatableName}`).removeClass('h-user-select-none');
        }
    };

    /**
     * Writes the value and the selected/not selected state of the checbox into the index DB
     * @param {Object} event - js event
     * @returns {Object} checkBoxInRow - optional checkbox $obj
     */
    handleSelectRow(event, checkBoxInRow) {
        let checkBox = null;
        if (checkBoxInRow) {
            checkBox = checkBoxInRow;
        } else {
            checkBox = event.currentTarget;
        }
        const rowId = $(checkBox).val();
        const rowSelected = $(checkBox).is(':checked');

        this.indexDbRowSelectStore
            .put({ rowId, rowSelected })
            .then(() => {
                this.countSelectedRows(() => {
                    this.disableActions();
                    this.displayCountOfSelectedRows();
                });

                this.syncCheckHooks(rowId, rowSelected, false);
            }).catch((error) => {
                console.error(error);
            })
        ;
    }

    /**
     * Sets all index DB entries we have to the given selections state (bool).
     * Also updated the UI to reflect this change for the checkboxed,
     * counters, and available actions.
     * @param {Bool} isChecked - selection state of the row
     */
    handleMarkAll(isChecked) {
        this.indexDbRowSelectStore.toArray((tableAsArray) => {
            const checkedArray = tableAsArray.map(
                (entry) => {
                    entry.rowSelected = isChecked;
                    return entry;
                }
            );
            this.indexDbRowSelectStore.bulkPut(checkedArray).then(() => {
                this.countSelectedRows(() => {
                    this.disableActions();
                    this.displayCountOfSelectedRows();
                });
                this.indexDbRowSelectStore.each((loadedEntry) => {
                    this.syncCheckHooks(loadedEntry.rowId, isChecked);
                }).catch((error) => {
                    console.error(error.stack);
                });
            }).catch((err) => {
                console.error(err);
            });
        }).catch((err) => {
            console.error(err);
        });
    }

    /**
     * Sets all index DB entries, according to the currently visible rows on screen, to the given selections state (bool).
     * Also updated the UI to reflect this change for the checkboxed,
     * counters, and available actions.
     * @param {Bool} isChecked - selection state of the row
     */
    handleMarkVisibel(isChecked) {
        $(this.classNameInputSelectRow).each((index, checkBox) => {
            const checkBoxItem = {};
            checkBoxItem.rowId = $(checkBox).val();
            checkBoxItem.rowSelected = isChecked;
            this.indexDbRowSelectStore
                .put({ rowId: checkBoxItem.rowId, rowSelected: checkBoxItem.rowSelected })
                .then(() => {
                    this.syncCheckHooks(checkBoxItem.rowId, checkBoxItem.rowSelected);
                    this.countSelectedRows(() => {
                        this.disableActions();
                        this.displayCountOfSelectedRows();
                    });
                }).catch((error) => {
                    console.error(error);
                })
            ;
        });
    }

    /**
     * Sets the given checkbox to an "checked" state. Also adds a css class to the corresponding row.
     * @param {Number|string} rowId - id of the input we like to change
     * @param {Bool} isChecked - selection state of the row
     * @param {Bool} changeCheckbox - should the state of the checkbox be changed or not
     */
    syncCheckHooks(rowId, isChecked, changeCheckbox = true) {
        if (changeCheckbox) {
            $(`${this.classNameInputSelectRow}[value=${rowId}]`).prop('checked', isChecked);
        }

        if (isChecked === true) {
            $(`${this.classNameInputSelectRow}[value=${rowId}]`).closest('tr').addClass('selected');
        } else {
            $(`${this.classNameInputSelectRow}[value=${rowId}]`).closest('tr').removeClass('selected');
        }
    }

    /**
     * Receives the amount of selected rows from index DB,
     * @param {func} callBack - optional call back function
     */
    countSelectedRows(callBack) {
        this.indexDbRowSelectStore
            .filter(row => row.rowSelected === true)
            .count((countOfSelectedRows) => {
                this.countOfSelectedRows = countOfSelectedRows;
                if (typeof callBack === 'function') {
                    callBack();
                }
            });
    }

    /**
     * Updates UI to reflect the current total count of selected rows.
     */
    displayCountOfSelectedRows() {
        if ($(this.classNameSelectedRowsCount).length > 0) {
            $(this.classNameSelectedRowsCount).text(this.countOfSelectedRows);
        }
    }

    /**
     * Some UI magic to prevent the user from clicking on stuff
     * which has no use on a given time.
     */
    disableActions() {
        if (this.countOfSelectedRows > 0) {
            this.hostElement.classList.remove('h-disabled');
            $(this.classNameRemoveVisible).removeClass('h-disabled');
            $(this.classNameSelectReset).removeClass('h-disabled');
        } else {
            this.hostElement.classList.add('h-disabled');
            $(this.classNameRemoveVisible).addClass('h-disabled');
            $(this.classNameSelectReset).addClass('h-disabled');
        }
    }

    /**
     * First we clear the index DB, than we reveive ALL the available id's for the current topic.
     * We use this later to persist the state of the checkboxes a cross all pagination pages and
     * to realize the "select ALL" option.
     */
    receiveAllIds() {
        if (this.allIdsReceived === false) {
            this.indexDbRowSelectStore.clear().then(() => {
                axios({
                    url: this.datatableUrlToFetchAllIds,
                    method: 'post',
                    headers: { 'X-Requested-With': 'XMLHttpRequest' }
                }).then((response) => {
                    response.data.forEach((receivedId) => {
                        this.indexDbRowSelectStore.put({ rowId: receivedId.toString(), rowSelected: false }).catch((error) => {
                            console.error(error);
                        });
                    });
                    this.allIdsReceived = true;
                }).catch((error) => {
                    console.error(error);
                });
            });
        }
    }

    getActions() {
        const multiActions = window.dataTableInstances[this.datatableName].multiActions;
        if (Array.isArray(multiActions)) {
            return multiActions;
        }
        console.error('did not received multiActions!');
        return null;
    }

    selectRange() {
        if (this.shiftSelection.indexStart !== null && this.shiftSelection.indexEnd !== null) {
            if (this.shiftSelection.indexEnd < this.shiftSelection.indexStart) {
                for (let i = this.shiftSelection.indexStart; i >= this.shiftSelection.indexEnd; i--) {
                    if (!this.shiftSelection.indexSelection.includes(i)) {
                        this.shiftSelection.indexSelection.push(i);
                    }
                }
            } else if (this.shiftSelection.indexEnd > this.shiftSelection.indexStart) {
                for (let i = this.shiftSelection.indexStart; i <= this.shiftSelection.indexEnd; i++) {
                    if (!this.shiftSelection.indexSelection.includes(i)) {
                        this.shiftSelection.indexSelection.push(i);
                    }
                }
            }

            if (this.shiftSelection.indexSelection.length > 0) {
                this.shiftSelection.indexSelection.forEach((index) => {
                    const $checkbox = $(`#${this.datatableName}`)
                        .find('tbody')
                        .find('tr')
                        .find(this.classNameInputSelectRow)
                    ;

                    const rowId = $checkbox[index].value;
                    if (!this.shiftSelection.idSelection.includes(rowId)) {
                        this.shiftSelection.idSelection.push(rowId);
                    }
                });
            }

            if (this.shiftSelection.idSelection.length > 0) {
                this.shiftSelection.idSelection.forEach((rowId) => {
                    this.indexDbRowSelectStore
                        .put({ rowId, rowSelected: true })
                        .then(() => {
                            this.countSelectedRows(() => {
                                /* Callbacks after indexDB Operation */

                                this.disableActions();
                                this.displayCountOfSelectedRows();
                                this.syncCheckHooks(rowId, true);

                                this.shiftSelection.indexSelection = [];
                                this.shiftSelection.idSelection = [];
                                this.shiftSelection.indexStart = null;
                                this.shiftSelection.indexEnd = null;
                                $('.range-selection').removeClass('range-selection');
                            });
                        }).catch((error) => {
                            console.error(error);
                        })
                    ;
                });
            }
        }
    }

    /**
     * Stuff that needs to happen after each dom change of the dataTable and after dataTable is loaded completly.
     * Mostly JS event handler shit. This method is triggert by the sg datatable as a callback after each "page draw".
     */
    dataTableDrawCallBack() {
        this.receiveAllIds();

        if (this.hostElement != null) {
            if (!this.root) {
                this.root = createRoot(this.hostElement);
            }
            this.root.render(
                <ActionHandlerComponent
                    actions={this.getActions()}
                    indexDbRowSelectStore={this.indexDbRowSelectStore}
                    datatableName={this.datatableName}
                />
            );
        }

        if (this.indexDbRowSelectStore !== undefined) {
            this.indexDbRowSelectStore.each((entry) => {
                this.syncCheckHooks(entry.rowId, entry.rowSelected);
            });

            $(`#${this.datatableName}`).find('tbody').find('tr').on('click', (event) => {
                if (this.key.shift.isPressed) {
                    event.stopPropagation();
                    /* Set select Range */
                    if (this.shiftSelection.indexStart === null && this.shiftSelection.indexEnd === null) {
                        this.shiftSelection.indexStart = $(event.currentTarget).index();
                        $(event.currentTarget).addClass('range-selection');
                    } else if (this.shiftSelection.indexStart !== null && this.shiftSelection.indexEnd === null) {
                        this.shiftSelection.indexEnd = $(event.currentTarget).index();
                    }
                    this.selectRange();
                } else {
                    const checkBox = $(event.currentTarget).find(this.classNameInputSelectRow);
                    // Unfortunately we need to set a timeout, since we are not able to hook into the select of a row directly.
                    setTimeout(() => { this.handleSelectRow(event, checkBox); }, 50);
                }
            });

            $(this.classNameInputSelectRow).on('change', (event) => {
                this.handleSelectRow(event);
            });

            $(this.classNameSelectVisible).on('click', () => {
                this.handleMarkVisibel(true);
            });

            $(this.classNameRemoveVisible).on('click', () => {
                this.handleMarkVisibel(false);
            });

            $(this.classNameSelectAll).on('click', () => {
                this.handleMarkAll(true);
            });

            $(this.classNameSelectReset).on('click', () => {
                this.handleMarkAll(false);
            });

            $(this.classNameToolbar).removeClass('d-none');
        }
    }

    init() {
        if (this.hostElement) {
            window.addEventListener('keydown', this.handleKeyDown, false);
            window.addEventListener('keyup', this.handleKeyUp, false);
        }
    }
}

export default new DataTableActions_new();