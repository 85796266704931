/* eslint-disable consistent-return */

import dataTablesMainInit from '../../../../../../LayoutBundle/js/dataTablesMainInit';
import {
    renderTicket,
    renderYesNoChoice
} from '../../../dToutputRenderer';

const $ = window.jQuery;

const Translator = typeof window.Translator !== 'undefined' ? window.Translator : { trans: key => key };
const trans = rootKey => key => Translator.trans(`${rootKey}.${key}`, 'datatable');

const t = trans('ticket');

$(document).ready(() => {
    const dTsettings = {};

    dTsettings.tableName = 'contract_order_order_position_ticket_datatable';
    dTsettings.selector = `#${dTsettings.tableName}`;

    if ($(dTsettings.selector).length === 0) return false;

    dTsettings.options = {
        order: [[3, 'desc'], [0, 'desc']],
        orderCellsTop: true,
    };

    dTsettings.extensions = {
        colReorder: {
            fixedColumnsLeft: 1
        },
    };

    dTsettings.columns = {
        columns: [
            {
                title: t('title.id'),
                searchable: true,
                orderable: true,
                data: 'id',
                name: 'id',
                render: renderTicket,
            },
            {
                title: t('title.registration_code'),
                searchable: true,
                orderable: true,
                data: 'registration_code',
                name: 'registration_code',
            },
            {
                title: t('title.short_code'),
                searchable: true,
                orderable: true,
                data: 'short_code',
                name: 'short_code',
            },
            {
                title: t('title.consecutive_number'),
                searchable: true,
                orderable: true,
                data: 'consecutive_number',
                name: 'consecutive_number',
            },
            {
                title: t('title.entrance_ability'),
                searchable: true,
                orderable: false,
                data: 'entrance_ability',
                name: 'entrance_ability',
                render: renderYesNoChoice,
            },
            {
                title: t('title.registration_ability'),
                searchable: true,
                orderable: false,
                data: 'registration_ability',
                name: 'registration_ability',
                render: renderYesNoChoice,
            },
        ]
    };

    dataTablesMainInit(dTsettings);
});
