import $ from 'jquery';

const resetBtnTxt = window.Translator.trans('generic.button.reset_value');

$(document).ready(() => {
    const $removeFields = $('.js-reset-value-button');

    $removeFields.each(function () {
        const resetFieldId = $(this).attr('id');
        $(this)
            .parent()
            .removeClass('col-sm-10')
            .addClass('col p-r-0')
            .parent()
            .append(`
                    <div class="col-sm-3 p-l-0">
                        <span class="js-reset-btn btn btn-small btn-primary w-100" data-targetid="${resetFieldId}">
                            <i class="fas fa-angle-left"></i> ${resetBtnTxt}
                        </span>
                    </div>
                `)
        ;
    });

    $(document).on('click', '.js-reset-btn', function () {
        const targetId = $(this).data('targetid');
        const $target = $(`#${targetId}`);
        $target.val(null);
    });
});
