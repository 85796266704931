import React from 'react';
import { createRoot } from 'react-dom/client';
import DataSheet from './DataSheet';

const hostEle = document.getElementById('js-datasheet');
if (hostEle) {
    const root = createRoot(hostEle);
    root.render(
        <DataSheet buttons={JSON.parse(hostEle.dataset.buttons)} role={hostEle.dataset.role.trim()} />
    );
}