/* eslint-disable no-unused-vars */

import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import ActionBar from '../ActionBar/ActionBar';
import CardCoreData from '../CardCoreData/CardCoreData';
import CardDisplay from '../CardDisplay/CardDisplay';
import CardProductVariants from '../CardProductVariants/CardProductVariants';
import CardNotes from '../CardNotes/CardNotes';
import CardTags from '../CardTags/CardTags';
import CardPlanning from '../CardPlanning/CardPlanning';
import { dateToUI } from '../CardPlanning/dateFormatter';

import LoadingIndicator from './LoadingIndicator';
import Errors from './Errors';
import {
    subEventDetails,
    subEventConfig,
} from '../../api';
import {
    setIsPending,
    setConfigIsPending,
    setClientId,
    setEventId,
    setConfig,
} from './shellReducer';
import {
    setStatus,
    setDisplayMode,
    setInEditMode,
    setLocales,
} from '../ActionBar/actionBarReducer';
import {
    setName,
    setSubEventId,
} from '../CardCoreData/coreDataReducer';
import {
    setParticipationType,
    setDurationType,
    setTimeZone,
    setStartDate,
    setEndDate,
    setTimeTables,
    setGlobalQuota,
    setEntranceTimeOffset,
    setEntranceTimeInheritedOffset,
} from '../CardPlanning/planningReducer';
import {
    setDescription,
    setDisplayName,
} from '../CardDisplay/displayReducer';
import {
    setVariants,
} from '../CardProductVariants/productVariantsReducer';

export const propTypes = {
    clientId: PropTypes.number.isRequired,
    eventId: PropTypes.number.isRequired,
    subEventId: PropTypes.number,
    locales: PropTypes.arrayOf(PropTypes.string).isRequired,
    editMode: PropTypes.string,
};

export const defaultProps = {
    subEventId: null,
    editMode: null,
};

export const requestData = async (dispatch, props) => {
    dispatch(setIsPending(true));
    try {
        const response = await subEventDetails(
            'get', {
                id: props.subEventId,
                event: props.eventId,
            }
        );
        const { data } = response;
        dispatch(setStatus(data.status));
        dispatch(setDisplayMode(data.display_mode));
        dispatch(setName(data.name));
        dispatch(setParticipationType(data.participation_type));
        dispatch(setEntranceTimeOffset(data.entrance_time_offset_without_inheritance));
        dispatch(setDurationType(data.duration_type));
        dispatch(setTimeZone(data.event.timezone));


        if (data.sub_event_variants.length > 0) {
            const variants = data.sub_event_variants.map(v => ({
                id: v.id,
                gross_amount: v.gross_amount,
                variant_id: v.variant.id,
                name: v.variant.name,
                product_no: v.variant.product_no,
            }));

            dispatch(setVariants(variants));
        }

        if (data.sub_event_plannings.length > 0) {
            dispatch(setTimeTables(data));
            dispatch(setGlobalQuota(data.sub_event_plannings[0].quota));
        }

        if (props.subEventId) {
            const descs = data.description ? Object.entries(data.description) : [];
            if (descs.length > 0) {
                descs.forEach(([key, value]) => {
                    dispatch(setDescription({ lang: key, description: value }));
                });
            }

            const names = data.display_name ? Object.entries(data.display_name) : [];
            if (names.length > 0) {
                names.forEach(([key, value]) => {
                    dispatch(setDisplayName({ lang: key, displayName: value }));
                });
            }
        }
    } catch (error) {
        console.error('ERROR', error);
    } finally {
        dispatch(setIsPending(false));
    }
};

const SubEventDetailsShell = (props) => {
    const dispatch = useDispatch();

    const requestConfig = async () => {
        dispatch(setConfigIsPending(true));
        try {
            const response = await subEventConfig({ event: props.eventId });
            const { data } = response;
            dispatch(setConfig(data));
            dispatch(setEntranceTimeInheritedOffset(data.event.entrance_time_offset_sub_event));
            dispatch(setStartDate(data.event.start_date));
            dispatch(setEndDate(data.event.end_date));
        } catch (error) {
            console.error('ERROR', error);
        } finally {
            dispatch(setConfigIsPending(false));
        }
    };


    // on mount
    useEffect(() => {
        dispatch(setClientId(props.clientId));
        dispatch(setEventId(props.eventId));
        dispatch(setLocales(props.locales));

        requestConfig();

        // start with edit mode
        if (props.editMode === 'active') {
            dispatch(setInEditMode(true));
        }

        // existing sub event
        if (props.subEventId) {
            requestData(dispatch, props);
            dispatch(setSubEventId(props.subEventId));

        // new sub event
        } else {
            dispatch(setInEditMode(true));
            props.locales.forEach((locale) => {
                dispatch(setDescription({ lang: locale, description: '<p></p>' }));
                dispatch(setDisplayName({ lang: locale, displayName: ' ' }));
            });
        }
    }, []);

    return (
        <div id="SubEventDetailsShell">
            <div>
                <LoadingIndicator />
                <ActionBar />
                <Errors />
                <div className="row">
                    <div className="col-md-12 col-lg-3">
                        <CardCoreData />
                        <CardDisplay />
                        <CardProductVariants />
                    </div>
                    <div className="col-md-12 col-lg-9">
                        <CardPlanning />
                    </div>
                    {/* <div className="col-md-12 col-lg-4">
                        <CardNotes />
                        <CardTags />
                    </div> */}
                </div>
            </div>
        </div>
    );
};

SubEventDetailsShell.propTypes = propTypes;
SubEventDetailsShell.defaultProps = defaultProps;

export default SubEventDetailsShell;
