/* eslint-disable consistent-return */

import dataTablesMainInit, { renderLink } from '../../../../../LayoutBundle/js/dataTablesMainInit';
import {
    rendererContactPerson,
    rendererBusinessPartner,
    renderTags,
    renderButtons,
    renderYesNoChoice,
    isTestTransaction,
    renderOrderPosition,
} from '../../dToutputRenderer';

const $ = window.jQuery;

const Translator = typeof window.Translator !== 'undefined' ? window.Translator : { trans: key => key };
const trans = rootKey => key => Translator.trans(`${rootKey}.${key}`, 'datatable');

const t = trans('contract');

$(document).ready(() => {
    const dTsettings = {};

    dTsettings.tableName = 'contract_order_management_contract_datatable';
    dTsettings.selector = `#${dTsettings.tableName}`;

    if ($(dTsettings.selector).length === 0) return false;

    dTsettings.options = {
        order: [[2, 'desc']],
        orderCellsTop: true,
    };

    dTsettings.extensions = {
        colReorder: {
            fixedColumnsLeft: 1
        },
    };

    dTsettings.columns = {
        columns: [
            {
                title: t('title.event'),
                searchable: true,
                orderable: true,
                data: 'event',
                name: 'event',
                render: renderLink,
            },
            {
                title: t('title.tags'),
                searchable: true,
                orderable: false,
                data: 'tags',
                name: 'tags',
                render: renderTags,
            },
            {
                title: t('title.source_id'),
                searchable: true,
                orderable: true,
                data: 'source_id',
                name: 'source_id',
                render: renderOrderPosition,
            },
            {
                title: t('title.status'),
                searchable: true,
                orderable: false,
                data: 'status',
                name: 'status',
            },
            {
                title: t('title.test'),
                searchable: true,
                orderable: false,
                data: 'test',
                name: 'test',
                render: isTestTransaction,
            },
            {
                title: t('title.primary_business_partner'),
                searchable: true,
                orderable: true,
                data: 'primary_business_partner',
                name: 'primary_business_partner',
                render: rendererBusinessPartner,
            },
            {
                title: t('title.primary_contact_person'),
                searchable: true,
                orderable: true,
                data: 'primary_contact_person',
                name: 'primary_contact_person',
                render: rendererContactPerson,
            },
            {
                title: t('title.secondary_business_partner'),
                searchable: true,
                orderable: true,
                data: 'secondary_business_partner',
                name: 'secondary_business_partner',
                render: rendererBusinessPartner,
            },
            {
                title: t('title.secondary_contact_person'),
                searchable: true,
                orderable: true,
                data: 'secondary_contact_person',
                name: 'secondary_contact_person',
                render: rendererContactPerson,
            },
            {
                title: t('title.fair_package'),
                searchable: true,
                orderable: false,
                data: 'fair_package',
                name: 'fair_package',
                render: renderYesNoChoice,
            },
            {
                searchable: false,
                orderable: false,
                data: 'action_links',
                name: 'action_links',
                render: renderButtons,
            },
        ]
    };

    dataTablesMainInit(dTsettings);
});
