/* eslint-disable consistent-return */

const $ = window.jQuery;

const createDateRangeSearch = (tableName) => {
    const $dateRangeSelector = $(`#${tableName}`).find('.js-date-range-search');
    if (typeof $dateRangeSelector === 'undefined' || $dateRangeSelector instanceof $ === false) return false;

    const dateRangeSearchInstances = [];
    class dateRangeSearch {
        constructor(host) {
            this.$host = host;

            this.styles = {
                input: /* css */`
                    width: 106px;
                    height: 24px;
                    display: inline;
                `,
                wrapper: /* css */`
                    background-color: #f0f0f0;
                    border: 1px solid #ddd;
                `,
            };

            this.state = {
                currentMinDate: '',
                currentMaxDate: '',
            };

            this.dataTableApi = window.dataTableInstances[tableName].api;

            if (this.$host.attr('data-from-display') !== 'null') {
                this.state.currentMinDate = this.$host.attr('data-from-display').substring(0, 10);
            }
            if (this.$host.attr('data-to-display') !== 'null') {
                this.state.currentMaxDate = this.$host.attr('data-to-display').substring(0, 10);
            }

            this.init();
        }

        render = () => {
            this.$host.html(/* html */`
                <div style="${this.styles.wrapper}">
                    <input
                        class="js-date-range form-control form-control-sm"
                        data-range="min"
                        style="${this.styles.input}"
                        type="date"
                        max="${this.state.currentMaxDate}"
                        value="${this.state.currentMinDate}"
                    >
                    -
                    <input
                        class="js-date-range form-control form-control-sm"
                        data-range="max"
                        style="${this.styles.input}"
                        type="date"
                        min="${this.state.currentMinDate}"
                        value="${this.state.currentMaxDate}"
                    >
                </div>
            `);
        };

        sendSearchRequest(colIdx) {
            const minTime = this.state.currentMinDate === '' ? '' : 'T00:00:00+00:00';
            const maxTime = this.state.currentMaxDate === '' ? '' : 'T23:59:59+00:00';
            const separator = this.state.currentMinDate === '' && this.state.currentMaxDate === '' ? '' : ',';

            this.dataTableApi
                .column(parseInt(colIdx, 10))
                .search(`${this.state.currentMinDate}${minTime}${separator}${this.state.currentMaxDate}${maxTime}`)
                .clearPipeline()
                .draw()
            ;
        }

        init() {
            this.render();

            // Reqister Events
            this.$host.on('change', '.js-date-range', (e) => {
                const rangeMode = $(e.currentTarget).attr('data-range');
                if (rangeMode === 'min') {
                    this.state.currentMinDate = $(e.currentTarget).val() || '';
                } else if (rangeMode === 'max') {
                    this.state.currentMaxDate = $(e.currentTarget).val() || '';
                }
                this.render();
                this.sendSearchRequest(this.$host.attr('data-col-idx'));
            });

            /**
             * Firefox bug fix since FF does not trigger change a event when clicking the native
             * clear date button without a focused input ¯\_(ツ)_/¯
             */
            this.$host.on('mousedown', '.js-date-range', (e) => {
                $(e.currentTarget).focus();
            });
        }
    }

    $('body').find($dateRangeSelector).each((index, element) => {
        dateRangeSearchInstances.push(new dateRangeSearch($(element)));
    });

    return dateRangeSearchInstances;
};

export default createDateRangeSearch;
