import $ from 'jquery';

export class MiofairContactPerson {
    enableDisableButtons() {
        $('.js-contact-person-email-address-readonly').each(function () {
            $(this).parent()
                .find('a.contact_person_shippingEmailAddresses-collection-remove')
                .css('pointer-events', 'none')
                .css('opacity', '0.4');
        });
    }

    init() {
        $(document).ready(() => {
            this.enableDisableButtons();
        });
    }
}

export default new MiofairContactPerson();
