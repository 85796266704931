/* eslint-disable consistent-return */

import dataTablesMainInit from '../../../../LayoutBundle/js/dataTablesMainInit';
import {
    renderTags,
    rendererBusinessPartner,
    rendererSourceId
} from '../dToutputRenderer';

const $ = window.jQuery;

const Translator = typeof window.Translator !== 'undefined' ? window.Translator : { trans: key => key };
const trans = rootKey => key => Translator.trans(`${rootKey}.${key}`, 'datatable');

const t = trans('business_partner');

$(document).ready(() => {
    const dTsettings = {};

    dTsettings.tableName = 'business_partner_datatable';
    dTsettings.selector = `#${dTsettings.tableName}`;

    if ($(dTsettings.selector).length === 0) return false;

    dTsettings.options = {
        order: [[4, 'desc']],
        orderCellsTop: true,
    };

    dTsettings.extensions = {
        colReorder: {},
    };

    dTsettings.columns = {
        columns: [
            {
                title: t('title.tags'),
                searchable: true,
                orderable: true,
                data: 'tags',
                name: 'tags',
                render: renderTags,
            },
            {
                title: t('title.source'),
                searchable: true,
                orderable: true,
                data: 'source',
                name: 'source',
            },
            {
                title: t('title.name'),
                searchable: true,
                orderable: true,
                data: 'names',
                name: 'names',
                render: rendererBusinessPartner,
            },
            {
                title: t('title.source_id'),
                searchable: true,
                orderable: true,
                data: 'source_id',
                name: 'source_id',
                render: rendererSourceId,
            },
            {
                title: t('title.id'),
                searchable: true,
                orderable: true,
                data: 'id',
                name: 'id',
            },
            {
                title: t('title.country'),
                searchable: true,
                orderable: true,
                data: 'country',
                name: 'country',
            },
            {
                title: t('title.status'),
                searchable: true,
                orderable: true,
                data: 'status',
                name: 'status',
            },
        ]
    };
    dTsettings.multiActions = [];

    dataTablesMainInit(dTsettings);
});
