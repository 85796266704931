/* eslint-disable */

import React, { useEffect, useContext, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import { ThreeDots } from 'react-loader-spinner';
import { v4 as uuidv4 } from 'uuid';
import GlobalContext from './state/GlobalContext';

const Translator = typeof window.Translator !== 'undefined' ? window.Translator : { trans: key => key };
const Routing = typeof window.Routing !== 'undefined' ? window.Routing : { generate: route => route };

const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16
};

const loaderWrapper = {
    position: 'absolute',
    background: '#ffffffd1',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    zIndex: 9999,
}

const loader = {
    height: 50,
    position: 'absolute',
    bottom: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: '1337',
}

const tdStyle = {
    borderBottomColor: 'rgb(214, 214, 214)',
    borderBottomStyle: 'solid',
    borderBottomWidth: 1,
}

const uploadBtnWrapper = {
    background: 'rgb(255, 255, 255)',
    position: 'sticky',
    bottom: '-4px',
    paddingBottom: 5,
    paddingTop: 21,
}

const uploadBtn = {
    display: 'block',
    margin: '0 auto',
    padding: '10px 20px',
    fontSize: '1.2rem',
    fontWeight: 'bold',
    marginBottom: '15px'
}

const dropZone = {
    border: '1px dashed #00AEEF',
    borderRadius: 5,
    textAlign: 'center',
    fontWeight: 'bold',
    color: '#00AEEF',
    padding: '10vh 0',
}

const fileUploadSuccessWrapper = {
    background: 'rgb(255 255 255 / 78%)',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    position: 'absolute',
    zIndex: 2,
    textAlign: 'center',
    border: '2px solid #67ac64',
    borderRadius: 5,
};

const fileUploadSuccessIcon = {
    color: 'rgb(68, 156, 68)',
    fontSize: 34,
    opacity: 0.8,
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-17px, -17px)',
};

const td = {
    color: 'rgb(68, 156, 68)',
    fontSize: 34,
    opacity: 0.8,
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-17px, -17px)',
};

const Uploader = (props) => {
    const [getClientId, getEventId] = useContext(GlobalContext);

    const sendFilesUrl = Routing.generate('filemanager_api_upload');

    const [files, setFiles] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const fileExtensionPattern = new RegExp(/\.[^/.]+$/);

    const { getRootProps, getInputProps } = useDropzone({
        // accept: 'image/*',
        onDrop: acceptedFiles => {
            setFiles([
                ...files,
                ...acceptedFiles.map(file => Object.assign(file, {
                    File: URL.createObjectURL(file),
                    filename: file.name.replace(fileExtensionPattern, ''),
                    fileextension: fileExtensionPattern.exec(file.name),
                    note: '',
                    force: false,
                    uploadId: uuidv4(),
                    errors: [],
                    uploadComplete: false,
                    showForceOption: false,
                }))
            ]
            );

        }
    });

    const handleFileMetaChange = (uploadId, property, value) => {
        const currentFiles = [...files];
        const fileObj = currentFiles.find(f => f.uploadId === uploadId);
        fileObj[property]= value;
        setFiles(currentFiles);
    };

    const handleDeleteFileFromList = (uploadId) => {
        const filesFiltered = files.filter(f => f.uploadId !== uploadId);
        setFiles(filesFiltered);
    };

    const sendFiles = async () => {
        let formData = new FormData()

        formData.append('_cid', getClientId);
        formData.append('_eid', getEventId);

        files.forEach(file => {
            if (file.uploadComplete === false) {
                formData.append(`files_upload[${file.uploadId}]`, file, file.name)
                formData.append(`files_upload[${file.uploadId}][filename]`, file.filename + file.fileextension)
                formData.append(`files_upload[${file.uploadId}][note]`, file.note)
                formData.append(`files_upload[${file.uploadId}][force]`, file.force)
            }
        })
        setIsLoading(true);
        axios.post(sendFilesUrl, formData).then(response => {
            setIsLoading(false);
            for (const [key, value] of Object.entries(response.data.uploaded_files)) {
                if (value.errors.length > 0) {
                    handleFileMetaChange(key, 'errors', value.errors);
                    if (value.errors.includes('filemanager.filename_unique_validation.filename_already_existing')) {
                        handleFileMetaChange(key, 'showForceOption', true);
                    }
                } else {
                    handleFileMetaChange(key, 'uploadComplete', true);
                    handleFileMetaChange(key, 'errors', []);
                    handleFileMetaChange(key, 'showForceOption', false);
                }
            }

            setIsLoading(false);
        }).catch((error) => {
            setIsLoading(false);
            const translatedErrors = error.response.data.errors.map(x => (Translator.trans(x)));
            alert(translatedErrors.join('\n'));
        });
    };

    useEffect(() => () => {
        // Make sure to revoke the data uris to avoid memory leaks
        files.forEach(file => URL.revokeObjectURL(file.File));
    }, [files]);

    return (
        <section {...props}>
            <div className="position-relative">
                <div className="container">
                    <div style={dropZone} {...getRootProps({ className: 'dropzone' })}>
                        <input {...getInputProps()} />
                        <p>
                            Ziehen Sie Dateien auf dieses Feld,
                            <br />
                            oder klicken Sie es an um Dateien auszuwählen.
                        </p>
                    </div>
                    <aside style={thumbsContainer}>

                        {isLoading && (
                            <div className="loaderWrapper" style={loaderWrapper}>
                                <div style={loader}>
                                    <ThreeDots
                                        color="#93dcfc"
                                        height="100"
                                        width="100"
                                    />
                                </div>
                            </div>
                        )}

                        {files.length > 0 && (
                            <table style={{ width: '100%', marginBottom: '15px' }}>
                                <thead>
                                    <tr>
                                        <th>Vorschau</th>
                                        <th>Dateiname</th>
                                        <th>Größe</th>
                                        <th>Anmerkung</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {files.map(file => (
                                        <React.Fragment key={file.uploadId}>
                                            <tr>
                                                <td
                                                    style={{
                                                        position: 'relative', borderBottomColor: 'rgb(214, 214, 214)',
                                                        borderBottomStyle: 'solid',
                                                        borderBottomWidth: 1,
                                                    }} key={file.name}>
                                                    {file.type && file.type.toLowerCase().includes('image') && (
                                                        <img style={{ maxWidth: '100px', maxHeight: '100px' }} src={file.File} alt="Vorschau"
                                                        />
                                                    )}

                                                    {file.type && file.type.toLowerCase().includes('pdf') && (
                                                        <i style={{ fontSize: '50px' }} className="fas fa-file-pdf" />
                                                    )}

                                                    {file.type && !file.type.toLowerCase().includes('image') && !file.type.toLowerCase().includes('pdf') && (
                                                        <i style={{ fontSize: '50px' }} className="fas fa-file" />
                                                    )}

                                                    {file.uploadComplete === true && (
                                                        <div style={fileUploadSuccessWrapper}>
                                                            <i style={fileUploadSuccessIcon}
                                                                className="fas fa-check-circle"
                                                            />
                                                        </div>
                                                    )}
                                                </td>

                                                <td style={tdStyle}>
                                                    <label className="w-100">
                                                        <input readOnly={file.uploadComplete} value={file.filename} onChange={(e) => handleFileMetaChange(file.uploadId, 'filename', e.target.value)} />
                                                        {file.fileextension}
                                                    </label>
                                                </td>
                                                <td style={tdStyle}>
                                                    {Math.round(file.size / 1000)} KB
                                                </td>
                                                <td style={tdStyle}>
                                                    <input className="w-100" readOnly={file.uploadComplete} onChange={(e) => handleFileMetaChange(file.uploadId, 'note', e.target.value)} />
                                                </td>
                                                <td style={tdStyle}>
                                                    <button disabled={file.uploadComplete} className="btn btn-sm btn-primary float-right" onClick={() => handleDeleteFileFromList(file.uploadId)}>
                                                        <i className="fas fa-trash-alt" />
                                                    </button>
                                                </td>
                                            </tr>
                                            {file.errors.length > 0 && (
                                                <tr>
                                                    <td colSpan="5">
                                                        {file.errors.map(er => (
                                                            <div key={er} className="m-b-15 ">
                                                                <i className="text-warning fas fa-exclamation-triangle" />
                                                                <span className="text-warning font-weight-bold m-r-10">{Translator.trans(er)}</span>

                                                                {file.showForceOption && (
                                                                    <label>
                                                                        <input type="checkbox"
                                                                            checked={file.force}
                                                                            onChange={(e) => handleFileMetaChange(file.uploadId, 'force', !file.force)}
                                                                            className="m-r-2"
                                                                        /> Überschreiben
                                                                    </label>
                                                                )}
                                                            </div>
                                                        ))}
                                                    </td>
                                                </tr>
                                            )}
                                        </React.Fragment>
                                    ))
                                    }
                                </tbody>
                            </table>
                        )}
                    </aside>

                    {files.length > 0 && (
                        <div style={uploadBtnWrapper} >
                            <button style={uploadBtn} className="btn btn-lg btn-primary" onClick={sendFiles} disabled={!files.length}>
                                Hochladen
                            </button>
                        </div>
                    )}

                </div>
            </div>
        </section>
    );
};

export default Uploader;