/* eslint-disable */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Skeleton from 'react-loading-skeleton';
import FileListing from './FileListing';
import Uploader from './Uploader';
import GlobalContext from './state/GlobalContext';

const Routing = typeof window.Routing !== 'undefined' ? window.Routing : { generate: route => route };

const viewFileListing = 'FileListing';
const viewUploader = 'Uploader';

const MediaManagerShell = (props) => {
    const stylesCloseBtn = {
        position: 'absolute',
        right: '15px',
        top: '15px',
        cursor: 'pointer',
        color: '#00AEEF',
        zIndex: 90210
    };

    const stickyWrapper = {
        position: 'sticky',
        top: 0,
        zIndex: 99,
        borderBottom: '2px solid #fff',
    }

    const selectGroup = {
        float: 'left',
        marginRight: '20px',
        marginLeft: '15px',
        marginTop: '3px',
    }

    const [getEventId, setEventIdState] = useState(parseInt(props.eid) || null);
    const [getClientId, setClientIdState] = useState(parseInt(props.cid) || null);
    const [getSelectInputId] = useState(props.selectinputid);
    const [getSelectedItem, setSelectedItem] = React.useState(null);

    const [getFilesIsLoading, setFilesIsLoading] = React.useState(false);

    const [isLoading, setIsLoading] = useState(false);

    const [config, setConfig] = useState([]);
    const [errors, setErrors] = useState([]);
    const [getCurrentView, setCurrentView] = useState('FileListing');

    const getConfigUrl = Routing.generate('filemanager_api_list_configuration', {
        _cid: getClientId,
        _eid: getEventId
    });

    const setClientId = (cid) => {
        if (config.clients.map((client)=> client.id).includes(cid)) {
            setClientIdState(cid);
        } else {
            // set no cid
            setClientIdState(null);
        }
        setEventIdState(null);
    }

    const setEventId = (eid) => {
        if (config.events.map((event)=> event.id).includes(eid)) {
            setEventIdState(eid);
        } else {
            // set no eid
            setEventIdState(null);
        }
    }

    const requestConfig = ()=> {
        setIsLoading(true);
        setFilesIsLoading(true);
        axios.get(getConfigUrl).then((response) => {
            createConfigList(response.data);
            setIsLoading(false);
            setFilesIsLoading(false);
          }).catch((error) => {
                setIsLoading(false);
                setFilesIsLoading(false);
                const translatedErrors = error.response.data.errors.map(x => (Translator.trans(x)));
                console.error(translatedErrors.join('\n'));
                setErrors(error.response.data.errors.map(x => (Translator.trans(x))));
            });
    };

    useEffect(() => {
        requestConfig()
    },[getClientId]);

    useEffect(() => {
        setSelectedItem(null);
    },[getClientId, getEventId]);

    const createConfigList = (configData) => {
        setConfig(configData);
    }

    const views = {
        FileListing,
        Uploader
    }

    const CurrentView = views[getCurrentView];

    return (
        <GlobalContext.Provider value={[getClientId, getEventId, getSelectInputId, getSelectedItem, setSelectedItem, config, getFilesIsLoading, setFilesIsLoading]}>
            <div>
                {errors.length > 0 && (
                    <div className="card border fw-bold text-center">
                        <div className="card-body">
                            <ul className="mb-0 pb-0" style={{ paddingLeft: '15px' }}>
                                {errors.map(er=> (
                                    <div key={er} className="m-b-15 ">
                                        <i className="text-warning fas fa-exclamation-triangle"/>
                                        <span className="text-warning fw-bold m-l-10">{Translator.trans(er)}</span>
                                    </div>
                                ))}
                            </ul>
                        </div>
                    </div>
                )}
                <div className="justify-content-center m-b-15 p-t-5 p-b-5 bg-light"
                style={stickyWrapper}>
                    <div className="btn-group" style={selectGroup}>

                        {isLoading ? (
                            <Skeleton width={300} height={25} style={{display: "inline-block"}} />
                        ):(
                            <React.Fragment>
                                {config.clients && config.clients.length > 0 && (
                                    <select value={getClientId === null ? 'noselect' : getClientId} onChange={(e)=> setClientId(parseInt(e.target.value))}>
                                        <option value='noselect' disabled> Bitte wählen </option>
                                        {config.clients.map(c=> (
                                            <option key={c.id} value={c.id}> {c.name} </option>
                                        ))}
                                    </select>
                                )}

                                {config.events && config.events.length > 0 && (
                                    <select value={getEventId === null ? 'noselect' : getEventId} onChange={(e)=> setEventId(parseInt(e.target.value))}>
                                        <option  value='null'> Nur Mandant </option>
                                        <option disabled>──────────</option>
                                        {config.events.map(e=> (
                                            <option key={e.id} value={e.id}> {e.name} </option>
                                        ))}
                                    </select>
                                )}
                                <button disabled={isLoading} className="btn btn-sm btn-primary"  onClick={()=> {setClientId(null); setEventId(null); setErrors([])}}> Reset </button>
                            </React.Fragment>
                        )}
                    </div>

                    <ul className="nav ">
                        <li className="nav-item">
                            <a className={`nav-link ${getCurrentView === viewFileListing ? 'fw-bold disabled' : ''}`} onClick={() => setCurrentView(viewFileListing)} href="#"> Dateien</a>
                        </li>
                        <li className="nav-item"> <span></span> </li>
                        <li className="nav-item">
                            <a className={`nav-link ${getCurrentView === viewUploader ? 'fw-bold disabled' : ''}`} onClick={() => setCurrentView(viewUploader)} href="#"> Hochladen</a>
                        </li>
                    </ul>

                    <i style={stylesCloseBtn} role="button" tabIndex={0} onClick={props.closeModal} className="fas fa-times" />

                </div>

                <CurrentView className="p-r-15 p-l-15" setIsOpen={props.setIsOpen} />
            </div>
        </GlobalContext.Provider>
    );
};

export default MediaManagerShell;
