/* implementation for the deprecated DTs which are still used for some shit */
const $ = window.jQuery;
window.dataTablesKeepFocus = () => {
    const lastDtInputFocus = localStorage.getItem('lastDtInputFocus');
    const $fixedHeader = $('body').find('.dataTable.fixedHeader-floating');
    if (lastDtInputFocus !== null && lastDtInputFocus !== '#') {
        if ($fixedHeader.length > 0) {
            $fixedHeader.find(lastDtInputFocus).focus();
        } else {
            setTimeout(() => {
                $('body').find('.table.dataTable.no-footer').find(lastDtInputFocus).focus();
            }, 500);
        }
    }

    $('table.dataTable thead input').on('click', function () {
        if (typeof this.id !== 'undefined') {
            localStorage.setItem('lastDtInputFocus', `#${this.id}`);
        }
    });

    $('.dataTables_wrapper').on('click', (e) => {
        const ele = e.target.nodeName;
        if (ele.toLowerCase() !== 'input' || e.target.type === 'search') {
            localStorage.removeItem('lastDtInputFocus');
        }
    });
};
