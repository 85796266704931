/**
 * RESULT ITEM META INFO SECTION TEMPLATE
 */

import React from 'react';
import PropTypes from 'prop-types';

const Translator = window.Translator;

const resultItemMetaInfo = (props) => {
    let value = ' - ';
    if (props.value && props.value !== 'null') {
        value = String(props.value).split('\\n').map((item, i) => (
            // dangerouslySetInnerHTML is necessary to display the <mark> tag we have added to the result items data.
            <p key={props.field + i} className="m-b-0" dangerouslySetInnerHTML={{ __html: item }} />
        ));
    }

    return (
        <div className="col ps-1">
            <div className="fw-bold" title={props.field}>
                {Translator.trans(`contract.rebook.search_result.${props.field}`)}
            </div>
            <div>
                {value}
            </div>
        </div>
    );
};

resultItemMetaInfo.propTypes = {
    field: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool
    ])
};

resultItemMetaInfo.defaultProps = {
    value: ' - '
};

export default resultItemMetaInfo;
