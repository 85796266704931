/**
 * Import libraries and dependendies to use in this JS class
 */
import $ from 'jquery';

export class ProductVariant {
    constructor() {
        /**
         * Set the CSS class names for further reference
         */
        this.classPrintChoice = '.js-print-choice';
        this.classPrintRegCodeChoice = '.js-print-regCode-choice';
        this.classReBookingDate = '.js-print-reBookingDate';
        this.classPrintProvider = '.js-print-provider';
        this.classPrintDaterange = '.js-print-daterange';

        this.start_date = '#createTicketVariantStep4_ticket_print_period_printStartDate_date';
        this.start_time = '#createTicketVariantStep4_ticket_print_period_printStartDate_time';
        this.end_date = '#createTicketVariantStep4_ticket_print_period_printEndDate_date';
        this.end_time = '#createTicketVariantStep4_ticket_print_period_printEndDate_time';
        this.online_only = '#createTicketVariantStep6_ticket_onlineOnly';
        this.entrance_ability = '#createTicketVariantStep6_ticket_targetGroupRegistrationEntrance_entranceAbility';
        this.entrance_ability_span = '#entranceAbility span';
        this.entrance_group = '#createTicketVariantStep6_ticket_targetGroupEntranceTimes_entranceTimes';

        this.online_only_editing = '#ticket_variant_ticket_registrationTab_onlineOnly';
        this.entrance_ability_editing = '#ticket_variant_ticket_registrationTab_targetGroupRegistrationEntrance_entranceAbility';
        this.entrance_group_editing = '#ticket_variant_ticket_registrationTab_targetGroupEntranceTimes';
    }

    /**
     * Check are classes for print choice available
     */
    init() {
        $(document).ready(() => {
            // check if our js-classes exist in dom. otherwise exit.
            if ($(this.classPrintChoice).length > 0) {
                this.checkPrintChoice();
                this.changePrintChoice();
            }
            if ($(this.online_only).length > 0) {
                this.changeOnlineOnlyChoice();
                this.hideEntrancesChoice();
            }
            if ($(this.online_only_editing).length > 0) {
                this.changeOnlineOnlyChoiceByEditing();
                this.hideEntrancesChoiceByEditing();
            }
            this.beforeSubmit();
        });
    }

    /**
     * Check if print choice zero then hide elements
     */
    checkPrintChoice() {
        if (parseInt($(this.classPrintChoice).val(), 10) === 1 || parseInt($(this.classPrintRegCodeChoice).val(), 10) === 1) {
            this.showPrintElements();
        } else {
            this.hidePrintElements();
        }
    }

    /**
     * Event function on print change
     */
    changePrintChoice() {
        $(`${this.classPrintChoice}, ${this.classPrintRegCodeChoice}`).on('change', () => {
            this.checkPrintChoice();
        });
    }

    changeOnlineOnlyChoiceByEditing() {
        $(this.online_only_editing).on('change', (event) => {
            if (event.target.value === '1') {
                this.changeEntranceAbilityChoiceByEditingToNo();
            } else {
                this.changeEntranceAbilityChoiceByEditingToYes();
            }
        });
    }

    changeEntranceAbilityChoiceByEditingToNo() {
        $(this.entrance_ability_editing).val(0);
        $(this.entrance_group_editing).parent().hide();
    }

    changeEntranceAbilityChoiceByEditingToYes() {
        $(this.entrance_ability_editing).val(1);
        $(this.entrance_group_editing).parent().show();
    }

    hideEntrancesChoiceByEditing() {
        $(this.entrance_ability_editing).on('change', (event) => {
            if (event.target.value === '0') {
                $(this.entrance_group_editing).parent().hide();
            } else {
                $(this.entrance_group_editing).parent().show();
            }
        });
    }

    changeOnlineOnlyChoice() {
        $(this.online_only).on('change', (event) => {
            if (event.target.value === '1') {
                this.changeEntranceAbilityChoiceToNo();
            } else {
                this.changeEntranceAbilityChoiceToYes();
            }
        });
    }

    changeEntranceAbilityChoiceToNo() {
        $(this.entrance_ability).val(0);
        $(this.entrance_group).parent().hide();
        $(this.entrance_ability_span).text($(this.entrance_ability).find('option:selected').text());
    }

    changeEntranceAbilityChoiceToYes() {
        $(this.entrance_ability).val(1);
        $(this.entrance_group).parent().show();
        $(this.entrance_ability_span).text($(this.entrance_ability).find('option:selected').text());
    }

    hideEntrancesChoice() {
        $(this.entrance_ability).on('change', (event) => {
            if (event.target.value === '0') {
                $(this.entrance_group).parent().hide();
            } else {
                $(this.entrance_group).parent().show();
            }
        });
    }

    /**
     * Event function on print change
     */
    beforeSubmit() {
        $('form').on('submit', () => {
            if (this.isPrintSelected() === false) {
                this.clearPrintPeriodInput();
            }
        });
    }

    /**
     * Hide elements for print
     */
    hidePrintElements() {
        $(this.classPrintProvider).parent().parent().hide();
        $(this.classPrintDaterange).parent().parent().hide();
        $(this.classReBookingDate).parent().parent().parent()
            .hide();
        $(`${(this.classPrintDaterange)} :input`).removeAttr('required');
        $(`${(this.classPrintDaterange)} :input`).removeClass(('h-required'));
    }

    /**
     * Show elements for print
     */
    showPrintElements() {
        $(this.classPrintProvider).parent().parent().show();
        $(this.classPrintDaterange).parent().parent().show();
        $(this.classReBookingDate).parent().parent().parent()
            .show();
        $(`${(this.classPrintDaterange)} :input`).attr('required', true);
        $(`${(this.classPrintDaterange)} :input`).addClass(('h-required'));
    }
    /**
     * check if Ticket is printable
     */
    isPrintSelected() {
        return parseInt($(this.classPrintChoice).val(), 10) !== 0;
    }

    /**
     * clear print period values
     */
    clearPrintPeriodInput() {
        $(this.start_date).val('');
        $(this.start_time).val('');
        $(this.end_date).val('');
        $(this.end_time).val('');
    }
}

/**
 * Export an instance of this class as default for easy use of this es6 module.
 * If needed one is still able to import the class itself as well, since it's "export"
 * flagged to.
 */
export default new ProductVariant();
