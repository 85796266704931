/* eslint-disable react/prop-types */
/* eslint-disable consistent-return */
/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-consistent-return */
/* eslint-disable prefer-template */
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { ThreeDots } from 'react-loader-spinner';
import styled from 'styled-components';

import config from './config';

function DataSheet({ buttons, role }) {
    const [getActiveTab, setActiveTab] = useState(config.defaultActiveTab);
    const [getFormattedData, setFormattedData] = useState([]);
    const [getDataLoading, setDataLoading] = useState(false);
    const [getTranslations, setTranslations] = useState({});

    const LoaderPositioner = styled.div`
        height: 50px;
        position: absolute;
        top: 28%;
        left: 50%;
        transform: translate(-50%, -50%);
        margin-top: 20%;
    `;
    async function fetchData() {
        try {
            setDataLoading(true);
            const request = {
                method: 'get',
                url: config.dataUrl,
                headers: {
                    'X-Requested-With': 'XMLHttpRequest'
                },
            };
            const response = await axios(request);

            const formatedData = config.proceedData(response.data);

            setFormattedData(formatedData);
            setTranslations({ ...response.data._translations });
        } catch (e) {
            console.error(e);
        } finally {
            setDataLoading(false);
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    const translate = (contentKey, labelKey) => {
        if (typeof getTranslations[contentKey] === 'undefined') {
            return labelKey;
        }
        const trans = getTranslations[contentKey][labelKey];
        if (typeof trans === 'undefined') {
            return labelKey;
        }
        return trans;
    };

    const ContentDefault = (props) => {
        // eslint-disable-next-line prefer-const
        let [key, value] = props.contentObj;
        if (!value) value = '-';
        return (
            <div key={key} className={`row ${props.contentIndex % 2 === 0 ? 'h-bg-lightgray' : ''}`}>
                <div className="col-4"><b> {translate(props.contentSection._contentKey, key)} </b></div>
                <div className="col" dangerouslySetInnerHTML={{ __html: value }} />
            </div>
        );
    };

    const tableTabs = () => {
        if (role === 'admin') {
            return ['entrance_events', 'printouts', 'qr_codes'];
        }
        return ['entrance_events'];
    };

    // return a promise
    function copyToClipboard(textToCopy) {
        // navigator clipboard api needs a secure context (https)
        if (navigator.clipboard && window.isSecureContext) {
            // navigator clipboard api method'
            return navigator.clipboard.writeText(textToCopy);
        }
        // text area method
        const textArea = document.createElement('textarea');
        textArea.value = textToCopy;
        // make the textarea out of viewport
        textArea.style.position = 'fixed';
        textArea.style.left = '-999999px';
        textArea.style.top = '-999999px';
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        return new Promise((res, rej) => {
            // here the magic happens
            if (document.execCommand('copy')) {
                res();
            } else {
                rej();
            }

            textArea.remove();
        });
    }

    return (
        <div>
            {getDataLoading && (
                <LoaderPositioner>
                    <ThreeDots
                        color="#93dcfc"
                        height="100"
                        width="100"
                    />
                </LoaderPositioner>
            )}

            {!getDataLoading && (
                <div className="card m-b-15">
                    <div className="row">
                        <div className="col-md-6">
                            <nav className="navbar navbar-expand-lg float-start">
                                <ul className="navbar-nav">
                                    {getFormattedData.map((t) => {
                                        if (t.adminOnly === true && role !== 'admin') return null;
                                        return (
                                            <li key={'nav_' + t.tabLabel} className={`nav-item ${getActiveTab === t.tabLabel ? 'fw-bold' : ''}`} >
                                                <a className="nav-link h-cursor-pointer" role="button" onClick={() => setActiveTab(t.tabLabel)}>{t.tabLabel}</a>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </nav>
                        </div>
                        <div className="col-md-6">
                            <nav className="navbar navbar-expand-lg float-end">
                                <ul className="navbar-nav">
                                    <li className="nav-item ">
                                        <a className="nav-link h-cursor-pointer text-danger" href={buttons.lock.url} role="button">
                                            <i className={buttons.lock.icon} /> {buttons.lock.title}
                                        </a>
                                    </li>
                                    {buttons.repair.url !== null && (
                                        <li className="nav-item ">
                                            <a
                                                className={`nav-link ${buttons.repair.disabled ? 'text-muted disabled' : 'h-cursor-pointer text-danger'}`}
                                                href={buttons.repair.url}
                                                role="button"
                                                title={buttons.repair.tooltip}
                                                onClick={() => setDataLoading(true)}
                                            >
                                                <i className={buttons.repair.icon} /> {buttons.repair.title}
                                            </a>
                                        </li>
                                    )}
                                    {buttons.sendEntranceEventsToTalend.url !== null && (
                                        <li className="nav-item ">
                                            <a className="nav-link h-cursor-pointer text-danger" href={buttons.sendEntranceEventsToTalend.url} role="button">
                                                <i className={buttons.sendEntranceEventsToTalend.icon} /> {buttons.sendEntranceEventsToTalend.title}
                                            </a>
                                        </li>
                                    )}
                                    <li>
                                        <a className="nav-link h-cursor-pointer m-l-15 m-r-15" role="button" onClick={async ()=> await fetchData()}>
                                            <i className="fas fa-refresh"/>
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            )}

            {!getDataLoading && getFormattedData.filter(o => o.tabLabel === getActiveTab).map(activeTab => (
                <div key={activeTab.tabLabel}>
                    <div className="row">
                        {activeTab.columns.map((column, columnIndex) =>
                            (
                                <div key={columnIndex} className={`col ${columnIndex === 0 ? 'p-r-0' : ''}`}>
                                    {column.content.map((contentSection, contentSectionIdx) => {
                                        if (contentSection._visible === false) return null;

                                        if (contentSection._empty_text !== null) {
                                            return (
                                                <div key={contentSection + contentSectionIdx} className="card p-4">
                                                    <div>
                                                        <h4 title={contentSection._label} key={contentSection._label}>{translate(contentSection._contentKey, '_label')} </h4>
                                                        <hr />
                                                        <i> {translate(contentSection._contentKey, contentSection._empty_text)}</i>
                                                    </div>
                                                </div>
                                            );
                                        }
                                        if (tableTabs().includes(contentSection._contentKey)) {
                                            return (
                                                <div key={contentSectionIdx} className="card">
                                                    <div className="table-responsive">
                                                        <table className="table table-striped table-bordered table-sm h-no-word-break">
                                                            <thead className="thead-light">
                                                                <tr>
                                                                    {Object.keys(Object.entries(contentSection)[0][1]).filter(e => e !== '_label' || '_visible').map((t, thIndex) => (
                                                                        <th key={thIndex}>{translate(contentSection._contentKey, t)}</th>
                                                                    ))}
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {Object.entries(contentSection).map((contentObj, ind) => {
                                                                    /* eslint-disable no-unused-vars */
                                                                    const [key, value] = contentObj;

                                                                    if (!value || typeof value !== 'object') {
                                                                        return;
                                                                    }

                                                                    const entranceEventForSubEvent = contentSection._contentKey === 'entrance_events' &&
                                                                        typeof value.sub_events !== 'undefined' && value.sub_events !== null && value.sub_events !== '';

                                                                    return (
                                                                        <tr key={ind} className={`${entranceEventForSubEvent ? 'h-bg-lightblue' : ''}`}>
                                                                            {Object.entries(value).map((obj, idx) => {
                                                                                const [k, v] = obj;
                                                                                if (k === 'barcode' && v) {
                                                                                    return (
                                                                                        <td className="text-center" key={idx}>
                                                                                            <button
                                                                                                title={v}
                                                                                                style={{ width: '70px' }}
                                                                                                className="btn btn-primary"
                                                                                                onClick={() => copyToClipboard(v)}
                                                                                            >
                                                                                                {v.length} <i className="far fa-copy" />
                                                                                            </button>
                                                                                        </td>
                                                                                    );
                                                                                }
                                                                                return (
                                                                                    <td key={idx}>
                                                                                        {(() => {
                                                                                            if (v === true) {
                                                                                                return ('true');
                                                                                            } else if (v === false) {
                                                                                                return ('false');
                                                                                            } else if (v === null || v === '' || v === undefined) {
                                                                                                return ('-');
                                                                                            }
                                                                                            return (
                                                                                                <span dangerouslySetInnerHTML={{ __html: v }} />
                                                                                            );
                                                                                        })()}
                                                                                    </td>
                                                                                );
                                                                            })}
                                                                        </tr>
                                                                    );
                                                                })}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            );
                                        }

                                        return (
                                            <div
                                                key={activeTab.tabLabel + '_' + contentSection._label}
                                                className="card p-4"
                                            >

                                                <h4 title={contentSection._label} >{translate(contentSection._contentKey, '_label')} </h4>
                                                <hr />


                                                { Object.entries(contentSection).map((contentObj, contentIndex) => {
                                                    const [key, value] = contentObj;

                                                    if (key === '_empty_text' || key === '_visible' || key === '_label') {
                                                        return null;
                                                    }

                                                    if (key === '_contentKey') return null;

                                                    if (value !== null && typeof value === 'object') {
                                                        const entries = Object.entries(value);
                                                        return entries.map((entry, entryIdx) => (
                                                            <div>
                                                                <ContentDefault
                                                                    key={entryIdx}
                                                                    contentSection={contentSection}
                                                                    contentObj={entry}
                                                                    contentIndex={entryIdx}
                                                                />
                                                                {entries.length === entryIdx + 1 && <hr />}
                                                            </div>
                                                        ));
                                                    }

                                                    return (
                                                        <ContentDefault
                                                            key={key}
                                                            contentSection={contentSection}
                                                            contentObj={contentObj}
                                                            contentIndex={contentIndex}
                                                        />
                                                    );
                                                })}
                                            </div>
                                        )
                                        ;
                                    }
                                    )}
                                </div>
                            )
                        )}
                    </div>
                </div>

            ))}
        </div>
    );
}

export default DataSheet;
