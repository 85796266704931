import $ from 'jquery';

$(document).ready(() => {
    $('.js-scrolltop').on('click', () => {
        $('html,body').animate({ scrollTop: 0 }, 'fast');
        return false;
    });
    $(window).scroll(() => {
        if ($(window).scrollTop() > 300) {
            $('.js-scrolltop').removeClass('d-none');
        }
        if ($(window).scrollTop() < 300) {
            $('.js-scrolltop').addClass('d-none');
        }
    });
});
