/* eslint-disable consistent-return */

import dataTablesMainInit, { renderLink } from '../../../LayoutBundle/js/dataTablesMainInit';

const $ = window.jQuery;

const Translator = typeof window.Translator !== 'undefined' ? window.Translator : { trans: key => key };
const trans = rootKey => key => Translator.trans(`${rootKey}.${key}`, 'datatable');

const t = trans('product.variant');

$(document).ready(() => {
    const dTsettings = {};

    dTsettings.tableName = 'variant_datatable';
    dTsettings.selector = `#${dTsettings.tableName}`;

    if ($(dTsettings.selector).length === 0) return false;

    dTsettings.options = {
        order: [[3, 'desc']],
        orderCellsTop: true,
    };

    dTsettings.extensions = {
        colReorder: {},
    };

    dTsettings.columns = {
        columns: [
            {
                title: t('title.event'),
                searchable: true,
                orderable: true,
                data: 'event',
                name: 'event',
                render: renderLink,
            },
            {
                title: t('title.type'),
                searchable: true,
                orderable: true,
                data: 'type',
                name: 'type',
            },
            {
                title: t('title.product_no'),
                searchable: true,
                orderable: true,
                data: 'product_no',
                name: 'product_no',
            },
            {
                title: t('title.name'),
                searchable: true,
                orderable: true,
                data: 'name',
                name: 'name',
                render: renderLink,
            },
            {
                title: t('title.sap_article_no'),
                searchable: true,
                orderable: true,
                data: 'sap_article_no',
                name: 'sap_article_no',
            },
            {
                title: t('title.sap_material_no'),
                searchable: true,
                orderable: true,
                data: 'sap_material_no',
                name: 'sap_material_no',
            },
            {
                title: t('title.gross_amount'),
                searchable: true,
                orderable: true,
                data: 'gross_amount',
                name: 'gross_amount',
            },
            {
                title: t('title.target_group'),
                searchable: true,
                orderable: true,
                data: 'target_group',
                name: 'target_group',
            },
            {
                title: t('title.distribution_channel'),
                searchable: true,
                orderable: true,
                data: 'distribution_channel',
                name: 'distribution_channel',
            },
            {
                title: t('title.print'),
                searchable: true,
                orderable: true,
                data: 'print',
                name: 'print',
            },
            {
                title: t('title.registration_requirement'),
                searchable: true,
                orderable: true,
                data: 'registration_requirement',
                name: 'registration_requirement',
            },
            {
                title: t('title.product'),
                searchable: true,
                orderable: true,
                data: 'product',
                name: 'product',
                render: renderLink,
            },
        ]
    };
    dTsettings.multiActions = [];

    dataTablesMainInit(dTsettings);
});
