/* eslint-disable */
import React, {useContext, useRef} from 'react';
import axios from 'axios';
import Pagination from 'rc-pagination';
import paginationTranslations from 'rc-pagination/lib/locale/de_DE';
import { ThreeDots } from 'react-loader-spinner';
import Modal from 'react-modal/lib';
import GlobalContext from './state/GlobalContext';

const Routing = typeof window.Routing !== 'undefined' ? window.Routing : { generate: route => route };
const Translator = window.Translator;

const transformDate = (utc, lang = 'de-DE') => {
    const transDate = new Date(utc);
    return transDate.toLocaleDateString(lang, { year: '2-digit', month: '2-digit', day: '2-digit' });
}

const useSortableData = (items, config = null) => {
    const [sortConfig, setSortConfig] = React.useState(config);

    const sortedItems = React.useMemo(() => {
        let sortableItems = [...items];
        if (sortConfig !== null) {
            sortableItems.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'sort-up' ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'sort-up' ? 1 : -1;
                }
                return 0;
            });
        }
        return sortableItems;
    }, [items, sortConfig]);

    const requestSort = (key) => {
        let direction = 'sort-up';
        if (
            sortConfig &&
            sortConfig.key === key &&
            sortConfig.direction === 'sort-up'
        ) {
            direction = 'sort-down';
        }
        setSortConfig({ key, direction });
    };

    return { items: sortedItems, requestSort, sortConfig };
};

const FileTable = (props) => {

    const stylesModal = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -55%)',
            width: '95%',
            height: '79%',
            padding: 0,
            maxWidth: '90vw',
            boxShadow: '0px 0px 19px rgb(0 0 0 / 36%)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        overlay: {
            backgroundColor: 'rgb(88 88 88 / 0%)',
            marginTop: 45,
        }
    };

    const [
        getClientId,
        getEventId,
        getSelectInputId,
        getSelectedItem,
        setSelectedItem
    ] = useContext(GlobalContext);

    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [getDeleteRequestItem, setDeleteRequestItem] = React.useState({});
    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    const { items, requestSort, sortConfig } = useSortableData(props.files);
    const getClassNamesFor = (name) => {
        if (!sortConfig) {
            return 'sort';
        }
        return sortConfig.key === name ? sortConfig.direction : 'sort';
    };

    return (
        <div>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={stylesModal}
                contentLabel="Sicherheitsabfrage"
                shouldCloseOnOverlayClick={false}
            >
                <div>
                    <div style={{textAlign: 'center'}}>
                        <p style={{fontSize: '18px'}}>
                            Möchten Sie die Datei <strong style={{fontSize: '18px'}}>{getDeleteRequestItem.filename}</strong> endgültig löschen?
                        </p>

                        <button className="btn btn-primary m-r-15" onClick={()=> closeModal()}>Abbrechen</button>
                        <button className="btn btn-danger" onClick={() => props.deleteFileRequest(getDeleteRequestItem.id)}>Endgültig löschen</button>
                    </div>
                </div>
            </Modal>

            {props.getErrorResponse.errors.length > 0
                && props.getErrorResponse.errors.map( error => (
                <div className="row">
                    <div className="col-md-12">
                        <div className="alert alert-danger">
                            { props.getErrorResponse.file.filename }: { Translator.trans(error) }
                        </div>
                    </div>
                </div>
            ))}
            <table className="table table-striped" style={{width: '100%'}}>
            <thead>
                <tr>
                    <th>
                        Vorschau
                    </th>
                    <th>
                        <a
                            onClick={() => requestSort('filename')}
                            className={`text-primary ${getClassNamesFor('filename')}`}
                        >
                            Name <i className={`fas fa-${getClassNamesFor('filename')}`}></i>
                        </a>
                    </th>
                    <th>
                        <a
                            onClick={() => requestSort('clientName')}
                            className={`text-primary  ${getClassNamesFor('clientName')}`}
                        >
                            Mandant  <i className={`fas fa-${getClassNamesFor('clientName')}`}></i>
                        </a>
                    </th>
                    <th>
                        <a

                            onClick={() => requestSort('eventName')}
                            className={`text-primary ${getClassNamesFor('eventName')}`}
                        >
                            Event <i className={`fas fa-${getClassNamesFor('eventName')}`}></i>
                        </a>
                    </th>
                    <th>
                        Anmerkungen
                    </th>
                    <th>
                        <a
                            onClick={() => requestSort('updatedAtDateUTC')}
                            className={`text-primary ${getClassNamesFor('updatedAtDateUTC')}`}
                        >
                            Letztes Update <i className={`fas fa-${getClassNamesFor('updatedAtDateUTC')}`}></i>
                        </a>
                    </th>
                    <th>
                        <a
                            onClick={() => requestSort('size')}
                            className={`text-primary ${getClassNamesFor('size')}`}
                        >
                            Dateigröße <i className={`fas fa-${getClassNamesFor('size')}`}></i>
                        </a>

                    </th>
                    <th>
                        Aktionen
                    </th>
                </tr>
            </thead>
            <tbody>
                {items.length > 0 && items.map((item) => (
                    <React.Fragment key={item.id}>
                        <tr
                            key={item.id}
                            style={
                                getSelectInputId &&
                                getSelectedItem && (
                                    getSelectedItem.id === item.id ? {backgroundColor: '#c4e0ff'} : {}
                                )}
                            onClick={()=> setSelectedItem(item)}
                        >
                            <td>
                                <a href={item.thumbnail} target="_blank" style={{display: 'block'}} >
                                    {item.type && item.type.toLowerCase().includes('image') && (
                                        <img style={{'width': '100px'}} src={item.thumbnail} />
                                    )}

                                    {item.type && item.type.toLowerCase().includes('pdf') && (
                                        <i style={{fontSize: '50px'}} className="fas fa-file-pdf"/>
                                    )}

                                    {item.type && !item.type.toLowerCase().includes('image') && !item.type.toLowerCase().includes('pdf') && (
                                        <i style={{fontSize: '50px'}}  className="fas fa-file"/>
                                    )}
                                </a>
                            </td>
                            <td>{item.filename}</td>
                            <td>{item.clientName}</td>
                            <td>{item.eventName}</td>
                            <td>{item.note}</td>
                            <td>{item.updatedAtDateLocal}</td>
                            <td>{item.sizeLabel}</td>
                            <td>
                                <button
                                    type="button"
                                    className="btn btn-danger float-right"
                                    onClick={()=> {setDeleteRequestItem(item); openModal();}}
                                >
                                    <i className="fas fa-trash-alt" />
                                </button>
                            </td>
                        </tr>
                    </React.Fragment>
                ))}
            </tbody>
        </table>
        </div>
    );
};

const FileListing = (props) => {
    const firstUpdate = useRef(true);
    const [
        getClientId,
        getEventId,
        getSelectInputId,
        getSelectedItem,
        setSelectedItem,
        config,
        getFilesIsLoading,
        setFilesIsLoading
    ] = useContext(GlobalContext);

    const [searchTerm, setSearchTerm] = React.useState('');

    const loader = {
        height: '50px',
        position: 'absolute',
        top: '28%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    }

    const loaderWrapper = {
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
        zIndex: 99,
        background: '#fff',
    }

    const [getListing, setListing] = React.useState([]);


    const [getSearchResultsCount, setSearchResultsCount] = React.useState(0);
    const [getPaginationOffset, setPaginationOffset] = React.useState(1);
    const [getPaginationLimit, setPaginationLimit] = React.useState(10);
    const [getErrorResponse, setErrorResponse] = React.useState({file: null, errors: []});

    const getFilesUrl = Routing.generate('filemanager_api_files');

    const requestFilesData = (page=1, noSearchTerm=false)=>  {
        setFilesIsLoading(true);
        setErrorResponse({file: null, errors: []})
        axios.post(getFilesUrl, {
            "_cid" : getClientId,
            "_eid" : getEventId,
            "search" : noSearchTerm === true ? '' : searchTerm.trim(),
            "limit" : getPaginationLimit,
            "offset": page
        }).then((response) => {
            setListing(response.data.files.data.map(f=> ({
                ...f,
                clientName: f.client ? f.client.name : null,
                eventName: f.event ? f.event.name : null,
                updatedAtDateLocal: transformDate(f.updatedAt.date),
                updatedAtDateUTC: f.updatedAt.date,
                type: f.mimeTypePrefix + '/' + f.mimeTypeSuffix
            })));
            setSearchResultsCount(response.data.files.total);
            setPaginationOffset(page);
            setFilesIsLoading(false);
          }).catch((error) => {
            setFilesIsLoading(false);
            const translatedErrors = error.response.data.errors.map(x => (Translator.trans(x)));
            alert(translatedErrors.join('\n'));
        });
    };

    const deleteFileRequest = (id) =>  {
        const getDeleteFileUrl = Routing.generate('filemanager_api_delete', {
            id: id
        });
        setFilesIsLoading(true)
        axios.delete(getDeleteFileUrl)
            .then((response) => {
                setFilesIsLoading(false)
                setSearchResultsCount(getSearchResultsCount - 1)
                if (response.status === 204) {
                    setListing(getListing.filter(item => item.id !== id))
                }
            })
            .catch((error) => {
                setFilesIsLoading(false)
                setErrorResponse(error.response.data)
            });
    };

    const handleSearchKeydown = (e)=> {
        if(e.key === 'Enter' && !getFilesIsLoading) {
            setSearchTerm(e.target.value);
            requestFilesData();
        };
    };

    const handleSetInputVal = (eleId, selectedItem) => {
        const fileIdInput = '#' + eleId + '_value';
        const filePreview = '#' + eleId + '_preview';
        const fileNameLabel = '#' + eleId + '_fileNameLabel';
        const fileDeleteIcon = '#' + eleId + '_delete';

        window.document.querySelector(fileIdInput).value = selectedItem.id;
        window.document.querySelector(filePreview).src = selectedItem.thumbnail;
        window.document.querySelector(filePreview).alt = selectedItem.filename;
        window.document.querySelector(filePreview).title = selectedItem.filename;
        window.document.querySelector(fileNameLabel).textContent = selectedItem.filename;
        window.document.querySelector(fileDeleteIcon).classList.remove('h-display-none');

        props.setIsOpen(false);
    }

    React.useEffect(()=> {
        setSearchTerm('');
        requestFilesData();
    }, []);

    React.useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        } else {
            setSearchTerm('');
            requestFilesData(1, true);
        }
    },[config, getEventId]);

    return (
        <div {...props}>


            <div className="position-relative">

                    <React.Fragment>
                        {!getFilesIsLoading && (
                            <div className="row justify-content-end">
                                <div className="float-right col-md-4 p-t-5 p-b-5 p-r-15 p-l-15" style={{marginLeft: '-15px', marginRight: '15px'}}>
                                    <div className="input-group mb-3">
                                        <input
                                            onChange={e => setSearchTerm(e.target.value)}
                                            onKeyDown={e => handleSearchKeydown(e)}
                                            type="text"
                                            className="form-control"
                                            value={searchTerm}
                                            placeholder="Dateiname"
                                        />
                                        <button
                                            onClick={()=> {
                                                requestFilesData(1, true);
                                                setSearchTerm('');
                                            }}

                                            className="btn btn"
                                            type="button"
                                            style={{
                                                position: 'absolute',
                                                right: 65,
                                                zIndex: 9,
                                            }}
                                        >
                                          <i className="fas fa-times"></i>
                                        </button>
                                        <button
                                            onClick={()=> requestFilesData()}
                                            className="btn btn-primary btn"
                                            type="button"
                                        >
                                            Suchen
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}

                        {!getFilesIsLoading && (getListing.length > 0) && (
                            <FileTable
                                files={getListing}
                                deleteFileRequest={deleteFileRequest}
                                getErrorResponse={getErrorResponse}
                                setSearchTerm={setSearchTerm}
                                requestFilesData={requestFilesData}
                            />
                        )}

                        {!getFilesIsLoading && getSelectedItem && getSelectInputId !== null && (
                            <button
                            style={{
                                position: 'sticky',
                                bottom: 10,
                                padding: '15px 20px',
                                boxShadow: '0 0 5px rgb(0 0 0 / 40%)',
                                fontSize: 14,
                            }}

                            className="btn btn-primary float-right display-block"
                            onClick={()=> handleSetInputVal(getSelectInputId, getSelectedItem)}>
                                Auswahl übernehmen
                            </button>
                        )}
                    </React.Fragment>


                {getFilesIsLoading && (
                    <div style={loaderWrapper}>
                        <div style={loader}>
                            <ThreeDots
                                color="#93dcfc"
                                height="100"
                                width="100"
                                />
                        </div>
                    </div>
                )}

                <React.Fragment>
                    <div className="text-center m-b-15">
                        <Pagination
                            className="m-b-5 d-inline-block"
                            onChange={requestFilesData}
                            current={getPaginationOffset}
                            defaultPageSize={getPaginationLimit}
                            total={getSearchResultsCount}
                            locale={paginationTranslations}
                            hideOnSinglePage
                            />
                    </div>

                    <div className="text-center m-b-15">
                        {getSearchResultsCount === 0 ? (
                            <small> Keine Dateien vorhanden </small>
                            ):(
                            <small> Insgesamt {getSearchResultsCount} Dateien</small>
                        )}
                    </div>
                </React.Fragment>



            </div>
        </div>
    );
};

export default FileListing;
