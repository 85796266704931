import React from 'react';
import PropTypes from 'prop-types';
import CheckboxGroup from 'react-checkbox-group';

const Translator = window.Translator;

const searchInputHeader = props => (
    <div>
        <div className="row">
            <div className="col">
                <div className="m-b-10 p-3 alert bg-white alert-info">
                    <span
                        className="h5 fw-bold"
                    > {Translator.trans('contract.rebook.search.productvariant')} </span>
                    <span className="h5 m-r-15"> {props.positionVariantName} </span>
                    <span
                        className="h5 fw-bold"
                    > {Translator.trans('contract.rebook.search.available_amount')} </span>
                    <span className="h5"> {props.state.positionAmount} </span>
                </div>
            </div>
        </div>
        <nav className="bg-light p-3 border rounded">
            <div className="row">
                <div className="col">
                    <div className="form-group row">
                        <label
                            htmlFor="search-input"
                            className="col-sm-2 col-form-label  p-t-10 p-b-10 fw-bold"
                        > {Translator.trans('contract.rebook.search.search_term')} </label>
                        <div className="col">
                            <div className="form-inline">
                                <input
                                    className="form-control h-border-rounded-right-0"
                                    name="search-input"
                                    type="search"
                                    placeholder={Translator.trans('contract.rebook.search.search_term_enter')}
                                    value={props.state.searchInputValue}
                                    onChange={props.handleSearchInputChange}
                                    onKeyDown={props.handleSendSearchRequestOnEnter}
                                />
                                <button
                                    className="btn btn-primary my-2 my-sm-0 h-border-rounded-left-0"
                                    onClick={props.handleSendSearchRequestOnBtnPress}
                                    disabled={!props.state.searchInputValue}
                                >
                                    <i className="fa fa-search" /> {Translator.trans('contract.rebook.search.search')}
                                </button>
                                {props.positionIsTarget === 1 &&
                                    <CheckboxGroup
                                        className="p-l-5"
                                        checkboxDepth={2}
                                        name="searchPositionTypes"
                                        value={props.state.searchInputTypes}
                                        onChange={props.handleCheckbox}
                                    >
                                        <div
                                            className="form-check"
                                        >
                                            <Checkbox
                                                className="form-check-input"
                                                id="rebookingPosition"
                                                name="rebookingPosition"
                                                value="rebookingPosition"
                                            />
                                            <label
                                                className="form-check-label"
                                                htmlFor="rebookingPosition"
                                            > {Translator.trans('contract.rebook.search.search_areas_labels.rebookingPosition')}
                                            </label>
                                        </div>
                                    </CheckboxGroup>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="form-group row m-b-0">
                <label
                    htmlFor="filter"
                    className="col-sm-2 col-form-label fw-bold"
                > {Translator.trans('contract.rebook.search.search_areas')}</label>

                <CheckboxGroup
                    className="col"
                    checkboxDepth={4}
                    name="searchInputTypes"
                    value={props.state.searchInputTypes}
                    onChange={props.handleCheckbox}
                >
                    {(Checkbox) => (
                        <>
                            <div className="row">
                                <div className="col-sm-3">
                                    <div className="form-check">
                                        <Checkbox
                                            className="form-check-input"
                                            id="businessPartner"
                                            name="businessPartner"
                                            value="businessPartner"
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor="businessPartner"
                                        > {Translator.trans('contract.rebook.search.search_areas_labels.businessPartner')} </label>
                                    </div>
                                    <div className="form-check">
                                        <Checkbox className="form-check-input" id="order" name="order" value="order" />
                                        <label
                                            className="form-check-label"
                                            htmlFor="order"
                                        > {Translator.trans('contract.rebook.search.search_areas_labels.order')} </label>
                                    </div>
                                </div>
                                <div className="col-sm-3">
                                    <div className="form-check">
                                        <Checkbox className="form-check-input" id="address" name="address" value="address" />
                                        <label
                                            className="form-check-label"
                                            htmlFor="address"
                                        > {Translator.trans('contract.rebook.search.search_areas_labels.address')} </label>
                                    </div>
                                    <div className="form-check">
                                        <Checkbox
                                            className="form-check-input"
                                            id="contract"
                                            name="contract"
                                            value="contract"
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor="contract"
                                        > {Translator.trans('contract.rebook.search.search_areas_labels.contract')} </label>
                                    </div>
                                </div>
                                <div className="col-sm-3">
                                    <div className="form-check">
                                        <Checkbox
                                            className="form-check-input"
                                            id="contractPartner"
                                            name="contractPartner"
                                            value="contractPartner"
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor="contractPartner"
                                        > {Translator.trans('contract.rebook.search.search_areas_labels.contractPartner')} </label>
                                    </div>
                                    <div
                                        className="form-check"
                                    >
                                        <Checkbox
                                            className="form-check-input"
                                            id="position"
                                            name="position"
                                            value="position"
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor="position"
                                        > {Translator.trans('contract.rebook.search.search_areas_labels.position')}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </CheckboxGroup>
            </div>
        </nav>
    </div>
);


searchInputHeader.propTypes = {
    state: PropTypes.object.isRequired,
    handleSearchInputChange: PropTypes.func.isRequired,
    handleCheckbox: PropTypes.func.isRequired,
    handleSendSearchRequestOnBtnPress: PropTypes.func.isRequired,
    handleSendSearchRequestOnEnter: PropTypes.func.isRequired,
    positionVariantName: PropTypes.string.isRequired,
    positionIsTarget: PropTypes.number.isRequired
};

export default searchInputHeader;