import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    label: PropTypes.string.isRequired,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired
};

const Card = (props) => {
    console.log('');
    return (
        <div className="card">
            <div className="card-header">
                {props.label}
            </div>
            <div className="card-body">
                {props.children}
            </div>
        </div>
    );
};

Card.propTypes = propTypes;

export default Card;
