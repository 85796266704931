import React from 'react';
import { createRoot } from 'react-dom/client';
import Modal from 'react-modal/lib';

import MediaManagerShell from './MediaManagerShell';

const stylesModal = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -55%)',
        width: '95%',
        height: '79%',
        padding: 0,
        maxWidth: '90vw',
        boxShadow: '0px 0px 19px rgb(0 0 0 / 36%)',
    },
    overlay: {
        backgroundColor: 'rgb(88 88 88 / 75%)',
        marginTop: 45,
        backdropFilter: 'blur(3px)',
        zIndex: 9999,
    }
};

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
/* eslint-disable react/prop-types */

Modal.setAppElement('#app');

function MediaManagerModal(props) {
    const [modalIsOpen, setIsOpen] = React.useState(false);
    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    return (
        <span>
            <span
                className={props.btnstyles}
                role="button"
                tabIndex={0}
                onClick={openModal}
            >
                {props.selectinputid ? 'Datei auswählen' : 'Mediathek'}
            </span>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={stylesModal}
                contentLabel="Mediathek"
                shouldCloseOnOverlayClick={false}
            >
                <div>
                    <MediaManagerShell
                        closeModal={closeModal}
                        selectinputid={props.selectinputid}
                        setIsOpen={setIsOpen}
                        cid={props.cid}
                        eid={props.eid}
                    />
                </div>
            </Modal>
        </span>
    );
}

document.querySelectorAll('.js-mediamanagermodal').forEach((hostEle) => {
    let selectinputid = null;
    let btnstyles = null;
    let cid = null;
    let eid = null;

    if (hostEle.dataset.selectinputid) {
        selectinputid = hostEle.dataset.selectinputid;
    }

    if (hostEle.dataset.btnstyles) {
        btnstyles = hostEle.dataset.btnstyles;
    }

    if (parseInt(hostEle.dataset.eid, 10) > 0) {
        eid = parseInt(hostEle.dataset.eid, 10);
    }

    if (parseInt(hostEle.dataset.cid, 10) > 0) {
        cid = parseInt(hostEle.dataset.cid, 10);
    }
    const root = createRoot(hostEle);
    root.render(
        <MediaManagerModal
            eid={eid}
            cid={cid}
            selectinputid={selectinputid}
            btnstyles={btnstyles}
        />);
});