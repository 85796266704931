/* eslint-disable consistent-return */
const $ = window.jQuery;

class GeoField {
    constructor() {
        this.$geoField = null;
    }

    validateValue($obj) {
        const lat = /^(\+|-)?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,6})?))$/;
        const long = /^(\+|-)?(?:180(?:(?:\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,6})?))$/;
        const validateLatitude = coordinate => lat.test(coordinate);
        const validateLongitude = coordinate => long.test(coordinate);

        const geotype = $obj.attr('data-geotype');
        const geoval = $obj.val();
        if (!geotype || !geoval) return false;

        let isValid = false;

        if (geotype === 'lat') {
            isValid = validateLatitude(geoval);
        }

        if (geotype === 'long') {
            isValid = validateLongitude(geoval);
        }

        if (isValid) {
            $obj.parent().find('.js-geoField-i-check').removeClass('fa-exclamation-triangle text-danger');
            $obj.parent().find('.js-geoField-i-check').addClass('fa-check-square text-success');
        } else {
            $obj.parent().find('.js-geoField-i-check').removeClass('fa-check-square text-success');
            $obj.parent().find('.js-geoField-i-check').addClass('fa-exclamation-triangle text-danger');
        }
    }

    init() {
        $(document).ready(() => {
            this.$geoField = $('.js-geo-field');
            if (this.$geoField.length === 0) return false;

            this.$geoField.attr('style', 'width: auto; display: inline;');
            this.$geoField.parent().append('<i style="margin-left: -21px;" class="js-geoField-i-check fas"></i>');

            this.$geoField.each((index, element) => {
                this.validateValue($(element));
            });

            this.$geoField.on('change, keyup', (e) => {
                this.validateValue($(e.currentTarget));
            });
        });
    }
}

export default new GeoField();
