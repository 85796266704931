import React from 'react';
import Type from './Type';
import TimeTable from './TimeTable';
import Card from '../Card/Card';
import EntranceTimeOffset from './EntranceTimeOffset';

const CardNotes = () => (
    <Card label={window.Translator.trans('sub_event.planning.planning')}>
        <EntranceTimeOffset />
        <hr />
        <Type />
        <hr />
        <TimeTable />
    </Card>
);

export default CardNotes;
