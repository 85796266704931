/*eslint-disable */
import React, {
    Fragment
} from 'react';

import PropTypes from 'prop-types';

import {
    ResultListingItem,
    ResultListingItemLabel,
    ItemColIcon,
    ItemColLabel
} from '../SeachSelectUI';

// Prop Types
const propTypes = {
    searchResult: PropTypes.shape({
        id: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number
        ]).isRequired,
        label: PropTypes.object.isRequired,
    }).isRequired,
    resultIcon: PropTypes.string,
    handleOnClick: PropTypes.func
};

const defaultProps = {
    handleOnClick: () => null,
    resultIcon: 'fas fa-carret-down'
};

const ResultListingItemStandard = (props) => {
    const { searchResult } = props;
    return (
        <ResultListingItem className={`result_${searchResult.id}`} onClick={() => props.handleOnClick(searchResult.id)}>
            <ItemColIcon>
                <i className={`fas ${props.resultIcon}`} style={{ lineHeight: 1 }} />
            </ItemColIcon>

            <ItemColLabel>
                {typeof searchResult.formattedAddress !== 'undefined' && searchResult.formattedAddress !== '' ? (
                    <Fragment>
                        {searchResult.formattedAddress.split('\n').map((item, i) => (
                            <ResultListingItemLabel key={i}>
                                {item}
                                {searchResult.formattedAddress.length < i && <br />}
                            </ResultListingItemLabel>
                        ))}
                    </Fragment>
                ) : Object.entries(searchResult.label).map((e) => {
                    const [labelKey, labelVal] = e;
                    const ret = labelVal !== '' ? (
                        <ResultListingItemLabel key={`item_${searchResult.id}_${labelKey}`}>
                            {labelVal.split('\n').map((item, i) => (
                                <Fragment key={i}>
                                    {item}
                                    {labelVal.length < i && <br />}
                                </Fragment>
                            ))}
                        </ResultListingItemLabel>
                    ) : null;
                    return ret;
                })
                }

            </ItemColLabel>
        </ResultListingItem>
    );
};

ResultListingItemStandard.propTypes = propTypes;
ResultListingItemStandard.defaultProps = defaultProps;

export default ResultListingItemStandard;
