/* eslint-disable camelcase */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    variants: [],
    searchTerm: '',
};

export const productVariantsReducer = createSlice({
    name: 'productVariants',
    initialState,
    reducers: {
        setSearchTerm: (state, action) => {
            state.searchTerm = action.payload;
        },
        setVariants: (state, action) => {
            state.variants = action.payload;
        },
        addVariant: (state, action) => {
            const { id, name, product_no } = action.payload;
            state.variants.push({
                id: null,
                variant_id: id,
                gross_amount: 0,
                name,
                product_no,
            });
        },
        deleteVariant: (state, action) => {
            state.variants = state.variants.filter(v => v.variant_id !== action.payload.variant_id);
        },
        changeGrossAmount: (state, action) => {
            const toEdit = state.variants.find(v => v.variant_id === action.payload.variant_id);
            if (!action.payload.gross_amount) action.payload.gross_amount = '';
            const gaInt = parseInt(action.payload.gross_amount, 10);
            if (toEdit) {
                toEdit.gross_amount = gaInt < 1 || action.payload.gross_amount === '-0' ? 0 : action.payload.gross_amount;
            } else {
                console.error('id not found!');
            }
        },
    },
});

export const {
    setSearchTerm,
    setVariants,
    addVariant,
    deleteVariant,
    changeGrossAmount,
} = productVariantsReducer.actions;

export default productVariantsReducer.reducer;
