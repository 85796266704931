import React from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { setEntranceTimeOffset, resetEntranceTimeOffset } from './planningReducer';

const EntranceTimeOffset = () => {
    const dispatch = useDispatch();
    const inEditMode = useSelector(state => state.actionBar.inEditMode);
    const entranceTimeOffset = useSelector(state => state.planning.entranceTimeOffset);
    const entranceTimeInheritedOffset = useSelector(state => state.planning.entranceTimeInheritedOffset);
    const errors = useSelector(state => state.shell.errors);
    const entranceTimePlaceholder = window.Translator.trans('template_bundle.data.entrance_time_offset', {
        entrance_time_offset: entranceTimeOffset.toString() || entranceTimeInheritedOffset.toString()
    });

    return (
        <div className="row mb-3">
            <div className="col-7">
                <span className="font-weight-bold text-nowrap">
                    {window.Translator.trans('sub_event.planning.entrance_time_offset')}
                </span>
            </div>
            <div className="col-5 d-flex">
                {inEditMode ? (
                    <div className="d-flex">
                        <input
                            onChange={e => dispatch(setEntranceTimeOffset(e.target.value))}
                            className="w-75 h-100 border border-primary rounded"
                            placeholder={entranceTimePlaceholder}
                            value={entranceTimeOffset}
                            required
                            type="number"
                            min="0"
                        />
                        <button
                            className="ms-2 w-75 btn btn-primary btn-sm"
                            onClick={() => dispatch(resetEntranceTimeOffset())}
                        > {window.Translator.trans('template_bundle.placeholder_textarea.reset_value')}
                        </button>
                    </div>
                ) : (
                    <span>{ entranceTimeOffset || entranceTimeInheritedOffset }</span>
                )}
            </div>
            {errors && errors.entrance_time_offset && (
                <div className="m-3 w-100 card border border-danger text-danger font-weight-bold">
                    <div className="card-header text-white bg-danger">
                        <i className="ms-1 fas fa-exclamation-triangle" />
                        {window.Translator.trans('sub_event.errors.global_default')}
                    </div>
                    <div className="card-body">
                        <ul className="mb-0 pb-0" style={{ paddingLeft: '15px' }}>
                            {errors.entrance_time_offset.map(error => (
                                <li>{ window.Translator.trans(error) }</li>
                            ))}
                        </ul>
                    </div>
                </div>
            )}
        </div>
    );
};

export default EntranceTimeOffset;