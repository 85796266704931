/**
 * RESULT ITEM HEAD SECTION TEMPLATE
 */

import React from 'react';
import PropTypes from 'prop-types';

const Translator = window.Translator;

const resultItemHead = (props) => {
    let value = ' - ';
    if (props.value && props.value !== 'null') {
        value = String(props.value).split('\\n').map((item, i) => (
            //  dangerouslySetInnerHTML is necessary to display the <mark> tag we have added to the result items data.
            <p key={props.field + i} className="m-b-0" dangerouslySetInnerHTML={{ __html: item }} />
        ));
    }
    return (
        <div className={props.iterator === 3 ? 'col-sm-3 ps-1' : 'col-sm-2 ps-1'}>
            <div className="fw-bold" title={props.field}>
                {Translator.trans(`contract.rebook.search_result.${props.field}`)}
            </div>
            <div>
                {value}
            </div>
        </div>
    );
};

resultItemHead.propTypes = {
    iterator: PropTypes.number.isRequired,
    field: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool
    ])
};

resultItemHead.defaultProps = {
    value: ' - '
};

export default resultItemHead;
