/* eslint-disable consistent-return */
/* eslint-disable prefer-template */

const $ = window.jQuery;

const createToolTips = (columns) => {
    columns.columns.forEach((col) => {
        if (!col.tooltip) return false;
        $('[aria-label="' + col.title + '"]')
            .attr('title', col.tooltip)
            .attr('data-bs-toggle', 'tooltip')
            .attr('data-bs-placement', 'top')
            .addClass('h-cursor-help')
            .tooltip()
        ;
    });
};

export default createToolTips;
