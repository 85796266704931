import styled from 'styled-components/dist/styled-components.cjs';

export const Container = styled.div`
    padding-top: 8px;
    border-top: 1px solid var(--light);
    position: relative;
`;

export const CurrentChoice = styled.div.attrs(() => ({
    role: 'button',
    tabIndex: '-1'
}))`
    cursor: pointer;
    line-height: 1.5;
    padding-left: 8px;
    padding-right: ${props => (props.hasPlaceholder ? '8px' : '26px')};
    padding-top: 8px;
    padding-bottom: 8px;
    position: relative;
`;

export const InputSearch = styled.input.attrs(() => ({
    className: 'card',
    type: 'text'
}))`
    display: block;
    width: calc(100% - 16px);
    padding: 4px;
    padding-right: 30px;
    padding-left: 30px;
    margin-left: 8px;
    margin-right: 8px;
    margin-bottom: 8px;
    box-shadow: inset 0 0 4px 0px hsla(0, 0%, 0%, 0.058);
`;

export const IconClearSearchTerm = styled.i.attrs(() => ({
    role: 'button',
    tabIndex: '-3',
    className: 'fas fa-times'
}))`
    cursor: pointer;
    font-weight: bold;
    color: var(--gray);
    position: absolute;
    right: 14px;
    top: 14px;
    font-size: 16px;
`;

export const IconClearCurrentChoice = styled(IconClearSearchTerm).attrs(() => ({
    tabIndex: '-2',
}))`
    right: 7px;
    top: 10px;
`;

export const IconSearch = styled.i.attrs(() => ({
    className: 'fas fa-search'
}))`
    position: absolute;
    left: 14px;
    top: 14px;
    font-size: 16px;
    z-index: 9;
    color: #ccc;
`;

export const ResultListing = styled.div`
    padding: 0;
    margin: 0;
    overflow-y: scroll;
    border-top: 1px solid var(--light);
    max-height: 15rem;
`;

export const ResultListingItem = styled.div`
    display: flex;
    cursor: pointer;
    padding-left: 9px;
    padding-right: 9px;
    padding-top: 4px;
    padding-bottom: 4px;
    &:nth-child(even) {
        background-color: var(--light);
    }
    &:hover {
        background-color: rgb(0, 174, 239);
        color: var(--white);
    }
`;

export const ItemColIcon = styled.div`
    width: 30px;
    margin-left: -9px;
    text-align: center;
`;

export const ItemColLabel = styled.div`
    flex: 1;
`;

export const ResultsInfo = styled.div.attrs(() => ({
    className: 'card'
}))`
    text-align: center;
    margin-bottom: 0;
    padding-top: 4px;
    padding-bottom: 4px;
`;

export const ResultListingItemLabel = styled.div`
    &:first-child {
        font-weight: bold;
    }
    &:only-child {
        font-weight: inherit;
    }
`;

export const SmallLoaderCentered = styled.div`
    width: 35px;
    padding-bottom: 3px;
    margin: auto;
`;

export const ChoiceLoaderOverlay = styled.div`
    background: hsla(0, 0%, 100%, 0.78);
    position: absolute;
    z-index: 9;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
`;

