/**
 * Import libraries and dependencies to use in this JS class
 */
import $ from 'jquery';

export class Rejection {
    constructor() {
        this.reasonCode = '.js-reason-code';
        this.optionalReason = '.js-reason-optional';
        this.optionalReasonLabel = "label[for='rejection_reason']";
    }

    /**
     * Check if reason form elements exist
     */
    init() {
        $(document).ready(() => {
            // check if our js-classes exist in dom. otherwise exit.
            if ($(this.reasonCode).length > 0) {
                this.checkReasonChoice();
                this.changeReasonChoice();
            }
        });
    }

    /**
     * Check first time on page load
     */
    checkReasonChoice() {
        if (this.isOptionalReasonSelected()) {
            this.showOptionalReason();
        } else {
            this.hideOptionalReason();
        }
    }

    changeReasonChoice() {
        $(this.reasonCode).on('change', () => {
            if (this.isOptionalReasonSelected()) {
                this.showOptionalReason();
            } else {
                this.hideOptionalReason();
            }
        });
    }

    hideOptionalReason() {
        $(this.optionalReason).parent().parent().hide();
        $(`textarea${(this.optionalReason)}`).removeAttr('required');
        $(`${(this.optionalReasonLabel)}`).removeClass(('h-required'));
    }

    showOptionalReason() {
        $(this.optionalReason).parent().parent().show();
        $(`textarea${(this.optionalReason)}`).attr('required', true);
        $(`${(this.optionalReasonLabel)}`).addClass(('h-required'));
    }

    isOptionalReasonSelected() {
        console.log('isoptional', ($(this.reasonCode).val() === 'OPT'));
        return ($(this.reasonCode).val() === 'OPT');
    }
}

/**
 * Export an instance of this class as default for easy use of this es6 module.
 * If needed one is still able to import the class itself as well, since it's "export"
 * flagged to.
 */
export default new Rejection();
