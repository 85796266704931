/* eslint-disable consistent-return */

const $ = window.jQuery;

const createNumberRangeSearch = (tableName) => {
    const $numberRangeSelector = $(`#${tableName}`).find('.js-number-range-search');
    if (typeof $numberRangeSelector === 'undefined' || $numberRangeSelector instanceof $ === false) return false;

    const numberRangeSearchInstances = [];
    class numberRangeSearch {
        constructor(host) {
            this.$host = host;

            this.styles = {
                input: /* css */`
                    width: 60px;
                    height: 24px;
                    display: inline;
                `,
                wrapper: /* css */`
                    background-color: #f0f0f0;
                    border: 1px solid #ddd;
                `,
            };

            this.state = {
                currentMinNumber: '',
                currentMaxNumber: '',
            };

            this.dataTableApi = window.dataTableInstances[tableName].api;

            if (this.$host.attr('data-from-display') !== 'null') {
                this.state.currentMinNumber = this.$host.attr('data-min-display');
            }
            if (this.$host.attr('data-to-display') !== 'null') {
                this.state.currentMaxNumber = this.$host.attr('data-max-display');
            }

            this.debounceTimeOut = '';

            this.init();
        }

        render = () => {
            this.$host.html(/* html */`
                <div style="${this.styles.wrapper}">
                    <input
                        class="js-number-range form-control form-control-sm"
                        data-range="min"
                        style="${this.styles.input}"
                        type="number"
                        min="0"
                        max="${this.state.currentMaxNumber}"
                        value="${this.state.currentMinNumber}"
                        title="${this.state.currentMinNumber}"
                    >
                    -
                    <input
                        class="js-number-range form-control form-control-sm"
                        data-range="max"
                        style="${this.styles.input}"
                        type="number"
                        min="${this.state.currentMinNumber}"
                        value="${this.state.currentMaxNumber}"
                        title="${this.state.currentMaxNumber}"
                    >
                </div>
            `);
        };

        sendSearchRequest(colIdx) {
            const colIdxForSearch = parseInt(colIdx, 10);
            if (isNaN(colIdxForSearch)) {
                console.error('given colIdx is NaN');
                return false;
            }

            const saneMinNumber = parseInt(this.state.currentMinNumber, 10);
            const saneMaxNumber = parseInt(this.state.currentMaxNumber, 10);

            const separator = isNaN(saneMinNumber) && isNaN(saneMaxNumber) ? '' : ',';
            const minNumberForSearch = isNaN(saneMinNumber) ? '' : saneMinNumber;
            const maxNumberForSearch = isNaN(saneMaxNumber) ? '' : saneMaxNumber;

            this.dataTableApi
                .column(colIdxForSearch)
                .search(`${minNumberForSearch}${separator}${maxNumberForSearch}`)
                .clearPipeline()
                .draw()
            ;
        }

        init() {
            this.render();

            // Reqister Events
            this.$host.on('keyup change', '.js-number-range', (event) => {
                if (event.type === 'keyup' && (
                    event.keyCode === 37 /* arrow left */
                    || event.keyCode === 38 /* arrow up */
                    || event.keyCode === 39 /* arrow right */
                    || event.keyCode === 40 /* arrow down */
                    || event.keyCode === 16 /* shift */
                    || event.keyCode === 17 /* ctrl */
                    || event.keyCode === 18 /* alt */
                )) {
                    return false;
                }

                const rangeMode = $(event.currentTarget).attr('data-range');
                if (rangeMode === 'min') {
                    this.state.currentMinNumber = $(event.currentTarget).val() || '';
                } else if (rangeMode === 'max') {
                    this.state.currentMaxNumber = $(event.currentTarget).val() || '';
                }

                // debounce search
                clearTimeout(this.debounceTimeOut);
                this.debounceTimeOut = setTimeout(() => {
                    this.render();
                    this.sendSearchRequest(this.$host.attr('data-col-idx'));
                }, 1000);
            });

            /**
             * Firefox bug fix since FF does not trigger change a event when clicking the native
             * clear number button without a focused input ¯\_(ツ)_/¯
             */
            this.$host.on('mousedown', '.js-number-range', (e) => {
                $(e.currentTarget).focus();
            });
        }
    }

    $('body').find($numberRangeSelector).each((index, element) => {
        numberRangeSearchInstances.push(new numberRangeSearch($(element)));
    });

    return numberRangeSearchInstances;
};

export default createNumberRangeSearch;
