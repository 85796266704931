const Translator = window.Translator;

export class MiofairConfirm {
    constructor() {
        /**
         * Set the JS selectors for further reference
         */
        this.idModal = '#modalConfirm';
        this.classTitle = '.modal-title';
        this.classBody = '.modal-body';
        this.classFooter = '.modal-footer';

        this.title = Translator.trans('generic.modal.confirm.title');
        this.message = Translator.trans('generic.modal.confirm.message');
        this.btnTextCancel = Translator.trans('generic.button.cancel');
        this.btnTextConfirm = Translator.trans('generic.button.confirm');
    }

    showDialog(options) {
        const title = options.title || this.title;
        const message = options.message || this.message;
        const textCancel = options.btnTextCancel || this.btnTextCancel;
        const textConfirm = options.btnTextConfirm || this.btnTextConfirm;

        const $modal = $(this.idModal);
        $(this.classTitle, $modal).text(title);
        $(`${this.classBody}  p`, $modal).text(message);
        $(`${this.classFooter} .abort`, $modal).text(textCancel);
        $(`${this.classFooter} .confirm`, $modal).text(textConfirm).attr('href', options.link);

        $modal.modal('show')
    }

    init() {
        $(document).ready(() => {
            $(document).on('click', '.actionConfirm', (e) => {
                e.preventDefault();
                const options = {};
                options.link = $(e.currentTarget).attr('href');
                options.message = $(e.currentTarget).data('confirm');
                options.btnTextCancel = $(e.currentTarget).data('btn-close');
                options.btnTextConfirm = $(e.currentTarget).data('btn-confirm');

                this.showDialog(options);

                return false;
            });
        });
    }
}


export default new MiofairConfirm();