/* eslint-disable consistent-return */
import createdListForConfigCreator from './createdListForConfigCreator';

const main = function () {
    if (document.getElementById('create_select_buttons_group_config_cashDeskPlace') === null) {
        return false;
    }
    const confirmButton = document.getElementById('create_select_buttons_group_config_add');

    const configCreatorOutput = new createdListForConfigCreator();
    function confirm() {
        confirmButton.addEventListener('click', () => {
            const enabled = document.querySelector('#create_select_buttons_group_config_cashDeskSets option:not(:disabled)');
            if (enabled.length === 0) {
                this.confirmButton = false;
            } else {
                configCreatorOutput.init();
            }
        });
    }
    document.addEventListener('DOMContentLoaded', confirm);
};

main();
