/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
const Translator = window.Translator;

const config = {
    dataUrl: window.location.href,
    defaultActiveTab: Translator.trans('transaction.ticketdetails.tabs.transaction'),
    tabs: [

        // tab
        {
            tabLabel: Translator.trans('transaction.ticketdetails.tabs.transaction'),
            adminOnly: false,
            columns: [

                // Spalte
                {
                    content: [
                        // Card
                        {
                            contentKey: 'transaction_entry',
                        },
                        // Card
                        {
                            contentKey: 'transaction',
                        },
                        // Card
                        {
                            contentKey: 'reservations',
                        },
                    ]
                },
                // Spalte
                {
                    content: [
                        // Card
                        {
                            contentKey: 'order_position',
                        },
                        // Card
                        {
                            contentKey: 'hybrid_variant'
                        },
                        // Card
                        {
                            contentKey: 'product_variant'
                        }
                    ]
                }
            ]
        },
        {
            tabLabel: Translator.trans('transaction.ticketdetails.tabs.profildata'),
            adminOnly: false,
            columns: [
                {
                    content: [
                        {
                            contentKey: 'profile_ticket_buyer'
                        },
                        {
                            contentKey: 'profile_data_ticket_buyer'
                        },
                        {
                            contentKey: 'profile_data_ticket_buyer_invoice'
                        },
                    ],
                },
                {
                    content: [
                        {
                            contentKey: 'profile_ticket_owner',
                        },
                        {
                            contentKey: 'profile_data_ticket_owner'
                        },
                        {
                            contentKey: 'profile_ticket_owner_event_interest',
                        },
                        {
                            contentKey: 'profile_ticket_owner_digital_covid_certificate',
                        }
                    ]
                }
            ]
        },
        {
            tabLabel: Translator.trans('transaction.ticketdetails.tabs.entrance_events'),
            adminOnly: false,
            columns: [
                {
                    content: [
                        { contentKey: 'entrance_events' }
                    ]
                }
            ]
        },
        {
            tabLabel: Translator.trans('transaction.ticketdetails.tabs.printouts'),
            adminOnly: true,
            columns: [
                {
                    content: [
                        { contentKey: 'printouts' }
                    ]
                }
            ]
        },
        {
            tabLabel: Translator.trans('transaction.ticketdetails.tabs.qr_codes'),
            adminOnly: true,
            columns: [
                {
                    content: [
                        { contentKey: 'qr_codes' }
                    ]
                }
            ]
        },
    ],
    proceedData(data) {
        return config.tabs.map((tab) => {
            const tabObj = {
                tabLabel: tab.tabLabel,
                columns: [],
            };

            tab.columns.forEach((col) => {
                const newEntry = {
                    content: []
                };

                col.content.forEach((content) => {
                    let ret = {};

                    ret = {
                        _contentKey: content.contentKey,
                        ...data[content.contentKey]
                    };
                    newEntry.content.push(ret);
                });

                tabObj.columns.push(newEntry);
            });

            return tabObj;
        });
    }
};

export default config;
