/* eslint no-eval: 0 */
/* eslint react/require-default-props: 0 */

/**
 * MOTHER COMPONENT FOR THE SEARCH UI.
 */

/**
 * Import dependencies.
 */
import React from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import ResultItem from './ResultItem/ResultItem';
import HeadlineResults from './HeadlineResults/HeadlineResults';
import LoaderAnimation from './LoaderAnimation/LoaderAnimation';
import StatusInfo from './StatusInfo/StatusInfo';
import Pagination from 'rc-pagination';
import paginationTranslations from 'rc-pagination/lib/locale/de_DE';
import SearchInputHeader from './SearchInputHeader/SearchInputHeader';
import {Animate}  from 'react-simple-animate';

const propTypes = {
    positionAmount: PropTypes.number,
    positionIsTarget: PropTypes.number,
    searchRequestUrl: PropTypes.string,
    positionVariantName: PropTypes.string,
    rebookActionCallBack: PropTypes.func,
};

export class Search extends React.Component {
    constructor(props) {
        /**
         * Static data we like to use.
         */
        super(props);
        this.hostElement = document.getElementById('js-search-position');
        this.positionAmount = parseInt(this.props.positionAmount ? this.props.positionAmount : this.hostElement.getAttribute('data-position-amount'), 10);
        this.positionIsTarget = parseInt(this.props.positionIsTarget ? this.props.positionIsTarget : this.hostElement.getAttribute('data-position-is-target'), 10);
        this.searchRequestUrl = String(this.props.searchRequestUrl ? this.props.searchRequestUrl : this.hostElement.getAttribute('data-search-url'));
        this.positionVariantName = String(this.props.positionVariantName ? this.props.positionVariantName : this.hostElement.getAttribute('data-position-variant-name'));
        this.remoteSearchQuery = this.hostElement ? this.hostElement.getAttribute('data-remote-search-query') : undefined;
        this.curPage = 1;
        this.searchResultsOrder = [
            // Head
            'business_partner_name',
            'business_partner_id',
            'business_partner_fid_and_sap_id',
            // Meta
            'internal',
            'contract_id',
            'contract_fid',
            'order_id',
            'position_id',
            'amount',
            // Details
            'contact_person_name',
            'business_partner_replacement_name',
            'contact_person_replacement_name',
            'billing_address',
            'shipping_address',
            'stand_description',
            'order_date_formatted',
        ];

        /**
         * State data we like to use.
         */
        this.state = {
            positionAmount: this.positionAmount,
            searchQuery: {},
            searchResults: {
                items: []
            },
            searchResultsCount: 0,
            searchResultsAvailable: '',
            error: false,
            searchInputTypes: [
                'businessPartner',
                'order',
                'address',
                'contract',
                'contractPartner',
                'position'
            ],
            searchInputValue: '',
            paginationOffset: 1,
            paginationLimit: 5,
            showLoader: false,
            showLoaderPagination: false,
            animateResults: false
        };

        this.handleSearchInputChange = this.handleSearchInputChange.bind(this);
        this.handleCheckbox = this.handleCheckbox.bind(this);
        this.handlePaginationChange = this.handlePaginationChange.bind(this);
        this.handleSendSearchRequestOnBtnPress = this.handleSendSearchRequestOnBtnPress.bind(this);
        this.handleAjaxSearch = this.handleAjaxSearch.bind(this);
        this.handleSendSearchRequestOnEnter = this.handleSendSearchRequestOnEnter.bind(this);
    }

    componentWillMount() {
        let remoteSearchObj = '';

        if (this.remoteSearchQuery) {
            remoteSearchObj = JSON.parse(decodeURIComponent(this.remoteSearchQuery));
        }

        if (typeof remoteSearchObj === 'object') {
            this.setState({
                searchInputValue: remoteSearchObj.input,
                searchInputTypes: remoteSearchObj.types
            });
        }
    }

    componentDidMount() {
        let remoteSearchObj = '';

        if (this.remoteSearchQuery) {
            remoteSearchObj = JSON.parse(decodeURIComponent(this.remoteSearchQuery));
        }

        if (typeof remoteSearchObj === 'object') {
            this.handleAjaxSearch(null, this.remoteSearchQuery);
        }
    }

    /**
     * Set the search keyword.
     * @param {Object} event - event obj
     */
    handleSearchInputChange(event) {
        this.setState({ searchInputValue: event.target.value });
        this.setState({ searchResultsAvailable: '' });
    }

    /**
     * Wrap the searched key word in a <mark> tag inside of the displayed results.
     * @param {string} searchInputValue - stuff we like to find
     * @param {Object} searchResultsItems - search results
     * @returns {Object} search results with marked keyword
     */
    searchResultMarker(searchInputValue, searchResultsItems) {
        const searchResultsMarked = [];
        const searchInputValueArray = searchInputValue.trim().split(' ');

        searchResultsItems.map((item) => {
            const markedEntry = { ...item };
            markedEntry.data = {};

            Object.keys(item.data).map((key) => {
                let value = item.data[key];

                searchInputValueArray.map((searchString) => {
                    const regEx = new RegExp(searchString, 'ig');

                    const match = regEx.exec(value);
                    value = String(value).replace(regEx, `<mark>${match}</mark>`);
                });

                markedEntry.data[key] = value;
            });

            searchResultsMarked.push(
                markedEntry
            );
        });

        return searchResultsMarked;
    }

    /**
     * Sorts the search results obj. like we defined in the "this.searchResultsOrder" setting.
     * @param {Object} searchResultsItems - search results unsorted
     * @returns {Object} search results sorted
     */
    searchResultSorter(searchResultsItems) {
        const searchResultsSorted = [];
        searchResultsItems.map((item) => {
            const newItem = { ...item };
            newItem.data = {};

            this.searchResultsOrder.forEach((entry) => {
                if (item.data[entry] !== undefined) {
                    newItem.data[entry] = item.data[entry] || ' - ';
                }
            });

            searchResultsSorted.push(
                newItem
            );
        });

        return searchResultsSorted;
    }

    /**
     * Fires the ajax search request, receives the results and changes displayed result items.
     * Also handles the UI/UX stuff like loaders.
     * @param {number} page - current pagination offset
     */
    handleAjaxSearch(page, remoteSearchQuery) {
        let searchQuery = {};

        this.curPage = 1;
        if (page) {
            this.curPage = page;
        }

        if (remoteSearchQuery) {
            searchQuery = remoteSearchQuery;
        } else {
            searchQuery = {
                input: this.state.searchInputValue.trim(),
                types: this.state.searchInputTypes,
                paginationOffset: this.curPage,
                paginationLimit: this.state.paginationLimit
            };
        }

        this.setState({ showLoader: true });
        axios.post(this.searchRequestUrl, searchQuery)
            // Do after response:
            .then((response) => {
                const searchResults = response.data;
                const searchResultsCount = response.data.countTotal;
                const searchResultsSorted = this.searchResultSorter(searchResults.items);
                searchResults.items = this.searchResultMarker(this.state.searchInputValue, searchResultsSorted);

                let searchResultsAvailable = false;
                if (searchResultsCount > 0) {
                    searchResultsAvailable = true;
                }

                this.setState({
                    searchResults,
                    searchResultsCount,
                    searchResultsAvailable,
                    showLoader: false,
                    showLoaderPagination: false,
                    paginationOffset: this.curPage,
                    animateResults: true
                });
            // Do after error:
            }).catch((error) => {
                this.setState({
                    error: true,
                    showLoader: false,
                    showLoaderPagination: false,
                });
                console.error(`Error with sending search query via ajax to ${this.searchRequestUrl} `, error);
            // Do always
            }).then(
                this.setState({
                    animateResults: false
                })
            );
    }

    /**
     * Event handler for the search button.
     */
    handleSendSearchRequestOnBtnPress() {
        this.handleAjaxSearch();
    }

    /**
     * Event handler for enter key inside the search input.
     */
    handleSendSearchRequestOnEnter(e) {
        if (e.key === 'Enter') {
            this.handleAjaxSearch();
        }
    }

    /**
     * Event handler for the third party pagination component.
     * @param {number} page - choosen pagination offset
     */
    handlePaginationChange(page) {
        this.setState({ showLoaderPagination: true });
        this.handleAjaxSearch(page);
    }

    /**
     * Event handler for the third party checkbox component.
     * @param {array} options - choosen search areas
     */
    handleCheckbox(options) {
        this.setState({
            searchInputTypes: options
        });
    }

    /**
     * Rendering of the UI.
     */
    render() {
        const searchResultListItems = this.state.searchResults.items.map(item =>
            (<ResultItem
                key={`${item.data.event_id}-${item.data.contract_id}-${item.data.position_id}`}
                item={item}
                positionAmount={this.state.positionAmount}
                rebookActionCallBack={this.props.rebookActionCallBack}
            />)
        );
        return (
            <div>
                <SearchInputHeader
                    state={this.state}
                    handleSearchInputChange={this.handleSearchInputChange}
                    handleCheckbox={this.handleCheckbox}
                    handleSendSearchRequestOnBtnPress={this.handleSendSearchRequestOnBtnPress}
                    handleSendSearchRequestOnEnter={this.handleSendSearchRequestOnEnter}
                    positionVariantName={this.positionVariantName}
                    positionIsTarget={this.positionIsTarget}
                />

                <StatusInfo
                    searchResultsCount={this.state.searchResultsCount}
                    searchInputValue={this.state.searchInputValue}
                    searchResultsAvailable={this.state.searchResultsAvailable}
                />

                <HeadlineResults
                    searchResultsCount={this.state.searchResultsCount}
                    showLoader={this.state.showLoader}
                />

                <LoaderAnimation showLoader={this.state.showLoader} classes="m-b-30  m-t-30" />

                <div className="text-center">
                    <Pagination
                        onChange={this.handlePaginationChange}
                        current={this.state.paginationOffset}
                        defaultPageSize={this.state.paginationLimit}
                        total={this.state.searchResultsCount}
                        locale={paginationTranslations}
                        className="m-b-20 d-inline-block"
                        hideOnSinglePage
                    />
                </div>

                <ul className="list-unstyled">
                    <Animate
                        startAnimation={this.state.animateResults}
                        startStyle={{ opacity: 0 }}
                        endStyle={{ opacity: 1 }}
                    >
                        {searchResultListItems}
                    </Animate>
                </ul>

            </div>
        );
    }
}

Search.propTypes = propTypes;

export default Search;
