import $ from 'jquery';

const focusRow = (table) => {
    let $focusedRow = null;
    table.on('key-focus', (e, datatable, cell) => {
        $focusedRow = $(datatable.row(cell.index().row).node());
        $focusedRow.addClass('focused-row');
    });
    table.on('key-blur', (e, datatable, cell) => {
        $focusedRow = $(datatable.row(cell.index().row).node());
        $focusedRow.removeClass('focused-row');
    });

    const selectWithEnter = (e) => {
        if (!e.repeat && e.keyCode === 13 && $focusedRow !== null && $focusedRow.length > 0) {
            e.preventDefault();
            $focusedRow.click();
        }
    };

    window.addEventListener('keydown', selectWithEnter, false);
};

export default focusRow;
