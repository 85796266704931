/* eslint-disable consistent-return */
/* jshint -W079 */

import dataTablesMainInit, {
    renderLink,
    renderWithoutHTML
} from '../../../../LayoutBundle/js/dataTablesMainInit';
import {
    renderTransaction,
    renderDateTime,
    isTestTransaction,
    renderCommaSeparatedBreak,
} from '../dToutputRenderer';

const $ = window.jQuery;

const Translator = typeof window.Translator !== 'undefined' ? window.Translator : { trans: key => key };
const trans = rootKey => key => Translator.trans(`${rootKey}.${key}`, 'datatable');

const t = trans('online_shop.transaction');

$(document).ready(() => {
    const dTsettings = {};

    dTsettings.tableName = 'online_shop_transaction_datatable';
    dTsettings.selector = `#${dTsettings.tableName}`;

    if ($(dTsettings.selector).length === 0) return false;

    dTsettings.options = {
        order: [[1, 'desc']],
        orderCellsTop: true,
    };

    dTsettings.extensions = {
        colReorder: {},
    };

    dTsettings.columns = {
        columns: [
            {
                title: t('title.event'),
                searchable: true,
                orderable: true,
                data: 'event',
                name: 'event',
                render: renderLink,
            },
            {
                title: t('title.id'),
                searchable: true,
                orderable: true,
                data: 'id',
                name: 'id',
                render: renderTransaction,
            },
            {
                title: t('title.test'),
                searchable: true,
                orderable: false,
                data: 'test',
                name: 'test',
                render: isTestTransaction,
            },
            {
                title: t('title.ip3_user_id'),
                searchable: true,
                orderable: true,
                data: 'ip3_user_id',
                name: 'profile_data.profile.ip3_user_id',
            },
            {
                title: t('title.email_address'),
                searchable: true,
                orderable: true,
                data: 'email_address',
                name: 'profile_data.profile.email_address',
                render: renderWithoutHTML,
            },
            {
                title: t('title.full_name'),
                searchable: true,
                orderable: true,
                data: 'full_name',
                name: 'profile_data.full_name',
                render: renderWithoutHTML,
            },
            {
                title: t('title.company_name'),
                searchable: true,
                orderable: true,
                data: 'company_name',
                name: 'profile_data.business_data.company_name',
                render: renderWithoutHTML,
            },
            {
                title: t('title.status'),
                searchable: true,
                orderable: true,
                data: 'status',
                name: 'status',
            },
            {
                title: t('title.payment_type'),
                searchable: true,
                orderable: true,
                data: 'payment_type',
                name: 'payment_type',
            },
            {
                title: t('title.invoice_number'),
                searchable: true,
                orderable: true,
                data: 'invoice_number',
                name: 'invoice_number',
                render: renderCommaSeparatedBreak,
            },
            {
                title: t('title.cancellation_number'),
                searchable: true,
                orderable: true,
                data: 'cancellation_numbers',
                name: 'transaction_cancellations.cancellation_number',
                render: renderCommaSeparatedBreak,
            },
            {
                title: t('title.created_at'),
                searchable: true,
                orderable: true,
                data: 'created_at',
                name: 'created_at',
                render: renderDateTime,
            },
            {
                title: t('title.gross_total'),
                searchable: true,
                orderable: true,
                data: 'gross_total',
                name: 'gross_total',
            },
        ]
    };

    dTsettings.multiActions = [];

    dataTablesMainInit(dTsettings);
});
