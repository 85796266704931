import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Modal from 'react-modal';
import { ThreeDots }  from 'react-loader-spinner' ;
import deleteModalStyles from './deleteModalStyles';
import actionBarStyles from './actionBarStyles';
import {
    subEventDetails,
    subEventTableRoute,
    errorConsole,
} from '../../api';
import {
    setStatus,
    setDeleteConfirmOpen,
    setInEditMode,
} from './actionBarReducer';
import {
    setSubEventId,
} from '../CardCoreData/coreDataReducer';
import {
    setIsPending,
    setErrors,
} from '../SubEventDetailsShell/shellReducer';
import {
    setVariants,
} from '../CardProductVariants/productVariantsReducer';
import {
    setTimeTables,
} from '../CardPlanning/planningReducer';
import {
    requestData,
} from '../SubEventDetailsShell/SubEventDetailsShell';
 
Modal.setAppElement('#app');

const ActionBar = () => {
    const [showRedirectMsg, setShowRedirectMsg] = useState(false);
    const dispatch = useDispatch();
    const status = useSelector(state => state.actionBar.status);
    const deleteConfirmOpen = useSelector(state => state.actionBar.deleteConfirmOpen);
    const subEventId = useSelector(state => state.coreData.subEventId);
    const name = useSelector(state => state.coreData.name);
    const inEditMode = useSelector(state => state.actionBar.inEditMode);
    const clientId = useSelector(state => state.shell.clientId);
    const eventId = useSelector(state => state.shell.eventId);
    const isPending = useSelector(state => state.shell.isPending);

    const apiRequest = async (operator) => {
        dispatch(setIsPending(true));
        dispatch(setErrors([]));
        try {
            const response = await subEventDetails(
                operator, {
                    id: subEventId,
                    event: eventId,
                }
            );
            const { data } = response;
            dispatch(setInEditMode(false));

            if (operator === 'create') {
                dispatch(setSubEventId(data));
            }

            if (operator === 'update') {
                dispatch(setTimeTables(data));
            }

            if (operator === 'delete') {
                setShowRedirectMsg(true);
                const redirectRoute = subEventTableRoute({
                    clientId,
                    event: eventId,
                });
                window.location.assign(redirectRoute);
            }

            const freshData = await subEventDetails(
                'get', {
                    id: subEventId,
                    event: eventId,
                }
            );

            if (freshData.data.sub_event_variants.length > 0) {
                const freshVariants = freshData.data.sub_event_variants.map(v => ({
                    id: v.id,
                    gross_amount: v.gross_amount,
                    variant_id: v.variant.id,
                    name: v.variant.name,
                    product_no: v.variant.product_no,
                }));
                dispatch(setVariants(freshVariants));
            }

            if (freshData.data.sub_event_plannings.length > 0) {
                dispatch(setTimeTables(freshData.data));
            }
        } catch (error) {
            if (error.response) {
                if (error.response.data.errors.status) {
                    dispatch(setErrors({ global: error.response.data.errors.status }));
                } else {
                    dispatch(setErrors(error.response.data.errors));
                }
                errorConsole.response(error);
            } else if (error.request) {
                dispatch(setErrors({ global: [window.Translator.trans('sub_event.errors.global_default')] }));
                errorConsole.request(error);
            } else {
                if (error.message === 'Invalid time value') {
                    dispatch(setErrors({ global: [window.Translator.trans('sub_event.errors.invalid_time_value')] }));
                } else {
                    dispatch(setErrors({ global: [window.Translator.trans('sub_event.errors.global_default')] }));
                }
                errorConsole.else(error);
            }
        } finally {
            dispatch(setIsPending(false));
        }
    };

    const handleDelete = async () => {
        await apiRequest('delete');
    };

    const handleReload = async () => {
        await requestData(dispatch, {
            subEventId,
            eventId,
        });
    }

    return (
        <div className="row">
            <div className="col">
                <div className="card">
                    <div className="btn-toolbar p-2 mb-0">
                        <div className="btn-group ms-2" role="group" aria-label="First group">
                            <label style={actionBarStyles.label} htmlFor="statusSelect"> {window.Translator.trans('sub_event.action_bar.status.label')} </label>
                            {inEditMode ? (
                                <select
                                    id="statusSelect"
                                    name="statusSelect"
                                    className="me-2 border border-primary rounded"
                                    onChange={e => dispatch(setStatus(e.target.value))}
                                    value={status}
                                >
                                    <option value="draft"> {window.Translator.trans('sub_event.action_bar.status.draft')} </option>
                                    <option value="published"> {window.Translator.trans('sub_event.action_bar.status.published')} </option>
                                </select>
                            ) : (
                                <span style={{
                                    marginRight: 19,
                                    lineHeight: 2,
                                    fontWeight: 400,
                                }}
                                >
                                    {window.Translator.trans(`sub_event.action_bar.status.${status}`)}
                                </span>
                            )}

                            {inEditMode ? (
                                <button
                                    className="me-2 btn btn-primary btn-sm"
                                    onClick={() => apiRequest(subEventId ? 'update' : 'create')}
                                >
                                    <i className="fas fa-save" />{'\u00A0'}
                                    {subEventId ?
                                        window.Translator.trans('sub_event.action_bar.save')
                                        :
                                        window.Translator.trans('sub_event.action_bar.create')
                                    }
                                </button>
                            ) : (
                                <button
                                    className="me-2 btn btn-primary btn-sm"
                                    onClick={() => dispatch(setInEditMode(true))}
                                >
                                    <i className="fas fa-edit" /> {window.Translator.trans('sub_event.action_bar.update')}
                                </button>
                            )}

                            {subEventId && (
                                <button
                                    onClick={() => dispatch(setDeleteConfirmOpen(true))}
                                    className="btn btn-danger btn-sm"
                                >
                                    <i className="fas fa-trash-alt" /> {window.Translator.trans('sub_event.action_bar.delete')}
                                </button>
                            )}

                            <Modal
                                isOpen={deleteConfirmOpen}
                                onRequestClose={() => dispatch(setDeleteConfirmOpen(false))}
                                style={deleteModalStyles}
                                contentLabel="Sicherheitsabfrage"
                                shouldCloseOnOverlayClick={false}
                            >
                                <div>
                                    {showRedirectMsg ? (
                                        <div className="text-center">
                                            <p style={{ fontSize: '18px' }}> {window.Translator.trans('sub_event.action_bar.redirect')} </p>
                                        </div>
                                    ) : (
                                        <div className="text-center">
                                            <p style={{ fontSize: '18px' }}>
                                                {window.Translator.trans('sub_event.action_bar.delete_confirm_1')}
                                                <br />
                                                <span style={{ fontSize: '18px' }} className="fw-bold">
                                                    {name} (ID {subEventId})
                                                </span>
                                                <br />
                                                {window.Translator.trans('sub_event.action_bar.delete_confirm_2')}
                                            </p>
                                            {isPending ? (
                                                <div>
                                                    <ThreeDots
                                                        color="#93dcfc"
                                                        height="48"
                                                        width="100"
                                                    />
                                                </div>
                                            ) : (
                                                <div>
                                                    <button disabled={isPending} className="btn btn-primary m-r-15" onClick={() => dispatch(setDeleteConfirmOpen(false))}>
                                                        {window.Translator.trans('sub_event.action_bar.cancel')}
                                                    </button>
                                                    <button disabled={isPending} className="btn btn-danger" onClick={handleDelete}>
                                                        {window.Translator.trans('sub_event.action_bar.delete')}
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </Modal>
                        </div>
                        <button
                            style={{ marginLeft: 'auto', order: 2}}
                            onClick={() => handleReload()} className="btn btn-primary btn-sm">
                                <i className="fas fa-refresh" />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ActionBar;
