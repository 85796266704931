import React from 'react';

import PropTypes from 'prop-types';

import {
    ResultListingItem,
    ItemColIcon,
    ItemColLabel,
    ResultListingItemLabel
} from '../SeachSelectUI';

// Prop Types
const propTypes = {
    searchResult: PropTypes.shape({
        id: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number
        ]).isRequired,
        label: PropTypes.object.isRequired,
    }).isRequired,
    resultIcon: PropTypes.string,
    handleOnClick: PropTypes.func
};

const defaultProps = {
    resultIcon: 'fas fa-carret-down',
    handleOnClick: () => null
};

const ResultListingItemStandard = (props) => {
    const { searchResult, resultIcon } = props;

    return (
        <ResultListingItem className={`result_${searchResult.id}`} onClick={props.handleOnClick}>
            <ItemColIcon>
                <i className={resultIcon} style={{ lineHeight: 1 }} />
            </ItemColIcon>
            {searchResult.label.address !== '' ? (
                <ItemColLabel>
                    {searchResult.label.address.split('\n').map((item, i) => (
                        <ResultListingItemLabel key={i}>
                            {item}
                            {searchResult.label.address.length < i && <br />}
                        </ResultListingItemLabel>
                    ))}
                </ItemColLabel>
            ) : null}
        </ResultListingItem>
    );
};

ResultListingItemStandard.propTypes = propTypes;
ResultListingItemStandard.defaultProps = defaultProps;

export default ResultListingItemStandard;
