/* eslint-disable */

import $ from 'jquery';
import axios from 'axios';

const confirmModal = window.confirmModal;
const Translator = window.Translator;

export class OnlineShopBackOffice {
    init() {
        $(document).ready(() => {
            $(".js-enable-on-ready").prop('disabled', false);
            this.beforeSubmit();
        });
    }

    beforeSubmit() {
        const self = this;

        $('form#transaction_refund').on('submit', function (event) {
            event.preventDefault();
            const formData = new FormData(this);
            if (self.formSubmit($(this), formData)) {
                $(".cancel-entry").prop('disabled', true);
            }

            return false;
        });

        $('form.transaction_entry_refund').on('submit', function (event) {
            event.preventDefault();
            const formData = new FormData(this);
            if (self.formSubmit($(this), formData)) {
                $("#transaction-cancel-btn").prop('disabled', true);
            }

            return false;
        });

        $('form.transaction_sent_to_ip3').on('submit', function (event) {
            event.preventDefault();
            const formData = new FormData(this);
            self.formSubmit($(this), formData);

            return false;
        });

        $('form.refund_transactions').on('submit', function (event) {
            event.preventDefault();
            const formData = new FormData(this);
            self.formSubmit($(this), formData);

            return false;
        });

        $('form#transaction_set_test').on('submit', function (event) {
            event.preventDefault();
            const formData = new FormData(this);
            self.formSubmit($(this), formData, 'transaction_set_test');

            return false;
        });

        $('form.send_confirmation_email').on('submit', function (event) {
            event.preventDefault();
            const formData = new FormData(this);
            self.formSubmit($(this), formData, 'send_confirmation_email');

            return false;
        });
    }

    formSubmit(form, formData, formName = '') {
        const self = this;
        const url = form.attr('action');
        const ajaxPayload = {};
        let labelHeader = '';
        let reply = false;

        const btnAction = () => {
            form.find('.loadingSpinner').removeClass('h-display-none');
            self.disableBtn(form.find('button'));
            axios.post(url, ajaxPayload).then((response) => {
                const jsonData = response.data;

                $('body,html').animate({
                    scrollTop: 0
                }, 400);

                form.find('.loadingSpinner').addClass('h-display-none');

                let alertType = '';
                if (jsonData.status === 'ERROR') {
                    self.enableBtn(form.find('button'));
                    alertType = 'danger';
                }

                if (jsonData.status === 'SUCCESS') {
                    alertType = 'success';
                    reply = true;
                }

                if (jsonData.message && jsonData.message.length !== 0) {
                    self.flashMessage(jsonData.message, alertType);
                }

                if (jsonData.reload && jsonData.reload === true) {
                    setTimeout(() => {
                        location.reload();
                    }, 500);
                }

                if (formName === 'transaction_set_test') {
                    if (jsonData.currentValue === true) {
                        $('#test-tag').removeClass('h-display-none');
                        $('#test-btn').html(Translator.trans('shopapi.ticketshop.details.test.label.as_not_test'));
                    } else {
                        $('#test-tag').addClass('h-display-none');
                        $('#test-btn').html(Translator.trans('shopapi.ticketshop.details.test.label.as_test'));
                    }

                    self.enableBtn(form.find('button'));
                }
            });
        };

        switch (formData.get('type')) {
            case 'refund_online_shop_transaction':
                labelHeader = 'shopapi.ticketshop.details.booked_services.refund_entry_info';
                break;
            case 'transaction_sent_to_ip3':
                labelHeader = 'shopapi.ticketshop.details.sent_transaction_to_ip3_info';
                break;
            case 'transaction_entry':
                labelHeader = 'shopapi.ticketshop.refund.transaction_entry';
                break;
            case 'transaction':
                labelHeader = 'shopapi.ticketshop.refund.transaction';
                break;
            case 'refund_transactions':
                labelHeader = 'shopapi.ticketshop.refund.transactions';
                break;
            case 'send_confirmation_email':
                labelHeader = 'shopapi.ticketshop.details.send_confirmation_email';
                if (formData.get('isSent') === '1') {
                    labelHeader = 'shopapi.ticketshop.details.send_confirmation_email_again';
                }
                if (formData.get('isCancelled') === '1') {
                    labelHeader = 'shopapi.ticketshop.details.send_confirmation_email_again';
                }
                break;
            default:
                labelHeader = '';
        }

        if (labelHeader.length !== 0) {
            confirmModal(
                Translator.trans(labelHeader),
                Translator.trans('generic.button.yes'),
                Translator.trans('generic.button.cancel'),
                { acceptFunction: btnAction }
            );
        } else {
            btnAction();
        }

        return reply;
    }

    disableBtn(btn) {
        btn.prop('disabled', true);
    }

    enableBtn(btn) {
        btn.prop('disabled', false);
    }

    flashMessage(text, type = 'success') {
        const alertBox = `
            <div class="alert alert-${type} alert-dismissable">
                <button type="button" class="close" data-bs-dismiss="alert" aria-hidden="true">
                    &times;
                </button>
                ${text}
            </div>`;
        $('#alert-box').append(alertBox);
    }
}
export default new OnlineShopBackOffice();
