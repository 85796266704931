const createDateRange = (dateStart, dateEnd) => {
    const dateArray = [];
    const currentDate = new Date(dateStart);

    while (currentDate <= new Date(dateEnd)) {
        dateArray.push(new Date(currentDate).toISOString().split('T')[0]);
        currentDate.setUTCDate(currentDate.getUTCDate() + 1);
    }

    return dateArray;
};

export default createDateRange;
