import React, {
    useState,
    useRef,
    useEffect,
    Fragment
} from 'react';
import {
    Container,
    CurrentChoice,
    InputSearch,
    IconClearCurrentChoice,
    IconClearSearchTerm,
    IconSearch,
    ResultListing,
    ResultsInfo,
    SmallLoaderCentered,
    ChoiceLoaderOverlay
} from './SeachSelectUI';
import ResultListingItemStandard from './ResultListingItemStandard/ResultListingItemStandard';
import ResultListingItemAddress from './ResultListingItemAddress/ResultListingItemAddress';
import PropTypes from 'prop-types';
import axios from 'axios';
import { ThreeDots } from 'react-loader-spinner';
import { find } from 'lodash';
import useDebounce from '../utils/react/hooks/useDebounce';
import trans from '../utils/translations/trans';

// Init Transe
const t = trans('layout.search_select');

// Prop Types
const propTypes = {
    config: PropTypes.shape({
        searchRoute: PropTypes.string.isRequired,
        getExistingChoiceByIdRoute: PropTypes.string.isRequired,
        searchTriggerThreshold: PropTypes.number.isRequired,
        inputEle: PropTypes.object.isRequired,
        searchRoutePayload: PropTypes.object,
        scrollingLimit: PropTypes.number,
        resultItem: PropTypes.string,
        type: PropTypes.string,
        mode: PropTypes.string,
        formBtnNext: PropTypes.object,
        isRequired: PropTypes.bool,
        uuid: PropTypes.uuid,
        allSearchSelects: PropTypes.array,
    }).isRequired,
};

// For future extension of search result item renderings
const ResultListingItem = {
    standard: ResultListingItemStandard,
    address: ResultListingItemAddress
};

// Main Component
const SearchSelectApp = (props) => {
    // DOM-Refs
    const inputSearchRef = useRef();
    const ResultListingItemComp = ResultListingItem[props.config.resultItem || 'standard'];

    // States
    const standardStates = {
        currentChoice: {
            id: '',
            label: {
                address: t('please_select')
            },
            isPlaceholder: true
        },
        offsetValue: 0,
        totalResultCount: 0,
        searchResults: [],
    };

    const [choicesAreVisible, setChoicesVisible] = useState(false);
    const [currentChoice, setCurrentChoice] = useState(standardStates.currentChoice);
    const [searchResults, setSearchResults] = useState(standardStates.searchResults);
    const [searchTerm, setSearchTerm] = useState('');
    const [isSearching, setIsSearching] = useState(false);
    const [isChoiceLoading, setIsChoiceLoading] = useState(false);
    const [offsetValue, setOffsetValue] = useState(standardStates.offsetValue);
    const [totalResultCount, setTotalResultCount] = useState(standardStates.totalResultCount);
    const [hasScrolledToBottom, setHasScrolledToBottom] = useState(false);
    const [hide, setHide] = useState(false);

    const searchRequest = async (st = '', mode, choicesVisible = true) => {
        try {
            setIsSearching(true);

            let data = {
                searchTerm: st,
                offset: offsetValue * (props.config.scrollingLimit || 10),
                limit: props.config.scrollingLimit || 10
            };

            if (typeof props.config.searchRoutePayload !== 'undefined') {
                data = { ...data, ...props.config.searchRoutePayload };
            }

            if (
                (
                    props.config.type === 'secondary_contact_person' ||
                    props.config.type === 'shipping_address' ||
                    props.config.type === 'invoice_address'
                ) &&
                props.config.mode === 'edit' &&
                window.searchSelect
            ) {
                const arr = [];

                // consider bp in results if not contact person tab
                if (
                    props.config.type !== 'secondary_contact_person' &&
                    window.searchSelect.business_partner_edit &&
                    window.searchSelect.business_partner_edit.inputValue
                ) {
                    arr.push(window.searchSelect.business_partner_edit.inputValue);
                }

                // consider bp replacement in results
                if (window.searchSelect.business_partner_replacement_edit &&
                    window.searchSelect.business_partner_replacement_edit.inputValue
                ) {
                    arr.push(window.searchSelect.business_partner_replacement_edit.inputValue);
                }

                data.businessPartnerIds = arr;
            }

            const request = {
                method: 'post',
                url: window.Routing.generate(props.config.searchRoute),
                headers: {
                    'X-Requested-With': 'XMLHttpRequest'
                },
                data
            };

            const response = await axios(request);

            setTotalResultCount(response.data.totalResultCount);
            setIsSearching(false);

            if (mode === 'append') {
                setSearchResults([...searchResults, ...response.data.searchResults]);
            } else {
                setSearchResults(response.data.searchResults);
            }
            setChoicesVisible(choicesVisible);
        } catch (e) {
            console.error('searchRequest', e);
            setIsSearching(false);
        }
    };

    // Actions
    const toggleChoicesVisible = () => {
        if (!props.config.inputEle.disabled) {
            if (!choicesAreVisible && searchResults.length === 0 && isSearching === false) {
                searchRequest('');
            }
            setChoicesVisible(!choicesAreVisible);
        }
    };

    const selectChoice = (id) => {
        if (searchResults.length > 0) {
            const selectedResult = find(searchResults, o => o.id === id);
            setCurrentChoice(selectedResult);
            setChoicesVisible(false);
        }
    };

    const handleSearchInput = (searchInput) => {
        setSearchTerm(searchInput);
    };

    const handleClearInput = () => {
        setCurrentChoice(standardStates.currentChoice);
        searchRequest('', '', false);
    };

    const handleClearSearchTerm = () => {
        setSearchTerm('');
        inputSearchRef.current.value = '';
        inputSearchRef.current.focus();
        searchRequest();
    };

    const debouncedSearchTerm = useDebounce(searchTerm);

    const getExistingChoiceById = async (id) => {
        try {
            setIsChoiceLoading(true);
            const request = {
                method: 'get',
                url: window.Routing.generate(props.config.getExistingChoiceByIdRoute, {
                    id
                }),
                headers: {
                    'X-Requested-With': 'XMLHttpRequest'
                }
            };

            const response = await axios(request);
            setCurrentChoice(response.data.searchResults[0]);

            setIsChoiceLoading(false);
        } catch (e) {
            console.error('getExistingChoiceById', e);
        }
    };

    const handleResultsScrolling = (e) => {
        const ele = e.target;
        const scrollingThreshold = 100;
        if ((ele.scrollTop + scrollingThreshold) > (ele.scrollHeight - ele.offsetHeight)) {
            setHasScrolledToBottom(true);
        } else if (hasScrolledToBottom) {
            setHasScrolledToBottom(false);
        }
    };

    const resultIcon = (searchResult) => {
        let iconName = 'home';

        if (searchResult.isPlaceholder === true) {
            iconName = 'caret-down';
        } else if (searchResult.isMainAddress === true) {
            iconName = 'building';
        } else if (searchResult.sourceSystem === 'source_system_xpATobs') {
            iconName = 'cloud-download';
        } else if (searchResult.sourceSystem === 'source_system_dmag_account') {
            iconName = 'cloud-download';
        } else if (searchResult.type === 'delivery_address') {
            iconName = 'truck';
        } else if (searchResult.type === 'business_partner_address') {
            iconName = 'user';
        } else if (searchResult.type === 'representative_address') {
            iconName = 'male';
        } else if (searchResult.type === 'invoice_address') {
            iconName = 'usd';
        }

        return `fas fa-${iconName}`;
    };

    // Side Effects
    useEffect(() => {
        const existingChoice = parseInt(props.config.inputEle.value, 10);
        if (!isNaN(existingChoice)) {
            getExistingChoiceById(existingChoice);
        }

        if (props.config.type && props.config.mode) {
            if (!window.searchSelect) {
                window.searchSelect = {};
            }
            if (!window.searchSelect[`${props.config.type}_${props.config.mode}`]) {
                window.searchSelect[`${props.config.type}_${props.config.mode}`] = {
                    config: {}
                };
            }
            window.searchSelect[`${props.config.type}_${props.config.mode}`].config = { ...props.config };
            window.searchSelect[`${props.config.type}_${props.config.mode}`].setHide = val => setHide(val);
            window.searchSelect[`${props.config.type}_${props.config.mode}`].setCurrentChoice = val => setCurrentChoice(val);
            window.searchSelect[`${props.config.type}_${props.config.mode}`].clearInput = () => handleClearInput();
            window.searchSelect[`${props.config.type}_${props.config.mode}`].getNewSearchResults = () => searchRequest('', '', false);

            if (props.config.type && props.config.mode && window.searchSelect[`${props.config.type}_${props.config.mode}`]) {
                window.searchSelect[`${props.config.type}_${props.config.mode}`].inputValue = currentChoice.id;
            }
        }

        if (
            (
                props.config.type === 'secondary_contact_person'
            ) &&
            props.config.mode === 'edit' &&
            window.searchSelect &&
            window.searchSelect.business_partner_replacement_edit &&
            !window.searchSelect.business_partner_replacement_edit.inputValue
        ) {
            setHide(true);
        }
    }, []);

    useEffect(() => {
        if (choicesAreVisible) {
            inputSearchRef.current.focus();
        }
    }, [choicesAreVisible]);

    useEffect(() => {
        setSearchResults(standardStates.searchResults);
        setTotalResultCount(standardStates.totalOffsetCount);
        setOffsetValue(standardStates.offsetValue);
    }, [searchTerm]);

    useEffect(() => {
        if (choicesAreVisible && (searchTerm.length === 0 || searchTerm.length >= props.config.searchTriggerThreshold)) {
            searchRequest(debouncedSearchTerm);
        }
    }, [debouncedSearchTerm]);

    useEffect(() => {
        if (searchResults.length < totalResultCount && isSearching === false) {
            setOffsetValue(offsetValue + 1);
        }
    }, [hasScrolledToBottom]);

    useEffect(() => {
        if (offsetValue > 0 && isSearching === false) {
            searchRequest(debouncedSearchTerm, 'append');
        }
    }, [offsetValue]);

    useEffect(() => {
        if (typeof currentChoice.id !== 'undefined') {
            props.config.inputEle.value = currentChoice.id;

            if (props.config.type && props.config.mode && window.searchSelect[`${props.config.type}_${props.config.mode}`]) {
                window.searchSelect[`${props.config.type}_${props.config.mode}`].inputValue = currentChoice.id;
            }

            if (props.config.type === 'secondary_contact_person' && props.config.mode === 'edit' &&
                window.searchSelect &&
                window.searchSelect.business_partner_replacement_edit &&
                !window.searchSelect.business_partner_replacement_edit.inputValue
            ) {
                setHide(true);
            }
        }

        if (currentChoice.id === '' && props.config.allSearchSelects.filter(o => o.isRequired).map(o => o.uuid).includes(props.config.uuid)) {
            props.config.formBtnNext.disabled = true;
        } else if (props.config.isRequired) {
            props.config.formBtnNext.disabled = false;
        }

        if (props.config.type === 'business_partner_replacement' && props.config.mode === 'edit' &&
            window.searchSelect.secondary_contact_person_edit &&
            window.searchSelect.shipping_address_edit &&
            window.searchSelect.invoice_address_edit
        ) {
            if (currentChoice.id) {
                window.searchSelect.secondary_contact_person_edit.setHide(false);
            } else {
                window.searchSelect.secondary_contact_person_edit.setHide(true);
            }
            window.searchSelect.secondary_contact_person_edit.clearInput();
            window.searchSelect.shipping_address_edit.clearInput();
            window.searchSelect.invoice_address_edit.clearInput();
        }
    }, [currentChoice]);

    if (hide) {
        return (
            <p>
                <i>Dieses Feld erfordert die Auswahl eines Geschäftspartnervertreters.</i>
            </p>
        );
    }
    return (
        <div className={`card ${props.config.inputEle.disabled ? 'h-disabled' : ''}`}>
            <CurrentChoice
                onClick={() => toggleChoicesVisible()}
                hasPlaceholder={currentChoice.isPlaceholder === true || props.config.inputEle.disabled === true}
            >
                {isChoiceLoading === true && (
                    <ChoiceLoaderOverlay onClick={e => e.stopPropagation()}>
                        <SmallLoaderCentered style={{ marginTop: '12px' }}>
                            <ThreeDots
                                color="#93dcfc"
                                height="20"
                                width="35"
                            />
                        </SmallLoaderCentered>
                    </ChoiceLoaderOverlay>
                )}

                <div className="card m-b-0">
                    <ResultListingItemComp
                        searchResult={currentChoice}
                        resultIcon={resultIcon(currentChoice)}
                    />
                </div>

                {currentChoice.id !== standardStates.currentChoice.id && !props.config.inputEle.disabled && (
                    <IconClearCurrentChoice onClick={() => handleClearInput()} />
                )}
            </CurrentChoice>
            {choicesAreVisible && (
                <Container>
                    <IconSearch />
                    <InputSearch
                        className={isSearching ? 'h-disabled' : ''}
                        onChange={e => handleSearchInput(e.target.value)}
                        ref={inputSearchRef}
                        placeholder={t('enter_search_term').replace('%threshold%', props.config.searchTriggerThreshold)}
                    />
                    {searchTerm.length > 0 && (
                        <IconClearSearchTerm onClick={() => handleClearSearchTerm()} />
                    )}

                    {totalResultCount === 0 && (
                        <ResultsInfo className="border-warning">
                            <span> {t('no_results')}  <i className="far fa-frown" /> </span>
                        </ResultsInfo>
                    )}

                    {searchResults.length > 0 && (
                        <Fragment>
                            <ResultListing onScroll={e => handleResultsScrolling(e)}>
                                {searchResults.map(searchResult => (
                                    <ResultListingItemComp
                                        key={`result_${searchResult.id}`}
                                        searchResult={searchResult}
                                        handleOnClick={() => selectChoice(searchResult.id)}
                                        resultIcon={resultIcon(searchResult)}
                                    />
                                )
                                )}
                            </ResultListing>
                            {searchResults.length > 0 && (
                                <ResultsInfo>
                                    {t('results_info')
                                        .replace('%searchResultsLength%', searchResults.length)
                                        .replace('%totalResultCount%', totalResultCount)
                                    }
                                </ResultsInfo>
                            )}
                        </Fragment>
                    )}

                    {isSearching === true && (
                        <SmallLoaderCentered>
                            <ThreeDots
                                color="#93dcfc"
                                height="20"
                                width="35"
                            />
                        </SmallLoaderCentered>
                    )}
                </Container>
            )}
        </div>
    );
};

SearchSelectApp.propTypes = propTypes;
export default SearchSelectApp;
