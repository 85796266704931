const assetImgUrl = `${window.location.origin}/bundles/miofairutilities/images/`;
const backgroundLayoutUrl = `${assetImgUrl}print_templates/domotex/full_event_ticket_eticket.svg`;
const dummyQRcodeUrl = `${assetImgUrl}dummy-qr-code.png`;

const domotexFullEticketHtml = /* html */`
<!DOCTYPE html>
<html lang="de">

<head>
    <meta charset="utf-8">
    <title> Domotex Dauerticket E-Ticket</title>
    <style>
        * {
            box-sizing: border-box;
            margin: 0;
            padding: 0;
            border: 0;
        }

        /* Don't remove this, we replace this with live-preview-only styles */
        /* @preview-styles */

        html,
        body,
        .ticket-container {
            width: 210mm;
            height: 297mm;
            position: relative;
        }

        .ticket-container {
            overflow: hidden;
        }

        html {
            zoom: 129.8%;
        }


        body:after {
            display: block;
            width: 100%;
            content: 'Muster';
            font-family: Arial, sans-serif;
            font-weight: bold;
            text-align: center;
            text-transform: uppercase;
            letter-spacing: 4px;
            color: red;
            opacity: 0.2;
            transform: rotate(-45deg);
            -webkit-transform: rotate(-45deg);
            z-index: 9;
            position: absolute;
            left: 0mm;
            top: 117mm;
            font-size: 150px;
        }

        .ticket-container {
            font-family: 'Arial', sans-serif;
            position: relative;
            background-image: url(${backgroundLayoutUrl});
            background-size: cover;
            -webkit-background-size: cover;
        }

        .personal-data {
            color: #34a9e0;
            width: 72mm;
            height: 30mm;
            font-size: 11px;
            font-family: "Courier New", Courier, "Lucida Sans Typewriter", "Lucida Typewriter", monospace;

            position: absolute;
            right: 14mm;
            top: 59mm;
            line-height: 2;
        }

        .registration-code {
            color: #34a9e0;
            font-size: 10px;
            font-family: 'Arial', sans-serif;
            font-weight: bold;
            position: absolute;
            right: 44.5mm;
            top: 127.5mm;
            line-height: 2;
        }

        .person-container {
            position: absolute;
            left: 12.6mm;
            top: 115mm;
            line-height: 1.3;
        }

        .person-registration-label,
        .person-registration-code {
            font-size: 10px;
        }

        .person-name {
            font-weight: bold;
        }

        .person-adress {
            font-weight: bold;
            font-size: 14px;
        }

        .qr-code {
            width: 30.5mm;
            height: auto;
            border: 1px solid #34a9e0;
            padding: 3mm;
            background: #fff;
        }

        .qr-code--header {
            position: absolute;
            left: 82mm;
            top: 12mm;
        }

        .qr-code--footer {
            position: absolute;
            right: 12.5mm;
            top: 103mm;
            width: 29.5mm;
        }
    </style>
</head>

<body>
    <div class="ticket-container">

        <div class="personal-data">
            <p> Zeile1 Zeile1 Zeile1 Zeile1 Zeile1 </p>
            <p> Zeile2 Zeile2 Zeile2 Zeile2 Zeile2 </p>
            <p> Zeile3 Zeile3 Zeile3 Zeile3 Zeile3 </p>
            <p> Zeile4 Zeile4 Zeile4 Zeile4 Zeile4 </p>
            <p> Zeile5 Zeile5 Zeile5 Zeile5 Zeile5 </p>
        </div>

        <div class="person-container">
            <p class="person-registration-label"> Registriert ist / <i>Registered is:</i> </p>
            <p class="person-name"> Max Mustermann </p>
            <p class="person-adress"> Karlsruher Str. 4, 30880 Laatzen </p>
            <p class="person-registration-code"> Registrierungscode / <i>Ticket Code</i> XXXXXX </p>
        </div>

        <img class="qr-code qr-code--header" src="${dummyQRcodeUrl}" />
        <img class="qr-code qr-code--footer" src="${dummyQRcodeUrl}" />

        <p class="registration-code"> XXXXXXXXXX XXXXXXXX </p>
    </div>
</body>

</html>
`;
export default domotexFullEticketHtml;
