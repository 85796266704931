import React, { useState } from 'react';
import { createRoot }  from 'react-dom/client';
import styled from 'styled-components';
import axios from 'axios';
import { Puff } from 'react-loader-spinner';
import domotexFullEticketHtml from './html/domotex/fullEventTicket/eTicket/fullEventTicket_eTicketHtml';
import domotexFullTicketHtml from './html/domotex/fullEventTicket/ticket/fullEventTicket_ticketHtml';
import domotexDayTicketHtml from './html/domotex/dayEventTicket/ticket/dayEventTicket_ticketHtml';

const Wrapper = styled.div`
    padding: 30px;
    margin: auto;
    position: relative;
`;

const Row = styled.div`
    display: flexbox;
`;

const Col = styled.div`
    width: 30%;
    &:nth-child(2) {
        width: 70%;
        padding-left: 15px;
        min-height: 20vh;
    }
`;

const ContainerLoader = styled.div`
    border: 1px solid #0092c9;
    border-radius: 0.25rem;
    background: rgba(255, 255, 255, 0.75);
    position: absolute;
    z-index: 9;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
`;

const LoaderStyled = styled.div`
    position: absolute;
    width: 100px;
    height: 100px;
    top: 50%;
    left: 50%;
    margin-top: -50px;
    margin-left: -25px;
`;

const TextareaForSettings = styled.textarea`
    padding: 10px;
    display: block;
    width: auto;
    min-height: 12vh;
    width: 100%;
    margin-bottom: 10px;
`;

const TextareaForCode = styled.textarea`
    padding: 10px;
    display: block;
    width: auto;
    min-height: 37.7vh;
    width: 100%;
    margin-bottom: 10px;
`;

const Preview = styled.iframe`
    width: 100%;
    min-height: 56vh;
    resize: both;
`;

const previewStyles = `
    html {
        margin: auto;
        box-shadow: 3px 5px 8px rgba(0, 0, 0, 0.34);
        margin-top: 15px;
        margin-bottom: 15px;
        zoom: 100%;
    }
`;

const PdfTest = () => {
    const [pdfUrl, setPdfUrl] = useState(null);
    const [htmlValue, setHtmlValue] = useState(domotexDayTicketHtml);
    const [settingsValue, setSettingsValue] = useState(`
    [
        {"header-spacing" : "0"},
        {"margin-top" : "0"},
        {"margin-left" : "0"},
        {"margin-right" : "0"},
        {"margin-bottom" : "0"},
        {"page-size" : "A4"}
    ]
    `);
    const [error, setError] = useState(null);
    const [reqDisabled, setReqDisabled] = useState(false);

    const requestPdf = async () => {
        const bodyFormData = new FormData();
        bodyFormData.append('html', htmlValue);

        if (settingsValue !== '') {
            const settings = JSON.parse(settingsValue);
            settings.forEach((setting) => {
                const settingArr = Object.entries(setting);
                bodyFormData.append(`options[${settingArr[0][0]}]`, settingArr[0][1]);
            });
        }

        if (error !== null) setError(null);
        if (pdfUrl !== null) setPdfUrl(null);

        setReqDisabled(true);

        try {
            const request = {
                method: 'post',
                url: `${window.baseUrl}/util/pdf/generate`,
                headers: {
                    'X-Requested-With': 'XMLHttpRequest'
                },
                data: bodyFormData,
            };
            const response = await axios(request);
            if (response.status === 200 && response.data.success === true) {
                setPdfUrl(response.data.url);
                window.open(response.data.url, '_blank');
            } else {
                setError(response.statusText);
            }
        } catch (e) {
            setError(e);
            console.error(e);
        }

        setReqDisabled(false);
    };

    return (
        <Wrapper>
            <h2 className="m-b-30"> <i style={{ fontSize: '1em' }} className="fas fa-file-pdf" /> Test PDF-Generator </h2>

            { error !== null && (
                <div className="alert alert-danger" role="alert">
                    {error}
                </div>
            ) }
            <Row>
                <Col>
                    {reqDisabled === true && (
                        <ContainerLoader>
                            <LoaderStyled>
                                <Puff
                                    color="#00BFFF"
                                    height="100"
                                    width="100"
                                />
                            </LoaderStyled>
                        </ContainerLoader>
                    ) }

                    <h5> <i className="fas fa-cogs" /> Settings <small>(<a href="https://wkhtmltopdf.org/usage/wkhtmltopdf.txt" rel="noopener noreferrer" target="_blank">Doku</a>)</small></h5>
                    <TextareaForSettings
                        className="card"
                        value={settingsValue.trim()}
                        placeholder={`
                        [
                            {"header-spacing" : "0"},
                            {"margin-top" : "0"},
                            {"margin-left" : "0"},
                            {"margin-right" : "0"},
                            {"margin-bottom" : "0"},
                            {"page-width" : "140mm"},
                            {"page-height" : "70mm"}
                        ]
                        `}
                        onChange={e => setSettingsValue(e.target.value)}
                    />

                    <h5> <i className="fas fa-code" /> HTML </h5>
                    <div className="btn-group">
                        <button title="Lädt Beispiel-Code" className="btn btn-light btn-sm" onClick={() => setHtmlValue(domotexDayTicketHtml)}> Domotex Tagesticket </button>
                        <button title="Lädt Beispiel-Code" className="btn btn-light btn-sm" onClick={() => setHtmlValue(domotexFullTicketHtml)}> Domotex Dauerticket </button>
                        <button title="Lädt Beispiel-Code" className="btn btn-light btn-sm" onClick={() => setHtmlValue(domotexFullEticketHtml)}> Domotex Dauerticket E-Ticket </button>
                    </div>
                    <TextareaForCode
                        className="card"
                        value={htmlValue.trim()}
                        placeholder="<h1> Hier HTML-Code einfügen </h1>"
                        onChange={e => setHtmlValue(e.target.value)}
                    />
                    <div className="clearfix">
                        <button
                            className="btn btn-sm btn-primary"
                            style={{ marginRight: '5px' }}
                            disabled={htmlValue.length < 1 || reqDisabled === true}
                            onClick={() => requestPdf()}
                        >
                            <i className="fas fa-magic" /> PDF generieren
                        </button>

                        { pdfUrl !== null && (
                            <a
                                className="btn btn-sm btn-primary"
                                href={pdfUrl}
                                title={pdfUrl}
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                <i className="fas fa-file-pdf" /> PDF aufrufen
                            </a>
                        ) }
                    </div>
                </Col>

                <Col>
                    <h5> <i className="fas fa-palette" /> Vorschau <small>(entspricht nicht immer 100% dem generierten PDF)</small></h5>
                    <Preview className="card" title="Preview" srcDoc={htmlValue.replace('/* @preview-styles */', previewStyles)} />
                </Col>
            </Row>
        </Wrapper>
    );
};

const hostEle = document.getElementById('js-pdf-test');
if (hostEle != null) {
    const root = createRoot(hostEle);
    root.render(
        <PdfTest />
    );
}