import CookieSidebar from '../js/helper/Cookie';

const $ = window.jQuery;

export class Sidebar {
    constructor() {
        /**
         * Set the CSS class names for further reference
         */
        this.classActive = 'is-active';
        this.classSidebar = '[class^="js-sidebar-level-"]';
        this.classSidebarHeader = '[class^="js-sidebar-level-"]';
        this.classSidebarState = '.js-sidebar-state';
        this.classSidebar1 = '.js-sidebar-level-1';
        this.classSidebar2 = '.js-sidebar-level-2';
        this.classSidebar2Content = '.js-sidebar-level-2-content';
        this.classSidebar2ContentHost = '.js-sidebar-level-2-content-host';
        this.classSidebarNavigationLink = '.js-sidebar-navigation-link';
        this.classSidebarNavigationLinkSub = '.js-sidebar-navigation-link-sub';
    }

    /**
     * throw JS events to let other scripts react
     * @param {String}
     */
    throwEvent(sort) {
        if (sort === 'width') {
            // Time when the CSS-Animation ends.
            setTimeout(() => {
                $('body').trigger('sidebar-width-changed');
            }, 200);
        }
    }

    /**
     * Handle the closed/open state icon
     * @param {Object} - jQueryObject
     */
    handleStateIcon($clickedIcon) {
        const $sidebar = $clickedIcon
            .toggleClass(this.classActive)
            .closest(this.classSidebar)
        ;

        $sidebar.toggleClass(this.classActive);

        /**
         * Specific magic, if its the sidebar 2, in conjunction with the sidebar 2
         * opening and closing magic in 'this.handleNavigationLinkSub'.
         */
        if ($sidebar.is(this.classSidebar2)) {
            $(this.classSidebar1)
                .find(this.classSidebarNavigationLink)
                .removeClass(this.classActive)
            ;
        }

        if ($(this.classSidebar1).hasClass(this.classActive)) {
            CookieSidebar.create('sidebar-1-state', 'open', 180);
        } else {
            CookieSidebar.create('sidebar-1-state', 'closed', 180);
        }

        this.throwEvent('width');
    }

    /**
     * Copy the second sidebars content from the first sidebar
     * structure to the second sidebars body
     * @param {Object} $clickedLink - jQuery Object
     * @returns {string} content - HTML
     */
    getSidebarLevel2Content($clickedLink) {
        const content = $clickedLink
            .next(this.classSidebar2ContentHost)
            .html()
        ;
        return content;
    }

    /**
     * Set the target element to the height of the src element.
     * @param {String} src - CSS Class
     * @param {String} target - CSS Class
     */
    setEqualHeight(src, target) {
        const heightSrc = $(src).outerHeight();
        $(target).css({
            height: heightSrc
        });
    }

    /**
     * Closes the sub sidebar, if a navi link without sub sidebar is clicked.
     * @param {Object} $clickedLink - jQuery Object
     * @param {Object} event - JS Event Object
     */
    handleNavigationLink() {
        $(this.classSidebar2)
            .removeClass(this.classActive)
            .find(this.classSidebarState)
            .removeClass(this.classActive)
        ;

        this.throwEvent('width');
    }

    /**
     * Copy the second sidebars content from the first sidebar
     * structure to the second sidebars body
     * @param {Object} $clickedLink - jQuery Object
     * @param {Object} event - JS Event Object
     */
    handleNavigationLinkSub($clickedLink, event) {
        event.preventDefault();
        const sideBarLevel2Content = this.getSidebarLevel2Content($clickedLink);

        $(this.classSidebar2Content).html(sideBarLevel2Content);

        /**
         * When clicking an navigation link with subnav, close the subnav of this
         * link, when it's already open, or open it when it's currently closed:
         */
        if ($clickedLink.hasClass(this.classActive)) {
            /**
             * Close sidebar 2, like the normal navigation link without subnav:
             */
            $clickedLink.removeClass(this.classActive);
            this.handleNavigationLink();
        } else {
            /**
             * Open sidebar 2:
             */
            $(this.classSidebarNavigationLink).removeClass(this.classActive);
            $clickedLink.addClass(this.classActive);

            $(this.classSidebar2)
                .addClass(this.classActive)
                .find(this.classSidebarState)
                .addClass(this.classActive)
            ;
        }

        /**
         * Set the second header to the hight of the first one, becouse
         * of dynamic event name length and the purpose to align the headline
         * of the second header always on bottom of the header.
         */
        this.setEqualHeight(
            $(this.classSidebar1).find('.js-sidebar-header'),
            $(this.classSidebar2).find('.js-sidebar-header')
        );

        this.throwEvent('width');
    }

    /**
     * Initiation of the whole magic for the sidebar.
     * Methods get attached to clickhandlers, after the dom is fully loaded.
     */
    init() {
        /**
         * To reference methods of this class we use "self" to prevent conflicts
         * with the $(this) object from the event handler.
         */
        const self = this;

        /**
         * Assign method calls to click handlers and other doc ready stuff
         */
        $(document).ready(() => {
            $(self.classSidebarState).on('click', function () {
                self.handleStateIcon($(this));
            });

            $(this.classSidebarNavigationLink).on('click', () => {
                self.handleNavigationLink();
            });

            $(this.classSidebarNavigationLinkSub).on('click', function (event) {
                self.handleNavigationLinkSub($(this), event);
            });
        });
    }
}

/**
 * Export an instance of this class as default for easy use of this es6 module.
 * If needed one is still able to import the class itself as well, since it's "export"
 * flagged to.
 */
export default new Sidebar();
