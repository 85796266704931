import NProgress from 'nprogress';

const $ = window.jQuery;

export class LoadingIndicator {
    init() {
        NProgress.configure({ showSpinner: false });

        /**
         * Page Loading
         */
        NProgress.start();
        $(document).ready(NProgress.done());

        /**
         * Page Changing
         */
        $(window).on('beforeunload', () => {
            NProgress.start();
        });
        $(window).on('unload', () => {
            NProgress.start();
        });

        /**
         * Ajax Requests
         */
        $(document).ajaxStart(NProgress.start);
        $(document).ajaxStop(NProgress.done);
    }
}

export default new LoadingIndicator();
