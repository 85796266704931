/* eslint react/require-default-props: 0 */

/**
 * SINGLE RESULT ITEM COMPONENT OF THE SEARCH UI.
 * Includes also possible actions for rebooking.
 */

/**
 * Import dependencies.
 */
import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import ResultItemHead from './ResultItemSections/ResultItemHead';
import ResultItemMetaInfo from './ResultItemSections/ResultItemMetaInfo';
import ResultItemDetails from './ResultItemSections/ResultItemDetails';
import LoaderAnimation from './../LoaderAnimation/LoaderAnimation';
import AjaxState from './../AjaxState/AjaxState';
import {Animate} from 'react-simple-animate';

const Translator = window.Translator;

class ResultItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            detailsOpen: false,
            rebookAmount: 1,
            showLoader: false,
            success: null
        };

        this.handleRebookCountChange = this.handleRebookCountChange.bind(this);
    }

    /**
     * Show or hide the details section.
     */
    handleToggleDetails() {
        const currentState = this.state.detailsOpen;
        this.setState({ detailsOpen: !currentState });
    }

    /**
     * Sets the changed amount inside the number input.
     * @param {Object} event - event Obj
     */
    handleRebookCountChange(event) {
        let rebookAmount = event.target.value;

        if (rebookAmount > this.props.positionAmount) {
            rebookAmount = this.props.positionAmount;
        } else if (rebookAmount.includes('-')) {
            rebookAmount = 1;
        }

        this.setState({ rebookAmount, success: null });
    }

    /**
     * Perform actions for rebooking. Depending on the action type, it's an ajax request,
     * or a redirect to an formular.
     * @param {string} actionType
     * @param {bool} isRedirect
     * @param {string} actionRequestUrl
     * @param {number} rebookAmount
     */
    handleAction(actionType, isAjax, isRedirect, actionRequestUrl, rebookAmount) {
        const actionQuery = {};
        if (actionType === 'create') {
            actionQuery.amount = rebookAmount;
            actionRequestUrl = `${actionRequestUrl}&amount=${rebookAmount}`;
        }

        this.setState({ showLoader: true, success: null });

        if (isAjax) {
            axios.get(actionRequestUrl, actionQuery)
            // Do after response:
                .then((response) => {
                    const actionResponse = response.data;

                    if (isRedirect === false) {
                        this.setState({ showLoader: false, success: actionResponse.success });
                    }

                    if (isRedirect === true && actionResponse.success === true) {
                        window.location = `${actionResponse.redirectUrl}`;
                    }

                    if (!actionResponse.success && actionResponse.error) {
                        this.setState({
                            responseMessage: actionResponse.error,
                            showLoader: false,
                            success: actionResponse.success
                        });
                    }

                    if (actionResponse.success === true && typeof this.props.rebookActionCallBack === 'function') {
                        this.props.rebookActionCallBack();
                    }

                    // Do after error:
                }).catch((error) => {
                    this.setState({
                        error: true
                    });
                    console.error(`Error with sending action query via ajax to ${actionRequestUrl} `, error);
                    this.setState({ showLoader: false, success: false });
                })
            ;
        } else {
            window.location = actionRequestUrl;
        }
    }

    /**
     * Rendering of the UI for an single search result element.
     */
    render() {
        const metaInfos = [];
        const detailsListItems = [];
        const headlineItems = [];
        let itemIterator = 0;
        let detailsList;

        // We iterate over the data of an search result item and choose layout templates.
        Object.keys(this.props.item.data).map((key) => {
            itemIterator++;

            if (itemIterator <= 3) {
                headlineItems.push(
                    <ResultItemHead key={key} iterator={itemIterator} field={key} value={this.props.item.data[key]} />
                );
            } else if (itemIterator <= 9) {
                metaInfos.push(
                    <ResultItemMetaInfo key={key} field={key} value={this.props.item.data[key]} />
                );
            } else {
                detailsListItems.push(
                    <ResultItemDetails key={key} field={key} value={this.props.item.data[key]} />
                );
            }
        });

        let toggleDetailsIcon = (<i className="fa fa-angle-down" />);
        let toggleDetailsActiveClass = '';
        if (this.state.detailsOpen === true) {
            detailsList = (
                <div className="bg-light ps-1">
                    <ul className="list-group list-group-flush">
                        {detailsListItems}
                    </ul>
                </div>
            );

            toggleDetailsIcon = (<i className="fa fa-angle-up" />);
            toggleDetailsActiveClass = 'active';
        }

        // Button create actions
        let buttonCreate = ' ';
        if (this.props.item.actions.create) {
            let btnDisabled = false;
            if (this.state.showLoader || !this.state.rebookAmount) {
                btnDisabled = true;
            }
            buttonCreate = (
                <div>
                    <input
                        className="form-control h-border-rounded-right-0"
                        type="number"
                        min="1"
                        max={this.props.positionAmount}
                        value={this.state.rebookAmount}
                        onChange={this.handleRebookCountChange}
                        style={{ width: '100px' }}
                    />
                    <button
                        className="form-control btn btn-primary m-r-15 h-border-rounded-left-0"
                        disabled={btnDisabled}
                        onClick={() => this.handleAction('create', this.props.item.actions.create.ajax, this.props.item.actions.create.redirect, this.props.item.actions.create.url, this.state.rebookAmount)}
                    >
                        {Translator.trans('contract.rebook.actions.create')}
                    </button>
                </div>
            );
        }

        // Button rebook actions
        let buttonRebook = ' ';
        if (this.props.item.actions.rebook) {
            buttonRebook = (
                <div>
                    <button
                        className="form-control btn btn-primary m-r-15"
                        disabled={this.state.showLoader}
                        onClick={() => this.handleAction('rebook', this.props.item.actions.rebook.ajax, this.props.item.actions.rebook.redirect, this.props.item.actions.rebook.url)}
                    >
                        {Translator.trans('contract.rebook.actions.rebook')}
                    </button>
                </div>
            );
        }

        // Main component template
        return (
            <li className="card m-b-25">
                <div className="card-header p-t-5 p-b-5">
                    <div className="col">
                        <div className="row">
                            {headlineItems}
                            <div className="col-sm-2 offset-md-3 text-end ps-1">
                                <span className="h5 d-block p-t-10">
                                    <i className="fa fa-tag" /> {Translator.trans(`contract.rebook.search_result.${this.props.item.type}`)}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-body border-top p-t-5 p-b-5">
                    <div className="col">
                        <div className="row">
                            {metaInfos}
                        </div>
                    </div>
                </div>
                <div className="card-footer border-bottom">
                    <div className="row">
                        <div className="col">
                            <div className="form-inline float-end">
                                <div className="input-group">
                                    <button className={`form-control btn btn-primary ${toggleDetailsActiveClass}`} onClick={() => this.handleToggleDetails()}>
                                        {toggleDetailsIcon}
                                    </button>
                                </div>
                            </div>
                            <div className="form-inline">
                                {buttonCreate}
                                {buttonRebook}
                                <LoaderAnimation showLoader={this.state.showLoader} classes=" " />
                                <AjaxState success={this.state.success} responseMessage={this.state.responseMessage} classes=" " />
                            </div>
                        </div>
                    </div>
                </div>
                <Animate
                    startAnimation={this.state.detailsOpen}
                    startStyle={{ opacity: 0 }}
                    endStyle={{ opacity: 1 }}
                >
                    {detailsList}
                </Animate>
            </li>
        );
    }
}

ResultItem.propTypes = {
    item: PropTypes.object.isRequired,
    positionAmount: PropTypes.number.isRequired,
    rebookActionCallBack: PropTypes.func,
};

export default ResultItem;
