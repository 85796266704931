/* eslint-disable*/
/**
 * Import libraries and dependendies to use in this JS class
 */
import CookieInfoArea from '../js/helper/Cookie';
import $ from 'jquery';

export class InfoArea {
    /**
     * JS selectors and texts get assigned to variables.
     */
    constructor(idAccordionParent = '#js-accordion') {
        this.classOpenAllBtn = '.js-open-all-sections';
        this.idAccordionParent = idAccordionParent;
        this.idSectionPrefix = "[id^='js-section-']";
        this.dataSectionPrefix = "[data-target^='#js-section-']";
        this.classShow = 'show';
        this.classCollapsed = 'collapsed';
        this.classActive = 'is-active';
        this.textOpenAll = 'Alle Panels öffnen';
        this.textCloseAll = 'Alle Panels schließen';
        this.infoAreaIsHidden = CookieInfoArea.read('infoarea-hidden') === 'yes' ? true : false;
    }

    /**
     * Method to OPEN all sections
     */
    openAllSections($btn) {
        $(this.idAccordionParent)
            .find(this.idSectionPrefix)
            .addClass(this.classShow)
        ;
        $(this.idAccordionParent)
            .find(this.dataSectionPrefix)
            .removeClass(this.classCollapsed)
        ;
        $btn.addClass(this.classActive);
        $btn.text(this.textCloseAll);
    }

    /**
     * Method to CLOSE all sections
     */
    closeAllSections($btn) {
        $(this.idAccordionParent)
            .find(this.idSectionPrefix)
            .removeClass(this.classShow)
        ;
        $(this.idAccordionParent)
            .find(this.dataSectionPrefix)
            .addClass(this.classCollapsed)
        ;
        $btn.removeClass(this.classActive);
        $btn.text(this.textOpenAll);
    }

    /**
     * Stuff we need to initalize
     */
    init() {
        /**
         *  To prevent conflicts with jQuery Objects 'this.
         */
        const self = this;

        /**
         * Assign methods to event handlers.
         */
        $(document).ready(() => {
            let oddClick = true;

            $(self.classOpenAllBtn).on('click', function (event) {
                event.preventDefault();

                if (oddClick) {
                    self.openAllSections($(this));
                } else {
                    self.closeAllSections($(this));
                }
                oddClick = !oddClick;
            });

            $('.js-infoarea-toggle').on('click', () => {
                this.infoAreaIsHidden = !this.infoAreaIsHidden;

                if (this.infoAreaIsHidden) {
                    CookieInfoArea.create('infoarea-hidden', 'yes', 180);
                    $('.js-infopanel-is-active').fadeOut('100', () => {
                        $('.js-infopanel-is-inactive').removeClass('h-display-none');
                    });
                } else {
                    CookieInfoArea.create('infoarea-hidden', 'no', 180);
                    $('.js-infopanel-is-active').removeClass('h-display-none').fadeIn('100');
                    $('.js-infopanel-is-inactive').addClass('h-display-none');
                }
            });
        });
    }
}

/**
 * Export an instance of this class as default for easy use of this es6 module.
 * If needed one is still able to import the class itself as well, since it's "export"
 * flagged to.
 */
export default new InfoArea('#js-infoarea');
