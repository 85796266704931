/**
 * LOAD INDICATOR
 */

import React from 'react';
import PropTypes from 'prop-types';
import Animation from '../assets/ajax-loader.gif';

const loaderAnimation = (props) => {
    let ret = null;
    if (props.showLoader === true) {
        ret = (<div className={props.classes}> <img className="mx-auto d-block" src={Animation} alt="Loading" /> </div>);
    }
    return ret;
};

loaderAnimation.propTypes = {
    showLoader: PropTypes.bool.isRequired,
    classes: PropTypes.string
};

loaderAnimation.defaultProps = {
    classes: 'row p-4 text-center'
};

export default loaderAnimation;
