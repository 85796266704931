/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import Card from '../Card/Card';
import {
    setLang,
} from '../ActionBar/actionBarReducer';
import { setDescription, setDisplayName } from './displayReducer';
import { useSelector, useDispatch } from 'react-redux';

const CardDisplay = () => {
    const dispatch = useDispatch();
    const [editorState, setEditorState] = React.useState(EditorState.createEmpty());
    const locales = useSelector(state => state.actionBar.locales);
    const lang = useSelector(state => state.actionBar.lang);
    const description = useSelector(state => state.display.description[lang]);
    const displayName = useSelector(state => state.display.displayName[lang]);
    const isPending = useSelector(state => state.shell.isPending);
    const inEditMode = useSelector(state => state.actionBar.inEditMode);
    const errors = useSelector(state => state.shell.errors);

    useEffect(() => {
        if (description && description !== 'null') {
            const contentBlock = htmlToDraft(description);
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            const newEditorState = EditorState.createWithContent(contentState);
            setEditorState(newEditorState);
        }
    }, [lang, isPending]);

    const onEditorStateChange = (newEditorState) => {
        setEditorState(newEditorState);

        const descriptionAsHTML = draftToHtml(convertToRaw(newEditorState.getCurrentContent()));

        dispatch(setDescription({ lang, description: descriptionAsHTML }));
    };

    return (
        <Card label={window.Translator.trans('sub_event.display.display')}>

            <ul className="nav nav-tabs p-b-0">
                {locales.map(locale => (
                    <li key={locale} className="nav-item">
                        <span
                            role="button"
                            tabIndex="0"
                            className={`re-editor-lang-tabs__nav-item h-cursor-pointer ${lang === locale ? 'active' : ''}`}
                            onClick={() => dispatch(setLang(locale))}
                        >
                            {window.Translator.trans(`sub_event.lang.${locale}`)}
                        </span>
                    </li>
                ))}
            </ul>

            <div className="row mb-2">
                <div className="col-4">
                    <span className="fw-bold text-nowrap">
                        {window.Translator.trans('sub_event.display.displayName')}
                    </span>
                </div>
                <div className="col">
                    {inEditMode ? (
                        <input
                            onChange={e => dispatch(setDisplayName({ lang, displayName: e.target.value }))}
                            className="w-100 border border-primary rounded"
                            value={displayName}
                            required
                        />
                    ) : (
                        <span> {displayName} </span>
                    )}

                    {errors && errors.display_name && (
                        <div className="card border border-danger text-danger fw-bold">
                            <div className="card-header text-white bg-danger">
                                <i className="fas fa-exclamation-triangle" />
                                {window.Translator.trans('sub_event.errors.global_default')}
                            </div>
                            <div className="card-body">
                                <ul className="mb-0 pb-0" style={{ paddingLeft: '15px' }}>
                                    {errors.display_name.map(error => (
                                        <li>{ window.Translator.trans(error) }</li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    )}
                </div>
            </div>

            <hr />

            <div>
                <span className="fw-bold text-nowrap">
                    {window.Translator.trans('sub_event.display.description')}
                </span>
                {inEditMode ? (
                    <Editor
                        localization={{
                            locale: 'de',
                        }}
                        stripPastedStyles
                        toolbarClassName="re-toolbar"
                        editorClassName="re-editor"
                        editorState={editorState}
                        toolbar={{
                            options: [
                                'list',
                                'link',
                                'inline',
                            ],
                            inline: {
                                options: ['bold', 'italic'],
                            },
                            list: {
                                options: ['unordered', 'ordered'],
                            },
                        }}
                        onEditorStateChange={onEditorStateChange}
                    />
                ) : (
                    <div dangerouslySetInnerHTML={{ __html: description }} />
                )}

                {errors && errors.description && (
                    <div className="card border border-danger text-danger fw-bold">
                        <div className="card-header text-white bg-danger">
                            <i className="fas fa-exclamation-triangle" />
                            {window.Translator.trans('sub_event.errors.global_default')}
                        </div>
                        <div className="card-body">
                            <ul className="mb-0 pb-0" style={{ paddingLeft: '15px' }}>
                                {errors.description.map(error => (
                                    <li>{ window.Translator.trans(error) }</li>
                                ))}
                            </ul>
                        </div>
                    </div>
                )}
            </div>
        </Card>
    );
};

export default CardDisplay;
