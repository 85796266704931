/* eslint-disable */
import React from 'react';

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};

var defaultFqdnOptions = {
    requireTld: true,
    allowUnderscores: false,
    allowTrailingDot: false,
};
var defaultEmailOptions = {
    allowDisplayName: false,
    requireDisplayName: false,
    allowUtf8LocalPart: true,
    requireTld: true,
};
var displayName = /^[a-z\d!#\$%&'\*\+\-\/=\?\^_`{\|}~\.\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+[a-z\d!#\$%&'\*\+\-\/=\?\^_`{\|}~\,\.\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF\s]*<(.+)>$/i;
var emailUserPart = /^[a-z\d!#\$%&'\*\+\-\/=\?\^_`{\|}~]+$/i;
var quotedEmailUser = /^([\s\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e]|(\\[\x01-\x09\x0b\x0c\x0d-\x7f]))*$/i;
var emailUserUtf8Part = /^[a-z\d!#\$%&'\*\+\-\/=\?\^_`{\|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+$/i;
var quotedEmailUserUtf8 = /^([\s\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|(\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*$/i;
/* eslint-disable prefer-rest-params */
function isByteLength(str, options) {
    var min = 0;
    var max;
    var len = encodeURI(str).split(/%..|./).length - 1;
    if (options) {
        min = Number(options.min) || 0;
        max = Number(options.max);
    }
    return len >= min && (typeof max === 'undefined' || len <= max);
}
function isFQDN(str, options) {
    options = __assign(__assign({}, defaultFqdnOptions), options);
    /* Remove the optional trailing dot before checking validity */
    if (options.allowTrailingDot && str[str.length - 1] === '.') {
        str = str.substring(0, str.length - 1);
    }
    var parts = str.split('.');
    if (options.requireTld) {
        var tld = '' + parts.pop();
        if (!parts.length ||
            !/^([a-z\u00a1-\uffff]{2,}|xn[a-z0-9-]{2,})$/i.test(tld)) {
            return false;
        }
        // disallow spaces
        if (/[\s\u2002-\u200B\u202F\u205F\u3000\uFEFF\uDB40\uDC20]/.test(tld)) {
            return false;
        }
    }
    for (var part = void 0, i = 0; i < parts.length; i++) {
        part = parts[i];
        if (options.allowUnderscores) {
            part = part.replace(/_/g, '');
        }
        if (!/^[a-z\u00a1-\uffff0-9-]+$/i.test(part)) {
            return false;
        }
        // disallow full-width chars
        if (/[\uff01-\uff5e]/.test(part)) {
            return false;
        }
        if (part[0] === '-' || part[part.length - 1] === '-') {
            return false;
        }
    }
    return true;
}
function isEmail(str, options) {
    options = __assign(__assign({}, defaultEmailOptions), options);
    if (options.requireDisplayName || options.allowDisplayName) {
        var displayEmail = str.match(displayName);
        if (displayEmail) {
            str = displayEmail[1];
        }
        else if (options.requireDisplayName) {
            return false;
        }
    }
    var parts = str.split('@');
    var domain = '' + parts.pop();
    var lowerDomain = domain.toLowerCase();
    var user = parts.join('@');
    if (lowerDomain === 'gmail.com' || lowerDomain === 'googlemail.com') {
        user = user.replace(/\./g, '').toLowerCase();
    }
    if (!isByteLength(user, { max: 64 }) || !isByteLength(domain, { max: 254 })) {
        return false;
    }
    if (!isFQDN(domain, { requireTld: options.requireTld })) {
        return false;
    }
    if (user[0] === '"') {
        user = user.slice(1, user.length - 1);
        return options.allowUtf8LocalPart
            ? quotedEmailUserUtf8.test(user)
            : quotedEmailUser.test(user);
    }
    var pattern = options.allowUtf8LocalPart
        ? emailUserUtf8Part
        : emailUserPart;
    var userParts = user.split('.');
    for (var i = 0; i < userParts.length; i++) {
        if (!pattern.test(userParts[i])) {
            return false;
        }
    }
    return true;
}

var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};

var isEmail_1 = isEmail;
function ReactMultiEmail(props) {
    var _this = this;
    var id = props.id, style = props.style, getLabel = props.getLabel, _a = props.className, className = _a === void 0 ? '' : _a, noClass = props.noClass, placeholder = props.placeholder, autoFocus = props.autoFocus, enable = props.enable, onDisabled = props.onDisabled, validateEmail = props.validateEmail, onChange = props.onChange, onChangeInput = props.onChangeInput, onFocus = props.onFocus, onBlur = props.onBlur, onKeyDown = props.onKeyDown, onKeyUp = props.onKeyUp, spinner = props.spinner, _b = props.delimiter, delimiter = _b === void 0 ? '[ ,;]' : _b, _c = props.initialInputValue, initialInputValue = _c === void 0 ? '' : _c, inputClassName = props.inputClassName, autoComplete = props.autoComplete;
    var emailInputRef = React.useRef(null);
    var _d = __read(React.useState(false), 2), focused = _d[0], setFocused = _d[1];
    var _e = __read(React.useState([]), 2), emails = _e[0], setEmails = _e[1];
    var _f = __read(React.useState(initialInputValue), 2), inputValue = _f[0], setInputValue = _f[1];
    var _g = __read(React.useState(false), 2), spinning = _g[0], setSpinning = _g[1];
    var findEmailAddress = React.useCallback(function (value, isEnter) { return __awaiter(_this, void 0, void 0, function () {
        var validEmails, inputValue, re, isEmail, addEmails, splitData, setArr, arr, validateResult, validateResult;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    validEmails = [];
                    inputValue = '';
                    re = new RegExp(delimiter, 'g');
                    isEmail = validateEmail || isEmail_1.isEmail;
                    addEmails = function (email) {
                        for (var i = 0, l = emails.length; i < l; i++) {
                            if (emails[i] === email) {
                                return false;
                            }
                        }
                        validEmails.push(email);
                        return true;
                    };
                    if (!(value !== '')) return [3 /*break*/, 11];
                    if (!re.test(value)) return [3 /*break*/, 6];
                    splitData = value.split(re).filter(function (n) {
                        return n !== '' && n !== undefined && n !== null;
                    });
                    setArr = new Set(splitData);
                    arr = __spreadArray([], __read(setArr), false);
                    _a.label = 1;
                case 1:
                    validateResult = isEmail('' + arr[0]);
                    if (!(typeof validateResult === 'boolean')) return [3 /*break*/, 2];
                    if (validateResult) {
                        addEmails('' + arr.shift());
                    }
                    else {
                        if (arr.length === 1) {
                            inputValue = '' + arr.shift();
                        }
                        else {
                            arr.shift();
                        }
                    }
                    return [3 /*break*/, 4];
                case 2:
                    // handle promise
                    setSpinning(true);
                    return [4 /*yield*/, (validateEmail === null || validateEmail === void 0 ? void 0 : validateEmail(value))];
                case 3:
                    if ((_a.sent()) === true) {
                        addEmails('' + arr.shift());
                        setSpinning(false);
                    }
                    else {
                        if (arr.length === 1) {
                            inputValue = '' + arr.shift();
                        }
                        else {
                            arr.shift();
                        }
                    }
                    _a.label = 4;
                case 4:
                    if (arr.length) return [3 /*break*/, 1];
                    _a.label = 5;
                case 5: return [3 /*break*/, 11];
                case 6:
                    if (enable && !enable({ emailCnt: emails.length })) {
                        onDisabled === null || onDisabled === void 0 ? void 0 : onDisabled();
                        return [2 /*return*/];
                    }
                    if (!isEnter) return [3 /*break*/, 10];
                    validateResult = isEmail(value);
                    if (!(typeof validateResult === 'boolean')) return [3 /*break*/, 7];
                    if (validateResult) {
                        addEmails(value);
                    }
                    else {
                        inputValue = value;
                    }
                    return [3 /*break*/, 9];
                case 7:
                    // handle promise
                    setSpinning(true);
                    return [4 /*yield*/, (validateEmail === null || validateEmail === void 0 ? void 0 : validateEmail(value))];
                case 8:
                    if ((_a.sent()) === true) {
                        addEmails(value);
                        setSpinning(false);
                    }
                    else {
                        inputValue = value;
                    }
                    _a.label = 9;
                case 9: return [3 /*break*/, 11];
                case 10:
                    inputValue = value;
                    _a.label = 11;
                case 11:
                    setEmails(__spreadArray(__spreadArray([], __read(emails), false), __read(validEmails), false));
                    setInputValue(inputValue);
                    if (validEmails.length) {
                        onChange === null || onChange === void 0 ? void 0 : onChange(__spreadArray(__spreadArray([], __read(emails), false), __read(validEmails), false));
                    }
                    if (inputValue !== inputValue) {
                        onChangeInput === null || onChangeInput === void 0 ? void 0 : onChangeInput(inputValue);
                    }
                    return [2 /*return*/];
            }
        });
    }); }, [delimiter, emails, enable, onChange, onChangeInput, onDisabled, validateEmail]);
    var onChangeInputValue = React.useCallback(function (value) { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, findEmailAddress(value)];
                case 1:
                    _a.sent();
                    onChangeInput === null || onChangeInput === void 0 ? void 0 : onChangeInput(value);
                    return [2 /*return*/];
            }
        });
    }); }, [findEmailAddress, onChangeInput]);
    var removeEmail = React.useCallback(function (index, isDisabled) {
        if (isDisabled) {
            return;
        }
        var _emails = __spreadArray(__spreadArray([], __read(emails.slice(0, index)), false), __read(emails.slice(index + 1)), false);
        setEmails(_emails);
        onChange === null || onChange === void 0 ? void 0 : onChange(_emails);
    }, [emails, onChange]);
    var handleOnKeydown = React.useCallback(function (e) {
        onKeyDown === null || onKeyDown === void 0 ? void 0 : onKeyDown(e);
        switch (e.key) {
            case 'Enter':
                e.preventDefault();
                break;
            case 'Backspace':
                if (!e.currentTarget.value) {
                    removeEmail(emails.length - 1, false);
                }
                break;
            default:
        }
    }, [emails.length, onKeyDown, removeEmail]);
    var handleOnKeyup = React.useCallback(function (e) { return __awaiter(_this, void 0, void 0, function () {
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    onKeyUp === null || onKeyUp === void 0 ? void 0 : onKeyUp(e);
                    _a = e.key;
                    switch (_a) {
                        case 'Enter': return [3 /*break*/, 1];
                        case 'Backspace': return [3 /*break*/, 1];
                    }
                    return [3 /*break*/, 3];
                case 1: return [4 /*yield*/, findEmailAddress(e.currentTarget.value, true)];
                case 2:
                    _b.sent();
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); }, [findEmailAddress, onKeyUp]);
    var handleOnChange = React.useCallback(function (e) { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, onChangeInputValue(e.currentTarget.value)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    }); }); }, [onChangeInputValue]);
    var handleOnBlur = React.useCallback(function (e) { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setFocused(false);
                    return [4 /*yield*/, findEmailAddress(e.currentTarget.value, true)];
                case 1:
                    _a.sent();
                    onBlur === null || onBlur === void 0 ? void 0 : onBlur();
                    return [2 /*return*/];
            }
        });
    }); }, [findEmailAddress, onBlur]);
    var handleOnFocus = React.useCallback(function () {
        setFocused(true);
        onFocus === null || onFocus === void 0 ? void 0 : onFocus();
    }, [onFocus]);
    React.useEffect(function () {
        var _a;
        setEmails((_a = props.emails) !== null && _a !== void 0 ? _a : []);
    }, [props.emails]);
    return (React.createElement("div", { className: "".concat(className, " ").concat(noClass ? '' : 'react-multi-email', " ").concat(focused ? 'focused' : '', " ").concat(inputValue === '' && emails.length === 0 ? 'empty' : ''), style: style, onClick: function () { var _a; return (_a = emailInputRef.current) === null || _a === void 0 ? void 0 : _a.focus(); } },
        spinning && (spinner === null || spinner === void 0 ? void 0 : spinner()),
        placeholder ? React.createElement("span", { "data-placeholder": true }, placeholder) : null,
        React.createElement("div", { className: 'data-labels', style: { opacity: spinning ? 0.45 : 1.0, display: 'contents', flexWrap: 'inherit' } }, emails.map(function (email, index) { return getLabel(email, index, removeEmail); })),
        React.createElement("input", { id: id, style: { opacity: spinning ? 0.45 : 1.0 }, ref: emailInputRef, type: 'text', value: inputValue, onFocus: handleOnFocus, onBlur: handleOnBlur, onChange: handleOnChange, onKeyDown: handleOnKeydown, onKeyUp: handleOnKeyup, autoFocus: autoFocus, className: inputClassName, autoComplete: autoComplete })));
}

export default ReactMultiEmail;