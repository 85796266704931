import React from 'react';

import { useSelector, useDispatch } from 'react-redux';
import {
    setParticipationType,
    setDurationType,
} from './planningReducer';

const Type = () => {
    const dispatch = useDispatch();
    const inEditMode = useSelector(state => state.actionBar.inEditMode);
    const participationType = useSelector(state => state.planning.participationType);
    const durationType = useSelector(state => state.planning.durationType);

    return (
        <div className="row mb-3">
            <div className="col-3">
                <span className="fw-bold">
                    {window.Translator.trans('sub_event.planning.type')}
                </span>
            </div>
            {inEditMode ? (
                <React.Fragment>
                    <div className="col-4 pe-0">
                        <select
                            className="me-2 border border-primary rounded d-block w-100"
                            onChange={e => dispatch(setParticipationType(e.target.value))}
                            value={participationType}
                        >
                            <option value="offline"> {window.Translator.trans('sub_event.planning.participation_type.offline')} </option>
                            <option value="online"> {window.Translator.trans('sub_event.planning.participation_type.online')} </option>
                            <option value="hybrid"> {window.Translator.trans('sub_event.planning.participation_type.hybrid')} </option>

                        </select>
                    </div>

                    <div className="col">
                        <select
                            className="me-2 border border-primary rounded d-block w-100"
                            onChange={e => dispatch(setDurationType(e.target.value))}
                            value={durationType}
                        >
                            <option value="day"> {window.Translator.trans('sub_event.planning.duration_type.day')} </option>
                            <option value="multi_day" disabled> {window.Translator.trans('sub_event.planning.duration_type.multi_day')} </option>
                        </select>
                    </div>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <div className="col-4 pe-0">
                        <span>
                            {window.Translator.trans(`sub_event.planning.participation_type.${participationType}`)}
                        </span>
                    </div>

                    <div className="col-5">
                        <span>
                            {window.Translator.trans(`sub_event.planning.duration_type.${durationType}`)}
                        </span>
                    </div>
                </React.Fragment>
            )}
        </div>
    );
};

export default Type;
