/**
 * INDICATOR FOR AJAX REBOOK BUTTON ACTION SUCESS OR FAIL
 */

import React from 'react';
import PropTypes from 'prop-types';

const AjaxState = (props) => {
    let ret = null;

    if (props.success === true) {
        ret = (<div className={props.classes}> <i className="fa fa-check-circle text-success" /> </div>);
    }

    if (props.success === false) {
        ret = (
            <div className={props.classes}>
                <p className="m-b-0 text-danger"> <i className="fa fa-exclamation-circle text-danger" /> {props.responseMessage} </p>
            </div>
        );
    }
    return ret;
};

AjaxState.propTypes = {
    success: PropTypes.bool,
    classes: PropTypes.string,
    responseMessage: PropTypes.string
};

AjaxState.defaultProps = {
    classes: 'row p-4 text-center',
    success: null,
    responseMessage: null
};

export default AjaxState;
