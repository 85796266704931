class createdListForConfigCreator {
    init() {
        const desks = document.getElementById('create_select_buttons_group_config_cashDeskPlace');
        const sets = document.getElementById('create_select_buttons_group_config_cashDeskSets');
        const outputsList = document.getElementById('listPlace');
        const containerForElement = document.createElement('div');
        const button = document.createElement('input');
        const selectedCashDesk = desks.options[desks.selectedIndex];
        const selectedCashDeskSet = sets.options[sets.selectedIndex];
        const nameCashDeskPlace = 'cashDeskPlace[]';
        const nameCashDeskSet = 'cashDeskSets[]';

        this.setIdSelectOption(desks);
        this.setIdSelectOption(sets);
        this.selectDisable(desks, selectedCashDesk);
        this.selectDisable(sets, selectedCashDeskSet);

        containerForElement.innerHTML = `
        <input class='input_list' id = ${selectedCashDesk.id} value = ${selectedCashDesk.value} name = ${nameCashDeskPlace} readonly >
        <input class='input_list' id = ${selectedCashDeskSet.id} value = ${selectedCashDeskSet.value} name = ${nameCashDeskSet} readonly >`;

        button.setAttribute('type', 'button');
        button.setAttribute('value', '<---Delete');

        containerForElement.appendChild(button);
        outputsList.appendChild(containerForElement);

        button.onclick = () => {
            outputsList.removeChild(containerForElement);
            desks.options[selectedCashDesk.id].disabled = false;
            sets.options[selectedCashDeskSet.id].disabled = false;
            desks.options[selectedCashDesk.id].selected = true;
            sets.options[selectedCashDeskSet.id].selected = true;
        };
        return outputsList;
    }
    selectDisable(selectInfo, selectedVar) {
        for (let x = 0; x < selectInfo.length; x++) {
            selectInfo.options[selectedVar.id].disabled = true;
            selectInfo.options[selectedVar.id].selected = false;
        }
    }
    setIdSelectOption(cashDeskInfo) {
        for (let number = 0; number < cashDeskInfo.length; number++) {
            cashDeskInfo.options[number].setAttribute('id', number);
        }
    }
}

export default createdListForConfigCreator;
