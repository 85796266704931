import React from 'react';
import PropTypes from 'prop-types';

const Translator = window.Translator;

const headlineResults = (props) => {
    let ret = '';
    if (props.searchResultsCount > 0 && props.showLoader === false) {
        ret = (
            <h4 className="h4 m-b-25  m-t-25 text-center">
                {props.searchResultsCount} {Translator.trans('contract.rebook.search.search_results')}
            </h4>
        );
    }
    return ret;
};

headlineResults.propTypes = {
    searchInputValue: PropTypes.string,
    searchResultsCount: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]).isRequired,
    showLoader: PropTypes.bool.isRequired
};

headlineResults.defaultProps = {
    searchInputValue: ''
};

export default headlineResults;
