const $ = window.jQuery;
const Routing = window.Routing;
const renderAddressShort = window.renderAddressShort;
const renderAddressDetailed = window.renderAddressDetailed;

class Shipping {
    constructor() {
        this.clientSlug = '';
        this.eventBusinessPartnerId = null;
        this.orderPositionId = null;
        this.contractId = null;
        this.businessPartnerId = null;
        this.businessPartnerContactPersons = [];
        this.businessPartnerReplacementId = null;
        this.businessPartnerReplacementContactPersons = [];
        this.businessPartnerDefaultId = null;
        this.businessPartnerDefaultContactPersons = [];
        this.hasAlternateShippingAdress = false;

        this.$inputClientSlug = $('input.clientSlug');
        this.$inputOrderPositionId = $('input.orderPositionId');
        this.$inputContractId = $('input.contractId');
        this.$inputBusinessPartner = $('input.businessPartnerId');
        this.$inputBusinessPartnerReplacement = $('input.businessPartnerReplacementId');
        this.$inputBusinessPartnerDefault = $('input.businessPartnerDefaultId');
        this.$classShippingContainer = $('.js-order-position-shipping-container');
        this.$inputShippingOnSite = $('input.shippingOnSite');

        this.classReloadEmailChoices = 'js-reload-email-choices';
        this.classButtonShowAlternateAddress = '.js-btn-alt-address';
        this.classButtonShippingOnSite = '.js-btn-shipping-on-site';
        this.classButtonHideAlternateAddress = '.js-close-card';
        this.classAlternateAddressRow = '.js-alternate-address-row';
        this.classShippingContainer = '.js-order-position-shipping-container';
        this.classOrdererSelect = '.js-orderer-select';
        this.classContactPersonSelect = '.js-contact-person-select';
        this.classContactPersonOutput = '.js-contactPersonOutput';
        this.classPrintBusinessPartnerOutput = '.js-printBusinessPartnerOutput';
        this.classDigitalBusinessPartnerOutput = '.js-digitalBusinessPartnerOutput';
        this.classDigitalContactPerson = '.digitalContactPerson';
        this.classDigitalEmailAddressSelect = 'select.digitalEmailAddress';
        this.classDigitalEmailAddressOutput = '.js-digitalEmailAddressOutput';
        this.classPrintAddressSelect = 'select.printAddress';
        this.classPrintAddressOutput = '.js-printAddressOutput';
        this.classPrintContactPerson = 'select.printContactPerson';
        this.classSelect2SelectionRendered = '.select2-selection__rendered';

        this.$selectPerson = {};
        this.itemsRegular = [];
    }

    /**
     * Generates string with objects property values
     *
     * @param {Object} obj - Object that holds the data
     * @param {Array} fields - Fields with values to be included in output string
     *
     * @returns {string}
     */
    objectToString(obj, fields) {
        const stringContents = [];

        fields.forEach((field) => {
            if (field in obj && obj[field] !== null && obj[field].length) {
                stringContents.push(obj[field]);
            }
        });

        return stringContents.join(' ').trim();
    }

    /**
     *
     * @param {JSON} contact -
     *
     * @returns {bool}
     */
    isEventBusinessPartnerContact(contact) {
        if (typeof contact !== 'object') {
            return false;
        }

        return contact.businessPartnerId === this.eventBusinessPartnerId;
    }

    /**
     * Updates the output field for digital email addresses in a shipping
     * container for order positions.
     *
     * @param {Object} affectedSelect - email address select
     */
    updateDigitalEmailAddress(affectedSelect) {
        const $curDigitalEmail = $(affectedSelect).find('option:selected');

        if (!$curDigitalEmail.length) {
            return;
        }

        $(affectedSelect)
            .closest(this.classShippingContainer)
            .find(this.classDigitalEmailAddressOutput)
            .html($curDigitalEmail.text())
        ;
        this.removeTitleAttr();
    }

    /**
     * Updates the output for contact person in a shipping container
     * for order positions.
     *
     * @param {Object} affectedSelect - Contact person select
     */
    updateContactPerson(affectedSelect) {
        const $affectedShippingContainer = $(affectedSelect).closest(this.classShippingContainer);
        const fields = ['salutation', 'firstName', 'lastName'];
        const $curContactPerson = $(affectedSelect).find('option:selected');
        const jsonPerson = $curContactPerson.length === 1 ? $($curContactPerson).html() : '';
        let contactPersonString = '';
        let contactPersonObject = {};

        if ($curContactPerson.length === 1 && jsonPerson.startsWith('{')) {
            try {
                contactPersonObject = $.parseJSON(jsonPerson);
                contactPersonString = this.objectToString(contactPersonObject, fields);
            } catch (e) {
                console.error(e);
            }
        }

        $affectedShippingContainer
            .find(this.classContactPersonOutput)
            .html(contactPersonString)
        ;
        this.removeTitleAttr();

        if ($(affectedSelect).is(this.classDigitalContactPerson) && typeof contactPersonObject.company1 !== 'undefined') {
            $affectedShippingContainer
                .find(this.classDigitalBusinessPartnerOutput)
                .html(`<span> ${contactPersonObject.company1} </span>`)
            ;
            this.removeTitleAttr();
        }
    }

    removeTitleAttr() {
        $(this.classSelect2SelectionRendered).removeAttr('title');
        $(this.classSelect2SelectionRendered).hover((element) => {
            $(element.currentTarget).removeAttr('title');
        });
    }

    /**
     * Updates the output field for print shipping addresses in a shipping
     * container for order positions.
     *
     * @param {Object} affectedSelect - Print address select
     */
    updatePrintAddress(affectedSelect) {
        let companyNameString = '';
        let addressString = '';
        const $curPrintAddress = $(affectedSelect.find('option:selected'));

        if ($curPrintAddress.length !== 1) {
            return;
        }

        try {
            const jsonString = $($curPrintAddress).html();
            if (!jsonString.startsWith('{')) {
                return;
            }
            const selectedPrintAddressObject = $.parseJSON(jsonString);

            addressString = this.adressObjToString([
                ['street'],
                ['addressAdditional1', 'addressAdditional2'],
                ['building'],
                ['postcode', 'city'],
                ['country']
            ], selectedPrintAddressObject);

            companyNameString = this.adressObjToString([
                ['company1'],
                ['company2'],
                ['company3'],
                ['company4'],
            ], selectedPrintAddressObject);
        } catch (e) {
            console.error($($curPrintAddress).html(), e);
        }

        $(affectedSelect)
            .closest(this.classShippingContainer)
            .find(this.classPrintAddressOutput)
            .html(addressString)
        ;

        $(affectedSelect)
            .closest(this.classShippingContainer)
            .find(this.classPrintBusinessPartnerOutput)
            .html(companyNameString)
        ;

        this.removeTitleAttr();
    }

    /**
     * Transforms jsob Object to a formated string for the adress preview
     *
     * @param {Array} fields - field names to scan the json obj for
     * @param {Object} obj - json object with adress data
     */
    adressObjToString(fields, obj) {
        const addressFields = [];
        fields.forEach((field) => {
            const str = this.objectToString(obj, field);
            if (str !== null && str.length) {
                addressFields.push(`<span>${str}</span>`);
            }
        });

        return addressFields.join('<br>');
    }

    /**
     * Loads email address choices for selected contact person in
     * digital shipping container for order positions.
     *
     * @param {Object} affectedSelect - Contact person select
     */
    loadDigitalEmailAddressChoices(affectedSelect) {
        const $curContactPersonId = $(affectedSelect).find('option:selected').val();

        if (isNaN($curContactPersonId) || $curContactPersonId < 1) {
            return;
        }

        const digitalEmailAddressSelect = $(affectedSelect)
            .closest(this.$classShippingContainer)
            .find(this.classDigitalEmailAddressSelect);

        let currentBusinessPartnerId = 0;

        if ($.inArray($curContactPersonId, this.businessPartnerContactPersons) !== -1) {
            currentBusinessPartnerId = this.businessPartnerId;
        } else {
            currentBusinessPartnerId = this.businessPartnerReplacementId;
        }

        if (isNaN(currentBusinessPartnerId) || currentBusinessPartnerId < 1) {
            return;
        }

        $.ajax({
            url: Routing.generate('business_partner_contact_person_list_email_addresses', {
                businessPartner: currentBusinessPartnerId,
                contactPerson: $curContactPersonId,
                orderPositionId: this.orderPositionId,
            }),
            success: (response) => {
                let options = '';

                if (response.data) {
                    const selectedVal = $(digitalEmailAddressSelect).val();
                    $.each(response.data, (key, value) => {
                        const isSelected = selectedVal === key ? 'selected' : '';
                        options += `<option ${isSelected} value="${key}">${value}</option>`;
                    });
                }

                $(digitalEmailAddressSelect).html(options);
                this.updateDigitalEmailAddress(digitalEmailAddressSelect);

                this.removeTitleAttr();
            }
        });
    }

    /**
     * @param {jQuery} $container -
     */
    selectAlternateAddressRow($container) {
        const $alternateAdressRow = $container.closest(this.classShippingContainer).find(this.classAlternateAddressRow);
        $alternateAdressRow.removeClass('h-hidden');
    }

    /**
     * @param {jQuery} $selectPrintAddress -
     */
    selectPrintAddress($selectPrintAddress) {
        this.updatePrintAddress($selectPrintAddress);
    }

    /**
     * @param {jQuery} $selectEmailAddress -
     */
    selectEmailAddress($selectEmailAddress) {
        this.updateDigitalEmailAddress($selectEmailAddress);
    }

    /**
     * @param {jQuery} $selectPrintAddress -
     * @param {string} selectedOption -
     */
    selectPrintContact($selectPrintAddress, selectedOption) {
        // reset current address selection
        $selectPrintAddress.val(null).trigger('change');
        let businessPartnerId = null;
        if (!selectedOption || selectedOption.length === 0) {
            return;
        }

        try {
            const elemData = JSON.parse(selectedOption);
            businessPartnerId = elemData.businessPartnerId;
        } catch (e) {
            console.error(e);
        }

        this.togglePrintAddresses($selectPrintAddress, businessPartnerId);
        this.selectPrintAddress($selectPrintAddress);
        this.removeTitleAttr();
    }

    /**
     * Enables/Disables the address select options based on selected
     * business partner.
     *
     * @param {jQuery} $selectPrintAddress -
     * @param {string} businessPartnerId Id to look address up for
     */
    togglePrintAddresses($selectPrintAddress, businessPartnerId) {
        let hasSelectedOption = false;
        $('option', $selectPrintAddress).each((idx, option) => {
            if (!option.label) {
                return;
            }
            try {
                const elemData = option.label.startsWith('{') ? JSON.parse(option.label) : null;
                const enable = (elemData && businessPartnerId === elemData.businessPartnerId);
                $(option).prop('disabled', !enable);

                if (enable && !hasSelectedOption) {
                    $(option).prop('selected', true);
                    hasSelectedOption = true;
                }
            } catch (e) {
                console.error(e);
            }
        });
    }

    initPrintAddressSelect2($selectPrintAddress) {
        $selectPrintAddress.select2('destroy');
        $selectPrintAddress.select2({
            templateResult: renderAddressDetailed,
            templateSelection: renderAddressShort
        }).on('select2:open', () => {
            setTimeout(() => {
                const $select2 = $selectPrintAddress.data('select2');
                $('[aria-disabled="true"]', $select2.$results).hide();
            }, 30);
        });
    }

    /**
     * @param {jQuery} $container -
     */
    initContactPerson($container) {
        const $reloadEmailChoices = true;
        this.$selectPerson = $container.find(this.classContactPersonSelect);

        if (this.$selectPerson.length !== 1) {
            return;
        }

        this.eventBusinessPartnerId = this.$selectPerson.data('event-businesspartner-id');
        this.updateContactPerson(this.$selectPerson);
        if ($reloadEmailChoices) {
            this.loadDigitalEmailAddressChoices(this.$selectPerson);
        }

        this.$selectPerson.find('option').each((idx, option) => {
            const text = $(option).text();
            if (!text || !text.startsWith('{')) {
                this.itemsRegular.push($(option));
            } else {
                const contact = JSON.parse(text);
                if (!this.isEventBusinessPartnerContact(contact)) {
                    this.itemsRegular.push($(option));
                }
            }
        });

        this.$selectPerson.on('change', () => {
            this.loadDigitalEmailAddressChoices($container.find(this.classContactPersonSelect));
            this.updateContactPerson($container.find(this.classContactPersonSelect));
        });
    }

    /**
     * @param {jQuery} $container -
     */
    initOrderer($container) {
        this.$selectOrderer = $container.parent().parent().parent().find(this.classOrdererSelect, 'select');
        this.$selectPerson = $container.find(this.classContactPersonSelect);
        const $selectEmailAddress = $container.find(this.classDigitalEmailAddressSelect);

        if (this.$selectOrderer.length !== 1) {
            return;
        }

        this.$selectOrderer.on('change', (event) => {
            if (this.hasAlternateShippingAdress) {
                return;
            }

            this.$selectPerson.val(event.target.value).change();
            $selectEmailAddress.val(event.target.value).change();
        });
    }

    /**
     *
     * @param {Object} $container
     *
     * @returns {Object}
     */
    initPrintAddress($container) {
        const $selectPrintAddress = $container.find(this.classPrintAddressSelect);
        if ($selectPrintAddress.length !== 1) {
            return null;
        }

        this.selectPrintAddress($selectPrintAddress);
        $selectPrintAddress.on('change', () => {
            this.selectPrintAddress($selectPrintAddress);
        });
        this.initPrintAddressSelect2($selectPrintAddress);

        return $selectPrintAddress;
    }

    initValues() {
        // detect current client slug
        if (this.$inputClientSlug.length) {
            this.clientSlug = this.$inputClientSlug.val();
        }

        // detect current orderPositionId
        if (this.$inputOrderPositionId.length) {
            const $orderPositionId = parseInt(this.$inputOrderPositionId.val(), 10);
            this.orderPositionId = isNaN($orderPositionId) ? null : $orderPositionId;
        }

        // detect current contractId
        if (this.$inputContractId.length) {
            const $contractId = parseInt(this.$inputContractId.val(), 10);
            this.contractId = isNaN($contractId) ? null : $contractId;
        }

        // collect business partner contact persons
        if (this.$inputBusinessPartner.length) {
            const $input = $(this.$inputBusinessPartner);
            const $businessPartnerId = parseInt($input.data('id'), 10);
            this.businessPartnerId = isNaN($businessPartnerId) ? null : $businessPartnerId;
            this.businessPartnerContactPersons = $input.val().split(',');
        }

        // collect business partner default contact persons
        if (this.$inputBusinessPartnerDefault.length) {
            const $input = $(this.$inputBusinessPartnerDefault);
            const $businessPartnerDefaultId = parseInt($input.data('id'), 10);
            this.businessPartnerDefaultId = isNaN($businessPartnerDefaultId) ? null : $businessPartnerDefaultId;
            this.businessPartnerDefaultContactPersons = $input.val().split(',');
        }

        // collect business partner replacement contact persons
        if (this.$inputBusinessPartnerReplacement.length) {
            const $input = $(this.$inputBusinessPartnerReplacement);
            const $businessPartnerReplacementId = parseInt($input.data('id'), 10);
            this.businessPartnerReplacementId = isNaN($businessPartnerReplacementId) ? null : $businessPartnerReplacementId;
            this.businessPartnerReplacementContactPersons = $input.val().split(',');
        }
        this.removeTitleAttr();
    }

    /**
     * @param {jQuery} $container -
     */
    initContainerEvents($container) {
        // initiate toggle buttons for alternate address rows
        const handleButtonShowClick = (event) => {
            event.preventDefault();
            this.hasAlternateShippingAdress = true;
            // const $selectPrintAddress = $container.find(this.classPrintAddressSelect);
            // this.$selectPerson.find('option').remove();
            // $selectPrintAddress.val(null).trigger('change');
            this.selectAlternateAddressRow($container);
        };

        const handleButtonHideClick = (event) => {
            event.preventDefault();
            this.hasAlternateShippingAdress = false;
            const $this = $(event.currentTarget);
            const $currentShippingContainer = $this.closest(this.$classShippingContainer);
            $currentShippingContainer
                .find(this.classAlternateAddressRow)
                .addClass('h-hidden')
            ;
            $currentShippingContainer
                .find(this.classButtonShowAlternateAddress)
                .removeClass('active')
            ;
            this.$selectPerson.val(this.$selectOrderer.val()).trigger('change');
        };

        $container.find(this.classButtonShowAlternateAddress).off().on('click', (event) => {
            handleButtonShowClick(event, this.itemsRegular);

            return false;
        });

        $container.find(this.classButtonShippingOnSite).off().on('click', (event) => {
            const $this = $(event.currentTarget);
            $this.toggleClass('active');
            this.$inputShippingOnSite.val($this.hasClass('active') ? 1 : 0);

            return false;
        });

        $container.find(this.classButtonHideAlternateAddress).off().on('click', (event) => {
            handleButtonHideClick(event);

            return false;
        });

        // initiate contact person selection
        this.initContactPerson($container);

        // initiate orderer selection
        this.initOrderer($container);

        // initiate digital email address selection
        const $selectEmailAddress = $container.find(this.classDigitalEmailAddressSelect);
        if ($selectEmailAddress.length === 1) {
            this.selectEmailAddress($selectEmailAddress);
            $selectEmailAddress.on('change', () => {
                this.selectEmailAddress($selectEmailAddress);
            });
        }

        // initiate print address selection
        const $selectPrintAddress = this.initPrintAddress($container);
        const $selectPrintContact = $container.find(this.classPrintContactPerson);
        if ($selectPrintContact.length === 1 && $selectPrintAddress.length === 1) {
            this.selectPrintAddress($selectPrintAddress);
            $selectPrintContact.on('change', (event) => {
                const selectedOption = $(event.currentTarget).select2('data');
                this.selectPrintContact($selectPrintAddress, selectedOption[0].text);
                this.initPrintAddressSelect2($selectPrintAddress);
            });
        }
    }

    /**
     * Initialises all fields and values.
     * One must only call this to init everything
     */
    init() {
        $(document).ready(() => {
            if (this.$classShippingContainer.length) {
                $(this.$classShippingContainer.each((idx, elem) => {
                    const shippingInstance = new Shipping();
                    shippingInstance.initValues();
                    shippingInstance.initContainerEvents($(elem));
                }));

                this.removeTitleAttr();
            }
        });
    }
}

export default new Shipping();
