import $ from 'jquery';

const copyBtnTxt = window.Translator.trans('generic.button.copy_value');

$(document).ready(() => {
    const $copyFields = $('.js-copy-value-button');

    $copyFields.each(function () {
        const copyFieldId = $(this).attr('id');
        const $field = $(this);
        const fieldCopyVal = $field.data('copyvalue');
        if (fieldCopyVal) {
            $field
                .parent()
                .removeClass('col-sm-10')
                .addClass('col p-r-0')
                .parent()
                .append(`
                    <div class="col-sm-3 p-l-0">
                        <span class="js-copy-btn btn btn-small btn-primary w-100" data-targetid="${copyFieldId}">
                            <i class="fas fa-angle-left"></i> ${copyBtnTxt}
                        </span>
                    </div>
                `)
            ;
        }
    });

    $(document).on('click', '.js-copy-btn', function () {
        const targetId = $(this).data('targetid');
        const $target = $(`#${targetId}`);
        const copyVal = $target.data('copyvalue');
        $target.val(copyVal);
    });
});