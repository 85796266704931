export const loaderWrapper = {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    zIndex: 50,
    background: 'rgb(255 255 255 / 75%)',
    backdropFilter: 'blur(3px) grayscale(100%)',
};

export const loader = {
    position: 'absolute',
    top: '49%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
};
