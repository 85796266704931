import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    status: 'draft',
    displayMode: 3,
    isAliasVisible: false,
    deleteConfirmOpen: false,
    inEditMode: false,
    lang: 'de',
    locales: ['de']
};

export const actionBarReducer = createSlice({
    name: 'actionBar',
    initialState,
    reducers: {
        setStatus: (state, action) => {
            state.status = action.payload;
        },
        setDisplayMode: (state, action) => {
            state.displayMode = action.payload;
            state.isAliasVisible = state.displayMode === 1 || state.displayMode === 4;
        },
        setDeleteConfirmOpen: (state, action) => {
            state.deleteConfirmOpen = action.payload;
        },
        setInEditMode: (state, action) => {
            state.inEditMode = action.payload;
        },
        setLang: (state, action) => {
            state.lang = action.payload;
        },
        setLocales: (state, action) => {
            state.locales = action.payload;
        },
    },
});

export const {
    setStatus,
    setDisplayMode,
    setDeleteConfirmOpen,
    setInEditMode,
    setLang,
    setLocales,
} = actionBarReducer.actions;

export default actionBarReducer.reducer;
