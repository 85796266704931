/* eslint-disable */

import $ from 'jquery';

const confirmModal = window.confirmModal;
const Translator = window.Translator;

export class EventBundleConfirmationButtons {
    constructor() {
        this.recalcualteFreeQuotaFrom = $('form#js-recalculate-free-quota');
    }

    init() {
        $(document).ready(() => {
            this.freeQuotaRecalculationCheckAvailability();
            this.freeQuotaRecalculationConfirmation();
        });
    }

    freeQuotaRecalculationConfirmation() {
        this.recalcualteFreeQuotaFrom.on('submit', function (event) {
            event.preventDefault();
            const form = $(this);

            confirmModal(
                Translator.trans('event.quota_calculation.recalculate.modal.title.free'),
                Translator.trans('generic.button.yes'),
                Translator.trans('generic.button.cancel'),
                {
                    acceptFunction: (function () {
                        form.unbind('submit').submit();
                        form.find('button').prop('disabled', true);
                    })
                }
            );

            return false;
        });
    }

    freeQuotaRecalculationCheckAvailability() {
        const disabled =  'true' === this.recalcualteFreeQuotaFrom.find('input[name="isLocked"]').val();
        const button = this.recalcualteFreeQuotaFrom.find('button').prop('disabled', true);

        if (disabled) {
            button.prop('disabled', true);
            button.attr('data-bs-toggle', 'tooltip');
            button.attr('data-bs-placement', 'top');
            button.attr('title', Translator.trans('event.quota_calculation.recalculate.tooltip'));
        } else {
            button.prop('disabled', false);
        }
    }
}
export default new EventBundleConfirmationButtons();
