import { configureStore } from '@reduxjs/toolkit';
import shellReducer from '../components/SubEventDetailsShell/shellReducer';
import coreDataReducer from './../components/CardCoreData/coreDataReducer';
import actionBarReducer from './../components/ActionBar/actionBarReducer';
import planningReducer from './../components/CardPlanning/planningReducer';
import displayReducer from './../components/CardDisplay/displayReducer';
import productVariantsReducer from './../components/CardProductVariants/productVariantsReducer';

const store = configureStore({
    reducer: {
        shell: shellReducer,
        coreData: coreDataReducer,
        actionBar: actionBarReducer,
        planning: planningReducer,
        display: displayReducer,
        productVariants: productVariantsReducer,
    },
});

export default store;
