/**
 * Import libraries and dependendies to use in this JS class
 */
import $ from 'jquery';

export class CodePool {
    constructor() {
        /**
         * Set the CSS class names for further reference
         */
        this.classCodepoolSelect = '.js-codepool-select';
        this.classCodepoolPlugin = '.js-codepool-plugin';
        this.classVariantQuota = '.js-variant-quota';
    }

    /**
     * Check are classes for code pool available
     */
    init() {
        $(document).ready(() => {
            // check if our js-classes exist in dom. otherwise exit.
            if ($(this.classCodepoolSelect).length > 0) {
                this.togglePlugin($(this.classCodepoolSelect).val());
                this.eventHandling();
            }
        });
    }

    /**
     * Function for eventhandling
     */
    eventHandling() {
        $(this.classCodepoolSelect).on('change', (event) => {
            this.togglePlugin(event.target.value);
        });
    }

    /**
     * show/hide the codepoolplugin, set/remove required attributes
     * @param {String} pool - Value/CSS Class
     */
    togglePlugin(pool) {
        $(this.classCodepoolPlugin).each((CodepoolIndex, CodepoolElement) => {
            if (pool === '-1' || !$(CodepoolElement).hasClass(pool)) {
                $(CodepoolElement).hide();
                $('input:required, select:required', CodepoolElement).each((index, element) => {
                    $(element).setAttr('data-required', 1).removeAttr('required');
                });
            } else {
                $(CodepoolElement).show();
                $('input[data-required="1"], select[data-required="1"]', CodepoolElement).attr('required', 'required');
            }
        });

        if ($(this.classVariantQuota).length > 0) {
            if (pool === '1') { // CodePool === CSV
                $(this.classVariantQuota).attr('disabled', 'disabled');
            } else {
                $(this.classVariantQuota).removeAttr('disabled');
            }
        }
    }
}

/**
 * Export an instance of this class as default for easy use of this es6 module.
 * If needed one is still able to import the class itself as well, since it's "export"
 * flagged to.
 */
export default new CodePool();
