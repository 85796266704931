import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Card from '../Card/Card';
import { setName } from './coreDataReducer';

const CardCoreData = () => {
    const dispatch = useDispatch();
    const name = useSelector(state => state.coreData.name);
    const subEventId = useSelector(state => state.coreData.subEventId);
    const inEditMode = useSelector(state => state.actionBar.inEditMode);
    const errors = useSelector(state => state.shell.errors);

    return (
        <Card label={window.Translator.trans('sub_event.core_data.core_data')}>
            <div className="row mb-2">
                <div className="col-4">
                    <span className="fw-bold text-nowrap">
                        {window.Translator.trans('sub_event.core_data.name')}
                    </span>
                </div>
                <div className="col">
                    {inEditMode ? (
                        <input
                            onChange={e => dispatch(setName(e.target.value))}
                            className="w-100 border border-primary rounded"
                            value={name}
                            required
                        />
                    ) : (
                        <span>{ name }</span>
                    )}

                    {errors && errors.name && (
                        <div className="card border border-danger text-danger fw-bold">
                            <div className="card-header text-white bg-danger">
                                <i className="fas fa-exclamation-triangle" />
                                {window.Translator.trans('sub_event.errors.global_default')}
                            </div>
                            <div className="card-body">
                                <ul className="mb-0 pb-0" style={{ paddingLeft: '15px' }}>
                                    {errors.name.map(error => (
                                        <li>{ window.Translator.trans(error) }</li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    )}
                </div>
            </div>

            {subEventId && <div className="row">
                <div className="col-4">
                    <span className="fw-bold text-nowrap">
                        {window.Translator.trans('sub_event.core_data.id')}
                    </span>
                </div>
                <div className="col">
                    {subEventId}
                </div>
            </div>}
        </Card>
    );
};

export default CardCoreData;
