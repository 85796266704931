import React from 'react';
import PropTypes from 'prop-types';

const Translator = window.Translator || { trans: _ => _ };

const statusInfo = (props) => {
    let ret = '';
    if (props.searchResultsAvailable === false) {
        ret = (<div className="alert alert-warning border" role="alert">
            <p className="m-b-0"> <i className="fa fa-exclamation-triangle" /> {Translator.trans('contract.rebook.search_infos.no_results')} </p>
        </div>);
    }
    if (props.searchInputValue === '') {
        ret = (<div className="alert alert-info border" role="alert">
            <p className="m-b-0"> <i className="fa fa-info-circle" /> {Translator.trans('contract.rebook.search_infos.no_input')}  </p>
        </div>);
    }
    return ret;
};

statusInfo.propTypes = {
    searchResultsCount: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]).isRequired
};

export default statusInfo;
