import $ from 'jquery';
import 'jQuery-QueryBuilder/dist/js/query-builder';
import translationDe from './querybuilder-translations-de';

export class QueryBuilder {
    constructor() {
        // Dom References
        this.$builderHost = null;
        this.$builderTextArea = null;
        this.$builderLoader = null;
        this.$builderError = null;

        // Config General
        $.fn.queryBuilder.regional.de = translationDe;
        this.queryBuilderConfig = {
            lang_code: 'de',
        };

        // Config from data-attr's
        this.formConfig = {};
    }

    initQueryBuilder() {
        try {
            this.formConfig = JSON.parse(this.$builderTextArea.val());
            const config = {
                ...this.queryBuilderConfig,
                filters: this.formConfig.filters,
                rules: this.formConfig.rules
            };
            this.$builderHost.queryBuilder(config);
            this.$builderLoader.addClass('d-none');
        } catch (e) {
            this.$builderError.removeClass('d-none');
            this.$builderLoader.addClass('d-none');
            console.error(e);
        }
    }

    outputToTextArea() {
        const queryBuilderOutput = {
            filters: this.formConfig.filters,
            rules: this.$builderHost.queryBuilder('getRules'),
        };

        if (!$.isEmptyObject(queryBuilderOutput)) {
            $(this.$builderTextArea).val(JSON.stringify(queryBuilderOutput));
        }
    }

    /**
     * Initiation of the whole magic for the sidebar.
     * Methods get attached to clickhandlers, after the dom is fully loaded.
     */
    init() {
        $(document).ready(() => {
            this.$builderHost = $('.js-querybuilder').find('.js-querybuilder_host');
            this.$builderTextArea = $('.js-querybuilder').find('.js-querybuilder_textarea').find('textarea');
            this.$builderLoader = $('.js-querybuilder').find('.js-querybuilder_loader');
            this.$builderError = $('.js-querybuilder').find('.js-querybuilder_error');
            if (this.$builderHost.length > 0 && this.$builderTextArea.length > 0) {
                this.initQueryBuilder();
                this.$builderHost.on('rulesChanged.queryBuilder', () => {
                    this.outputToTextArea();
                });
            }
        });
    }
}

/**
 * Export an instance of this class as default for easy use of this es6 module.
 * If needed one is still able to import the class itself as well, since it's "export"
 * flagged to.
 */
export default new QueryBuilder();
