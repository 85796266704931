/* eslint-disable consistent-return */
/* jshint -W079 */

import dataTablesMainInit, { renderLink } from '../../../../../LayoutBundle/js/dataTablesMainInit';
import {
    rendererBusinessPartner,
    renderTags,
    renderOrderPosition,
    renderContract,
    renderOrder,
    renderYesNoChoiceSymbol,
    renderFreeAmounts,
    renderStatus,
    renderCheckbox,
    renderProductVariant,
    renderProductVariantProductNumber
} from '../../dToutputRenderer';

const $ = window.jQuery;

const Translator = typeof window.Translator !== 'undefined' ? window.Translator : { trans: key => key };
const trans = rootKey => key => Translator.trans(`${rootKey}.${key}`, 'datatable');
const t = trans('order_position');

$(document).ready(() => {
    const dTsettings = {};
    dTsettings.tableName = 'contract_order_management_order_position_datatable';
    dTsettings.selector = `#${dTsettings.tableName}`;

    if ($(dTsettings.selector).length === 0) return false;

    dTsettings.options = {
        order: [[3, 'desc']],
        orderCellsTop: true,
    };

    dTsettings.extensions = {
        colReorder: {
            fixedColumnsLeft: 1 /* = checkbox col. not reorderable */
        },
    };

    dTsettings.columns = {
        columns: [
            {
                title: '<i class="m-l-5 far fa-square"></i>',
                searchable: false,
                orderable: false,
                data: 'position.id',
                name: 'multi_action_checkbox',
                render: renderCheckbox,
            },
            {
                title: t('title.event'),
                searchable: true,
                orderable: true,
                data: 'event',
                name: 'event',
                render: renderLink,
            },
            {
                title: t('title.tags'),
                searchable: true,
                orderable: false,
                data: 'tags',
                name: 'tags',
                render: renderTags,
            },
            {
                title: t('title.position'),
                searchable: true,
                orderable: true,
                data: 'position',
                name: 'order_position_identifier',
                render: renderOrderPosition,
            },
            {
                title: t('title.status'),
                searchable: true,
                orderable: false,
                data: 'status',
                name: 'status',
                render: renderStatus,
            },
            {
                title: t('title.cancellation_status'),
                searchable: true,
                orderable: false,
                data: 'cancellation_status',
                name: 'cancellation_status',
            },
            {
                title: t('title.distribution_channel'),
                searchable: true,
                orderable: false,
                data: 'distribution_channel',
                name: 'distribution_channel',
            },
            {
                title: t('title.product_variant'),
                searchable: true,
                orderable: true,
                data: 'product_variant',
                name: 'product_variant.name',
                render: renderProductVariant,
            },
            {
                title: t('title.product_variant_product_number'),
                searchable: true,
                orderable: true,
                data: 'product_variant_product_number',
                name: 'product_variant.product_no',
                render: renderProductVariantProductNumber,
            },
            {
                title: t('title.amount'),
                searchable: true,
                orderable: true,
                data: 'amount',
                name: 'amount',
            },
            {
                title: t('title.free_amounts'),
                searchable: true,
                orderable: true,
                data: 'free_amounts',
                name: 'free_amounts',
                render: renderFreeAmounts,
            },
            {
                title: t('title.priority'),
                searchable: true,
                orderable: false,
                data: 'priority',
                name: 'priority',
            },
            {
                title: t('title.rebooking_position_short'),
                tooltip: t('title.rebooking_position'),
                searchable: true,
                orderable: false,
                data: 'rebooking_position',
                name: 'rebooking_position',
                render: renderYesNoChoiceSymbol,
            },
            {
                title: t('title.rebooking_type_short'),
                tooltip: t('title.rebooking_type'),
                searchable: true,
                orderable: false,
                data: 'rebooking_type',
                name: 'rebooking_type',
            },
            {
                title: t('title.business_partner'),
                searchable: true,
                orderable: true,
                data: 'business_partner',
                name: 'business_partner',
                render: rendererBusinessPartner,
            },
            {
                title: t('title.contract'),
                searchable: true,
                orderable: true,
                data: 'contract',
                name: 'contract',
                render: renderContract,
            },
            {
                title: t('title.order'),
                searchable: true,
                orderable: true,
                data: 'order',
                name: 'order',
                render: renderOrder,
            },
            {
                title: t('title.shipping_type'),
                searchable: true,
                orderable: false,
                data: 'shipping_type',
                name: 'shipping_type',
            },
        ]
    };

    dTsettings.multiActions = [
        // @todo P2-7015
        // {
        //     actionIcon: 'fas fa-lock',
        //     actionTitle: 'contract.order_positions.table.actions.lock_order_positions',
        //     actionValidateUrl: 'contract_bulk_lock_order_positions_validate',
        //     actionProceedUrl: 'contract_bulk_lock_order_positions_proceed',
        // },
        {
            actionIcon: 'fas fa-lock-open',
            actionTitle: 'contract.order_positions.table.actions.unlock_order_positions',
            actionValidateUrl: 'contract_bulk_unlock_order_positions_validate',
            actionProceedUrl: 'contract_bulk_unlock_order_positions_proceed',
        },
        {
            actionIcon: 'fas fa-ban',
            actionTitle: 'contract.order_positions.table.actions.cancel_order_positions',
            actionValidateUrl: 'contract_bulk_cancel_order_positions_validate',
            actionProceedUrl: 'contract_bulk_cancel_order_positions_proceed',
        },
        {
            actionIcon: 'fas fa-forward',
            actionTitle: 'contract.order_positions.table.actions.cancel_accept_order_positions',
            actionValidateUrl: 'contract_bulk_cancel_accept_order_positions_validate',
            actionProceedUrl: 'contract_bulk_cancel_accept_order_positions_proceed',
        },
        {
            actionIcon: 'fas fa-forward',
            actionTitle: 'contract.order_positions.table.actions.cancel_decline_order_positions',
            actionValidateUrl: 'contract_bulk_cancel_decline_order_positions_validate',
            actionProceedUrl: 'contract_bulk_cancel_decline_order_positions_proceed',
        },
        {
            actionIcon: 'fas fa-user-edit',
            actionTitle: 'contract.order_positions.table.actions.enable_editing',
            actionValidateUrl: 'contract_bulk_enable_editing_order_positions_validate',
            actionProceedUrl: 'contract_bulk_enable_editing_order_positions_proceed',
        },
        {
            actionIcon: 'fas fa-forward',
            actionTitle: 'contract.order_positions.table.actions.production_release',
            actionValidateUrl: 'contract_bulk_production_release_validate',
            actionProceedUrl: 'contract_bulk_production_release',
            actionType: 'contract_bulk_production_release',
        }
    ];

    dataTablesMainInit(dTsettings);
});
