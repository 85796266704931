const $ = window.jQuery;

/**
 * Simply disabling the buttons will break the back button, as the value of the button won't be submitted.
 * So we fake disabling using a bootstrap class for the disabled style and prevent further clicks with an event listener
 */
$(document).ready(() => {
    $('form[name="createOrderPositionStep2"], form[name="createOrderStep4"]').on('submit', (e) => {
        const $submitBtn = $(e.target).find('button[type="submit"]');
        $submitBtn.addClass('disabled');
        $submitBtn.on('click', (f) => {
            $(f).preventDefault();
            return false;
        })
    });
});
