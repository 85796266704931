import React, { useState, useRef, useEffect } from 'react';
import CodeMirror from '@uiw/react-codemirror';
import { html as htmlLang } from "@codemirror/lang-html";
import { EditorView } from "@codemirror/view";

const styleWrapper = {
    background: '#F6F8F9',
    padding: '5px',
    borderTadius: '5px',
    borderColor: 'rgba(0, 0, 0, 0.125)',
};

const CodeHighlightingTextarea = (props)=> {
    const [editorValue, setEditorValue] = useState('');
    const [labelValue, setLabelValue] = useState(null);
    const [editorIsExpanded, setEditorIsExpanded] = useState(false);

    const editorRef = useRef(null);

    useEffect(()=> {
        setEditorValue(props.textareaEle.value);
    }, []);

    useEffect(()=> {
        props.textareaEle.value = editorValue;
    }, [editorValue]);

    const editorOnChange = React.useCallback((val, viewUpdate) => {
        setEditorValue(val);
        props.textareaEle.value = val;
      }, []);
    
    return (
        <div style={styleWrapper}>
            
            <CodeMirror 
                value={editorValue} 
                maxHeight={editorIsExpanded ? 'auto' : '400px'} 
                minHeight='200px' 
                ref={editorRef} 
                extensions={[
                    htmlLang(), 
                    EditorView.lineWrapping
                ]}
                onChange={editorOnChange}
                /* HTML placeholder like attribute, NOT the placeholders this component is about! ;) */
                placeholder={labelValue}
            />
                
            <div className="clearfix">
                <span role="button" 
                    tabIndex={-2}
                    onClick={() => setEditorIsExpanded(!editorIsExpanded)} 
                    className="btn btn-outline-secondary btn-sm float-end">
                        <i className={`fas ${editorIsExpanded ? 'fa-minimize' : 'fa-maximize'}`} />
                </span>
            </div>

        </div>
    );
}

export default CodeHighlightingTextarea;
