/* eslint-disable prefer-arrow-callback */
const $ = window.jQuery;

const Translator = typeof window.Translator !== 'undefined' ? window.Translator : { trans: key => key };

export function rendererContactPerson(contactPerson, type) {
    if (type !== 'display' || contactPerson === null) {
        return contactPerson;
    }

    return contactPerson.name;
}

export function rendererBusinessPartner(businessPartner, type) {
    if (type !== 'display' || businessPartner === null) {
        return businessPartner;
    }

    let html = `<a href="${businessPartner.link}">${businessPartner.name}</a>`;

    if (businessPartner.name2 !== '') {
        html += `<br>${businessPartner.name2}`;
    }
    if (businessPartner.name3 !== '') {
        html += `<br>${businessPartner.name3}`;
    }
    if (businessPartner.name4 !== '') {
        html += `<br>${businessPartner.name4}`;
    }

    return html;
}

export function renderTags(tags, type) {
    if (type !== 'display') {
        return tags;
    }

    let html = '';

    $.each(tags, (index, tag) => {
        html = `${html}<div class="tag" style="background-color: ${tag.color}; text-align: center; font-weight: bold; font-size: 0.75em; border-radius: 5px;">${tag.name}</div>`;
    });

    return html;
}

export function renderProductVariant(productVariant, type) {
    if (type !== 'display' || productVariant === null) {
        return productVariant;
    }

    return `<a href="${productVariant.link}">${productVariant.name}</a>`;
}

export function renderProductVariantProductNumber(productVariantProductNumber, type) {
    if (type !== 'display' || productVariantProductNumber === null) {
        return productVariantProductNumber;
    }

    return `${productVariantProductNumber}`;
}

export function renderButtons(actionLinks, type) {
    if (type !== 'display') {
        return actionLinks;
    }

    const btnGroup = $('<div class="btn-group" role="group"/>');

    if (actionLinks.show !== undefined && actionLinks.show !== null) {
        $('<a class="btn btn-secondary" role="button"/>')
            .attr('href', actionLinks.show)
            .attr('title', Translator.trans('action_link.show', 'datatable'))
            .append('<span class="far fa-eye"></span>')
            .appendTo(btnGroup)
        ;
    }

    if (actionLinks.edit !== undefined && actionLinks.edit !== null) {
        $('<a class="btn btn-secondary" role="button"/>')
            .attr('href', actionLinks.edit)
            .attr('title', Translator.trans('action_link.edit', 'datatable'))
            .append('<span class="fas fa-pencil-alt"></span>')
            .appendTo(btnGroup)
        ;
    }

    if (actionLinks.add !== undefined && actionLinks.add !== null) {
        $('<a class="btn btn-secondary" role="button"/>')
            .attr('href', actionLinks.add)
            .attr('title', Translator.trans('action_link.add', 'datatable'))
            .append('<span class="fas fa-plus"></span>')
            .appendTo(btnGroup)
        ;
    }

    if (actionLinks.delete !== undefined && actionLinks.delete !== null) {
        $('<a class="btn btn-danger actionConfirm" role="button"/>')
            .attr('href', actionLinks.delete)
            .attr('title', Translator.trans('action_link.delete', 'datatable'))
            .append('<span class="fas fa-trash"></span>')
            .appendTo(btnGroup)
        ;
    }

    if (actionLinks.cancel !== undefined && actionLinks.cancel !== null) {
        $('<a class="btn btn-secondary" role="button"/>')
            .attr('href', actionLinks.cancel)
            .attr('title', Translator.trans('action_link.cancel', 'datatable'))
            .append('<span class="fas fa-ban"></span>')
            .appendTo(btnGroup)
        ;
    }

    if (actionLinks.rebook_source !== undefined && actionLinks.rebook_source !== null) {
        $('<a class="btn btn-secondary" role="button"/>')
            .attr('href', actionLinks.rebook_source)
            .attr('title', Translator.trans('action_link.rebook_in_target', 'datatable'))
            .append('<span class="fas fa-sign-in-alt"></span>')
            .appendTo(btnGroup)
        ;
    }

    if (actionLinks.rebook_target !== undefined && actionLinks.rebook_target !== null) {
        $('<a class="btn btn-secondary" role="button"/>')
            .attr('href', actionLinks.rebook_target)
            .attr('title', Translator.trans('action_link.rebook_from_source', 'datatable'))
            .append('<span class="fas fa-sign-out-alt"></span>')
            .appendTo(btnGroup)
        ;
    }

    return btnGroup.prop('outerHTML');
}

export function renderYesNoChoice(value, type) {
    if (type !== 'display' || value === null) {
        return value;
    }

    return value === true ? Translator.trans('filter.yes', 'datatable') : Translator.trans('filter.no', 'datatable');
}

export function renderYesNoChoiceSymbol(value, type) {
    if (type !== 'display' || value === null) {
        return value;
    }

    return value === true ? '<i class="fas fa-check"></i>' : '<i class="fas fa-times"></i>';
}

export function renderPrint(print, type) {
    if (type !== 'display' || print === null) {
        return print;
    }

    return (print === true ? '<i class="fas fa-print"></i> ' : '<i class="fas fa-envelope"></i> ') + renderYesNoChoice(print, type);
}

export function renderTicket(ticketId, type) {
    if (type !== 'display' || ticketId === null) {
        return ticketId;
    }

    const urlParams = new URLSearchParams(window.location.search);
    const cid = urlParams.get('_cid');
    const eid = urlParams.get('_eid');

    const url = window.Routing.generate('transaction_entry_show', {
        id: ticketId,
        _cid: cid,
        _eid: eid
    });

    return `<a href="${url}">${ticketId}</a>`;
}

export function renderOrderPosition(orderPosition, type) {
    if (type !== 'display' || orderPosition === null) {
        return orderPosition;
    }

    let html = `<a href="${orderPosition.link}">${orderPosition.id} (miofair)</a>`;

    if (orderPosition.external_source) {
        html += `<br>${orderPosition.source_id} (${orderPosition.source})`;
    }

    return html;
}

export function renderContract(contract, type) {
    if (type !== 'display' || contract === null) {
        return contract;
    }

    let html = `<a href="${contract.link}">${contract.id} (miofair)</a>`;

    if (contract.external_source) {
        html += `<br>${contract.source_id} (${contract.source})`;
    }

    return html;
}

export function renderOrder(order, type) {
    if (type !== 'display' || order === null) {
        return order;
    }

    let html = `<a href="${order.link}">${order.id} (miofair)</a>`;

    if (order.external_source) {
        html += `<br>${order.source_id} (${order.source})`;
    }

    return html;
}

export function renderTicketImprints(ticketImprints, type) {
    if (type !== 'display' || ticketImprints === null) {
        return ticketImprints;
    }

    return ticketImprints.join('<br>');
}

export function renderStatus(status, type) {
    if (type !== 'display' || status === null) {
        return status;
    }

    let statusIcon = 'square';

    if (status.identifier === 'order_position.status.invoice_approved') {
        statusIcon = 'receipt';
    }
    if (status.identifier === 'order_position.status.invoice_forwarded') {
        statusIcon = 'file-invoice-dollar';
    }
    if (status.identifier === 'order_position.cancellation_status.requested' || status.identifier === 'order_position.cancellation_status.confirmed' || status.identifier === 'order_position.cancellation_status.declined') {
        statusIcon = 'ban';
    }
    if (status.identifier === 'order_position.status.editable') {
        statusIcon = 'pen-square';
    }
    if (status.identifier === 'order_position.status.ready') {
        statusIcon = 'play';
    }
    if (status.identifier === 'order_position.status.in_production') {
        statusIcon = 'angle-double-right';
    }
    if (status.identifier === 'order_position.status.complete') {
        statusIcon = 'check-square';
    }
    if (status.identifier === 'order_position.status.locked') {
        statusIcon = 'lock';
    }

    return `<span style="white-space: nowrap;"><i class="fas fa-${statusIcon}"></i> ${status.value}</span>`;
}

export function renderFreeAmounts(freeAmounts, type) {
    if (type !== 'display' || freeAmounts === null) {
        return freeAmounts;
    }

    let tooltip = '';
    let totalFreeAmount = 0;

    freeAmounts.forEach(function (freeAmount) {
        tooltip += `${freeAmount.amount} &times; ${freeAmount.reason}<br>`;

        if (freeAmount.comment !== '') {
            tooltip += `<em>${freeAmount.comment}</em><br>`;
        }

        totalFreeAmount += freeAmount.amount;
    });

    if (totalFreeAmount === 0) {
        return totalFreeAmount;
    }

    return `${totalFreeAmount} <i class="fas fa-info-circle text-info h-cursor-pointer" data-bs-toggle="popover" data-bs-placement="bottom" data-content="${tooltip}"></i>`;
}

export function renderCheckbox(identifier, type) {
    if (type !== 'display' || identifier === null) {
        return identifier;
    }

    return `<input type="checkbox" class="js-multiselect-checkbox m-l-5" value="${identifier}">`;
}

export function renderDateTime(datetime, type) {
    if (type !== 'display' || new Date(Date.parse(datetime)) === null) {
        return new Date(Date.parse(datetime));
    }

    return new Date(Date.parse(datetime)).toLocaleString('de-DE', {
        dateStyle: 'medium',
        timeStyle: 'medium',
    });
}

export function renderDate(datetime, type) {
    if (type !== 'display' || new Date(Date.parse(datetime)) === null) {
        return new Date(Date.parse(datetime));
    }

    return new Date(Date.parse(datetime)).toLocaleDateString('de-DE', {
        dateStyle: 'medium',
    });
}

export function isTestTransaction(isTest, type) {
    if (type !== 'display' || isTest === null) {
        return '';
    }

    return isTest ?
        '<div class="text-center"><i class="far fa-check-square h-text-lightgray"></i></div>' :
        '<div class="text-center"><i class="far fa-square h-text-lightgray"></i></div>';
}

// contract -> order positions dt -> render order id to link to detail page
export function renderOrderId(order) {
    return `<a href="${order.link}">${order.id}</a>`;
}

export function renderOrderPositionId(orderPosition) {
    return `<a href="${orderPosition.link}">${orderPosition.id}</a>`;
}
