/**
 * About:
 * This react component manages the action button, modal, and further
 * action handling for the sg datatable.
 */

import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal/lib';
import axios from 'axios';
import LoaderAnimation from '../assets/ajax-loader.gif';
import Search from '../../../../ContractBundle/js/Search/Search';

import Step2LoadingState from './Step2LoadingState';

const Translator = window.Translator;
const propTypes = {
    actions: PropTypes.array.isRequired,
    indexDbRowSelectStore: PropTypes.object.isRequired,
    datatableName: PropTypes.string.isRequired,
};

class ActionHandler extends React.Component {
    constructor(props) {
        super(props);
        /*
         * State data we like to use.
         */
        this.state = {
            actions: props.actions,
            modalIsOpen: false,
            step: 1,

            currentActionType: '',
            currentActionTitle: '',
            currentActionIcon: '',
            currentActionValidateUrl: '',
            currentActionProceedUrl: '',
            currentActionValidateUrlResponse: {},

            checkedIdsForDom: [],
            checkedIdsWithStateValidCount: 0,
            checkedIdsWithStateInvalidCount: 0,
            checkedIdsWithStateRebookingDateReachedCount: 0,
            checkedIdsForActionProceedCount: 0,
            checkedIdsCountFail: 0,
            checkedIdsWithStateValid: [],
            checkedIdsWithStateInvalid: [],
            checkedIdsWithStateRebookingDateReached: [],
            checkedIdsForActionProceed: [],

            step1SelectValue: 0,
            step1CheckboxValue: 0,
            step1AjaxState: 'waiting',
            step2AjaxState: 'waiting',
            step1AjaxResponseSuccessIds: [],
            manualAddedIds: [],

            itemDetails: {},

            iFrameHeight: 0,

            search: {
                positionAmount: 1,
                positionIsTarget: 1,
                searchRequestUrl: '/#',
                positionVariantName: ' ',
            },
        };

        this.idFormInputIds = 'form-input-ids';
        this.nameIFrame = 'iframe-bulk';

        /**
         * Copy of the initial state to make a reset of the comp easy
         */
        this.stateResetValues = {
            ...this.state
        };

        /**
         * React Dom references
         */
        this.iFrame = React.createRef();
        this.iFrameForm = React.createRef();

        /**
         * Handlers
         */
        this.handleActionClick = this.handleActionClick.bind(this);
        this.handleIframeOnload = this.handleIframeOnload.bind(this);
        this.handleOpenModal = this.handleOpenModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.handleStep1Submit = this.handleStep1Submit.bind(this);
        this.handleStep1Select = this.handleStep1Select.bind(this);
        this.handleStep1Checkbox = this.handleStep1Checkbox.bind(this);
        this.handleAddItemToProceedList = this.handleAddItemToProceedList.bind(this);
        this.handleUseSearch = this.handleUseSearch.bind(this);
        this.handleSkipProductionReleaseStep1 = this.handleSkipProductionReleaseStep1.bind(this);
        this.rebookActionCallBack = this.rebookActionCallBack.bind(this);

        /**
         * Third party modal comp setup stuff
         */
        Modal.setAppElement('#app');
        Modal.defaultStyles.overlay.backgroundColor = 'rgba(0, 0, 0, 0.75)';
        this.stylesCustomForModal = {
            content: {
                transition: 'width 250ms, height 250ms',
                width: '900px',
                height: '600px',
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)'
            },
            close: {
                position: 'absolute',
                right: '15px',
                top: '15px',
                zIndex: '3'
            }
        };
    }

    /**
     * Sets the current action to the state and than starts all the magic to validate the action.
     * @param {Object} action - json obj
     */
    handleActionClick(action) {
        this.setState({
            currentActionType: action.actionType,
            currentActionIcon: action.actionIcon ? action.actionIcon : 'fas fa-circle',
            currentActionTitle: action.actionTitle,
            currentActionValidateUrl: action.actionValidateUrl,
            currentActionProceedUrl: action.actionProceedUrl
        }, this.checkIds);
        this.handleOpenModal();
    }

    /**
     * Let's check the ids to validate the action. Therefore we send the
     * current selection from the index DB To our friend the mighty backend <3.
     */
    checkIds() {
        const indexDbStore = this.props.indexDbRowSelectStore;

        indexDbStore.toArray((currentIndexDBStore) => {
            const ajaxPayload = {};
            ajaxPayload.rowsSelected = [];
            currentIndexDBStore.forEach((currentIndexDBStoreItem) => {
                if (currentIndexDBStoreItem.rowSelected === true) {
                    ajaxPayload.rowsSelected.push(currentIndexDBStoreItem.rowId);
                }
            });


            axios.post(this.state.currentActionValidateUrl, ajaxPayload).then((response) => {
                this.setState({
                    checkedIdsForDom: response.data
                });


                // Action Production Release
                if (this.state.currentActionType === 'contract_bulk_production_release') {
                    this.prepareIdsForProductionRelease(response.data);

                    // Other Actions
                } else {
                    const checkedIdsWithStateInvalid = [];
                    const checkedIdsForActionProceed = [];
                    let checkedIdsForActionProceedCount = 0;
                    let checkedIdsWithStateInvalidCount = 0;

                    response.data.forEach((checkedId) => {
                        if (checkedId.actionIsValid === true) {
                            checkedIdsForActionProceed.push(checkedId.id);
                        } else if (checkedId.actionIsValid === false) {
                            checkedIdsWithStateInvalid.push(checkedId.id);
                        }

                        checkedIdsForActionProceedCount = checkedIdsForActionProceed.length;
                        checkedIdsWithStateInvalidCount = checkedIdsWithStateInvalid.length;
                    });

                    this.setState(
                        {
                            checkedIdsForActionProceed,
                            checkedIdsForActionProceedCount,
                            checkedIdsWithStateInvalid,
                            checkedIdsWithStateInvalidCount
                        },
                        () => {
                            if (this.actionConfig.step1 === 'iFrame' && this.state.checkedIdsForActionProceedCount) {
                                this.iFrameForm.current.submit();
                            }
                        }
                    );
                }
            }).catch((error) => {
                console.error(error);
            });
        });
    }

    handleStep1Submit() {
        const bodyFormData = new FormData();
        this.state.checkedIdsForActionProceed.forEach((id) => {
            bodyFormData.append('ids[]', id);
        });
        bodyFormData.append('position_release[priority]', this.state.step1SelectValue);
        bodyFormData.append('position_release[rebookingPosition]', this.state.step1CheckboxValue);
        this.setState({
            step1AjaxState: 'loading'
        });
        axios({
            method: 'post',
            url: this.state.currentActionProceedUrl,
            data: bodyFormData,
            headers: {
                'X-Requested-With': 'XMLHttpRequest'
            },
            config: {
                headers: {
                    'Content-Type': 'form-data'
                }
            }
        }).then((response) => {
            // handle success
            if (response.data.success === true) {
                this.prepareIdsForSearch(response.data);
            } else {
                this.setState({
                    step1AjaxState: 'error'
                });
            }
        }).catch((response) => {
            // handle error
            this.setState({
                step1AjaxState: 'error'
            });
            console.error(response);
        });
    }

    handleUseSearch(detailsUrl, checkId) {
        this.setState({
            step2AjaxState: 'loading'
        });

        axios({
            url: detailsUrl,
            method: 'post',
            headers: {
                'X-Requested-With': 'XMLHttpRequest'
            }
        }).then((response) => {
            this.setState({
                search: {
                    positionAmount: response.data.amount,
                    positionIsTarget: 1,
                    searchRequestUrl: response.data.searchUrl,
                    positionVariantName: response.data.productVariant.name,
                    shippingPrintAddress: response.data.shippingPrintAddress,
                    orderer: response.data.orderer,
                    priority: response.data.priority,
                    transferConfiguration: response.data.transferConfiguration,
                    currentId: checkId,
                },
                step2AjaxState: 'success'
            });
        }).catch((response) => {
            // handle error
            this.setState({
                step2AjaxState: 'error'
            });
            console.error(response);
        });
    }

    prepareIdsForProductionRelease(checkedIds) {
        const checkedIdsWithStateValid = [];
        const checkedIdsWithStateInvalid = [];
        const checkedIdsWithStateRebookingDateReached = [];
        let checkedIdsForActionProceed = [];
        checkedIds.forEach((checkedId) => {
            // Invalid
            if (checkedId.actionIsValid === false) {
                checkedIdsWithStateInvalid.push(checkedId.id);
                // Valid and manual addable
            } else if (checkedId.actionIsValid === true && checkedId.data.rebookingDateReached === true) {
                checkedIdsWithStateRebookingDateReached.push(checkedId.id);
                // Valid for automatic process
            } else if (checkedId.actionIsValid === true && checkedId.data.rebookingDateReached === false) {
                checkedIdsWithStateValid.push(checkedId.id);
            }
            checkedIdsForActionProceed = [...checkedIdsWithStateValid];

            const checkedIdsWithStateValidCount = checkedIdsWithStateValid.length;
            const checkedIdsWithStateInvalidCount = checkedIdsWithStateInvalid.length;
            const checkedIdsWithStateRebookingDateReachedCount = checkedIdsWithStateRebookingDateReached.length;
            const checkedIdsForActionProceedCount = checkedIdsForActionProceed.length + checkedIdsWithStateRebookingDateReached.length;

            this.setState(
                {
                    checkedIdsWithStateValid,
                    checkedIdsWithStateValidCount,
                    checkedIdsWithStateInvalid,
                    checkedIdsWithStateInvalidCount,
                    checkedIdsWithStateRebookingDateReached,
                    checkedIdsWithStateRebookingDateReachedCount,
                    checkedIdsForActionProceed,
                    checkedIdsForActionProceedCount,
                }
            );
        });
    }

    prepareIdsForSearch(items) {
        const step1AjaxResponseSuccessIds = [];

        items.data.map((item) => {
            if (item.success === true) {
                step1AjaxResponseSuccessIds.push(item.id);
            }
        });

        const idsToFilter = [...this.state.checkedIdsForActionProceed];
        const filter = step1AjaxResponseSuccessIds;
        const filteredIds = idsToFilter.filter(id => filter.includes(id));
        const filteredAndInvalidIds = [...filteredIds, ...this.state.checkedIdsWithStateInvalid];

        const checkedIdsForDom = [];
        this.state.checkedIdsForDom.map((item) => {
            if (filteredAndInvalidIds.includes(item.id) === false) {
                checkedIdsForDom.push(item);
            }
        });

        this.setState({
            step1AjaxState: 'success',
            step1AjaxResponseSuccessIds,
            checkedIdsForDom,
            step: 2
        });
    }

    handleSkipProductionReleaseStep1() {
        const currentCheckedIdsForDom = [...this.state.checkedIdsForDom];
        const newCheckedIdsForDom = currentCheckedIdsForDom.filter(item => item.actionIsValid === true && item.data.rebookingDateReached === true);
        this.setState({
            checkedIdsForDom: newCheckedIdsForDom,
            step: 2
        });
    }

    handleStep1Select(event) {
        this.setState({
            step1SelectValue: event.target.value
        });
    }

    handleStep1Checkbox(event) {
        this.setState({
            step1CheckboxValue: event.target.checked === true ? 1 : 0
        });
    }

    /**
     * Boring, just opens a modal.
     */
    handleOpenModal() {
        this.setState({
            modalIsOpen: true
        });
    }

    /**
     * When closing the modal ALL state values get resettet to prepare the action handler for a fresh start.
     */
    handleCloseModal() {
        this.setState(this.stateResetValues);
        this.stylesCustomForModal.content.width = '900px';
        this.stylesCustomForModal.content.height = '600px';
        window.jQuery(`#${this.props.datatableName.replace(/_wrapper/g, '')}`).DataTable().clearPipeline().draw('full-hold');
    }

    /**
     * Some stuff to set the iframe height to the content of the iframe with some extra space.
     */
    handleIframeOnload() {
        const iframeDocument = this.iFrame.current.contentWindow.document;

        const contentScrollHeight = iframeDocument.body.scrollHeight + 140;
        this.setState({
            iFrameHeight: `${contentScrollHeight}px`
        });
    }

    handleAddItemToProceedList(idToAdd) {
        const checkedIdsForActionProceed = [...this.state.checkedIdsForActionProceed];
        checkedIdsForActionProceed.push(idToAdd);

        const manualAddedIds = [...this.state.manualAddedIds];
        manualAddedIds.push(idToAdd);
        this.setState({
            checkedIdsForActionProceed, manualAddedIds
        });
    }

    handleRemoveItemFromProceedList(idToRemove) {
        let checkedIdsForActionProceed = [...this.state.checkedIdsForActionProceed];
        checkedIdsForActionProceed = checkedIdsForActionProceed.filter(id => id !== idToRemove);

        let manualAddedIds = [...this.state.manualAddedIds];
        manualAddedIds = manualAddedIds.filter(id => id !== idToRemove);
        this.setState({
            checkedIdsForActionProceed, manualAddedIds
        });
    }

    rebookActionCallBack() {
        const currentId = this.state.search.currentId;

        const currentCheckedIdsForDom = [...this.state.checkedIdsForDom];
        const newCheckedIdsForDom = currentCheckedIdsForDom.filter(item => item.id !== currentId);
        this.setState({
            checkedIdsForDom: newCheckedIdsForDom
        });

        // Timeout is used here not for tech. reasons, but to give the user a little time to grasp
        // the UI feedback of the container method which uses this callback.
        setTimeout(() => {
            this.setState({
                step2AjaxState: 'waiting'
            });
        }, 250);
    }

    /**
     * JSX Output comes here
     */
    render() {
        /**
         * Todo Action config parser schreiben
         */
        if (this.state.currentActionType === 'contract_bulk_production_release') {
            this.actionConfig = {
                step1: 'ajax',
                manualAddableItem: true,
            };
        } else {
            this.actionConfig = {
                step1: 'iFrame',
                manualAddableItem: false,
            };
        }

        if (this.state.currentActionType === 'contract_bulk_production_release' && this.state.step === 2) {
            this.stylesCustomForModal.content.width = '90%';
            this.stylesCustomForModal.content.height = '90%';
        }


        const idInputs = [];
        if (this.actionConfig.step1 === 'iFrame') {
            this.state.checkedIdsForActionProceed.forEach((id) => {
                idInputs.push(
                    <input key={id} type="hidden" name="ids[]" value={id} />
                );
            });
        }

        const actionList = [];
        const checkedIdsList = [];

        this.state.actions.forEach((action) => {
            actionList.push(
                <a
                    key={action.actionTitle}
                    className="dropdown-item h-cursor-pointer p-2 text-primary"
                    href="#"
                    onClick={() => this.handleActionClick(action)}
                >
                    <span className="icon-set" style={{ width: '22px' }}>
                        <i className={action.actionIcon ? action.actionIcon : 'fas fa-circle'} />
                    </span> {action.actionTitle}
                </a>
            );
        });


        this.state.checkedIdsForDom.forEach((checkedId) => {
            let actionReason = null;
            if (checkedId.actionIsValid === false && checkedId.actionReason) {
                actionReason = (
                    <p style={{ lineHeight: 1.2, margin: 0 }}>
                        <small className="text-danger" style={{ lineHeight: 0 }}>{checkedId.actionReason}</small>
                    </p>
                );
            }

            let actionIcon = '';
            if (this.state.currentActionType === 'contract_bulk_production_release') {
                actionIcon = <i className="fas fa-check-square text-success" />;
                if (checkedId.actionIsValid === false) {
                    actionIcon = <i className="fas fa-exclamation-triangle text-danger" />;
                } else if (checkedId.actionIsValid === true && checkedId.data.rebookingDateReached === true && !this.state.manualAddedIds.includes(checkedId.id)) {
                    actionIcon = <i className="fas fa-check-square text-warning" />;
                }
            } else {
                actionIcon = <i className="fas fa-check-square text-success" />;
                if (checkedId.actionIsValid === false) {
                    actionIcon = <i className="fas fa-exclamation-triangle text-danger" />;
                }
            }

            let title = `${checkedId.title} ${checkedId.id}`;
            if (checkedId.useOrderPositionIdInTitle === true) {
                title = `${checkedId.title} ${checkedId.orderPositionId}`;
            }

            checkedIdsList.push(
                <div key={checkedId.id} className="s">
                    <div className="row rounded border m-l-0 m-r-0 p-t-10 p-b-10 bg-light m-b-10">
                        <div className="col-sm-9">
                            <a href={checkedId.url} target="_blank" rel="noopener noreferrer">
                                {title}
                            </a>
                            {this.state.currentActionType === 'contract_bulk_production_release' &&
                            <div>
                                {/* Production Release Step 1 */}
                                {this.state.step === 1
                                    ?
                                    <div>
                                        {checkedId.actionIsValid === true && checkedId.data.rebookingDateReached === true &&
                                        <div className="m-t-5">
                                            {this.state.manualAddedIds.includes(checkedId.id) === true
                                                ?
                                                <button
                                                    className="btn btn-primary btn-sm h-disable-focus"
                                                    style={{ lineHeight: '1.2' }}
                                                    onClick={() => this.handleRemoveItemFromProceedList(checkedId.id)}
                                                >
                                                    <i
                                                        className="fas fa-minus-circle"
                                                        style={{ fontSize: '10px' }}
                                                    /> {Translator.trans('multiactions.action_handler.remove_entry')}
                                                </button>
                                                :
                                                <button
                                                    className="btn btn-outline-primary btn-sm h-disable-focus"
                                                    style={{ lineHeight: '1.2' }}
                                                    onClick={() => this.handleAddItemToProceedList(checkedId.id)}
                                                >
                                                    <i
                                                        className="fas fa-plus-circle"
                                                        style={{ fontSize: '10px' }}
                                                    /> {Translator.trans('multiactions.action_handler.add_entry')}
                                                </button>
                                            }
                                        </div>
                                        }
                                    </div>
                                    :
                                    <div>
                                        {/* Production Release Step 2 */}
                                        <div className="m-t-5">
                                            <button
                                                className="btn btn-primary btn-sm h-disable-focus"
                                                style={{ lineHeight: '1.2' }}
                                                onClick={() => this.handleUseSearch(checkedId.url, checkedId.id)}
                                            >
                                                <i
                                                    className="fas fa-code-branch"
                                                    style={{ fontSize: '10px' }}
                                                /> {Translator.trans('multiactions.action_handler.choose_entry')}
                                            </button>
                                        </div>
                                    </div>
                                }
                            </div>
                            }
                            {actionReason}
                        </div>
                        <div className="col text-end">
                            {actionIcon}
                        </div>
                    </div>
                </div>
            );
        });

        return (
            <div>
                <Modal
                    isOpen={this.state.modalIsOpen}
                    onRequestClose={this.handleCloseModal}
                    style={this.stylesCustomForModal}
                    contentLabel="Actions"
                >
                    <button
                        onClick={this.handleCloseModal}
                        style={this.stylesCustomForModal.close}
                        className="btn btn-outline-primary"
                    >
                        <i className="fas fa-times" />
                    </button>

                    <div className="row border-bottom m-b-15">
                        <div className="col">
                            <div className="h4 text-center m-b-30 m-t-15">
                                <span className="h4 fw-bold">
                                    {Translator.trans('multiactions.action_handler.current_action')}
                                </span> <i className={this.state.currentActionIcon} /> {this.state.currentActionTitle}
                            </div>
                        </div>
                    </div>

                    <div className="row" style={{ height: 'calc(100% - 90px)' }}>
                        {this.state.checkedIdsForActionProceedCount > 0 &&
                        <div
                            className="col"
                            style={{ overflowY: 'auto', overflowX: 'hidden', height: '100%', maxWidth: '280px' }}
                        >
                            {(this.state.checkedIdsForActionProceedCount > 0 || this.state.checkedIdsWithStateInvalidCount > 0) &&
                            <div>
                                <div className="p-b-30">
                                    {checkedIdsList}
                                </div>
                            </div>
                            }
                        </div>
                        }
                        <div className="col">
                            {(this.state.checkedIdsForActionProceedCount === 0 && this.state.checkedIdsWithStateInvalidCount === 0) &&
                            <div>
                                <div className="alert bg-white alert-info h5 text-center">
                                    <div> {Translator.trans('multiactions.action_handler.check_action_request')} </div>
                                    <img
                                        src={LoaderAnimation}
                                        alt={Translator.trans('multiactions.action_handler.check_action_request')}
                                    />
                                </div>
                            </div>
                            }

                            {(this.state.checkedIdsForActionProceedCount > 0 || this.state.checkedIdsWithStateInvalidCount > 0) && this.state.step === 1 &&
                            <div className="alert bg-white alert-info h5 m-b-10">
                                {(this.state.checkedIdsForActionProceedCount > 0 || this.state.checkedIdsWithStateInvalidCount > 0) &&
                                <div className="h5">
                                    <p>
                                        {Translator.trans('multiactions.action_handler.check_action_request_result', {
                                            countValid: this.state.checkedIdsForActionProceedCount,
                                            countInvalid: this.state.checkedIdsWithStateInvalidCount
                                        })}
                                    </p>
                                </div>
                                }
                                {this.actionConfig.step1 === 'iFrame' && this.state.checkedIdsForActionProceedCount > 0 &&
                                <p className="m-b-0">
                                    {this.state.iFrameHeight === 0 ? Translator.trans('multiactions.action_handler.action_is_loading') : Translator.trans('multiactions.action_handler.proceed_with_action')}
                                    {this.state.iFrameHeight === 0 &&
                                    <img
                                        className="d-block m-t-10"
                                        src={LoaderAnimation}
                                        alt={Translator.trans('multiactions.action_handler.action_is_loading')}
                                    />}
                                </p>
                                }
                            </div>
                            }

                            {this.state.checkedIdsForActionProceedCount > 0 && this.state.step === 1 &&
                            <div>
                                {this.actionConfig.step1 === 'iFrame' &&
                                <div>
                                    <form
                                        ref={this.iFrameForm}
                                        action={this.state.currentActionProceedUrl}
                                        method="POST"
                                        target={this.nameIFrame}
                                    >
                                        {idInputs}
                                    </form>
                                    <iframe
                                        style={{ overflow: 'visible' }}
                                        onLoad={this.handleIframeOnload}
                                        scrolling="no"
                                        frameBorder="0"
                                        width="100%"
                                        name={this.nameIFrame}
                                        height={this.state.iFrameHeight}
                                        ref={this.iFrame}
                                        title={this.state.currentActionTitle}
                                    />
                                </div>
                                }

                                {this.actionConfig.step1 === 'ajax' &&
                                <form>
                                    <div className="form-group row">
                                        <label
                                            htmlFor="priority"
                                            className="col-sm-3 col-form-label"
                                        >
                                            {Translator.trans('multiactions.action_handler.priority')}
                                        </label>
                                        <div className="col">
                                            <select
                                                onChange={this.handleStep1Select}
                                                name="priority"
                                                className="form-control-plaintext custom-select"
                                            >
                                                <option
                                                    defaultValue
                                                    value={0}
                                                >
                                                    {Translator.trans('multiactions.action_handler.regular')}
                                                </option>
                                                <option
                                                    value={20}
                                                >
                                                    {Translator.trans('multiactions.action_handler.important')}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label
                                            htmlFor="rebookPosition"
                                            className="col-sm-3 col-form-label"
                                        >
                                            {Translator.trans('multiactions.action_handler.rebook_position')}
                                        </label>
                                        <div className="col">
                                            <div className="form-control border-0">
                                                <input
                                                    onChange={this.handleStep1Checkbox}
                                                    type="checkbox"
                                                    name="rebookPosition"
                                                    placeholder="Password"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <hr />

                                    <div className="text-end">
                                        <button
                                            disabled={this.state.checkedIdsForActionProceed.length === 0 || this.state.step1AjaxState === 'loading'}
                                            onClick={this.handleStep1Submit}
                                            type="button"
                                            className="btn btn-primary m-b-10"
                                        >
                                            {Translator.trans('multiactions.action_handler.save_and_proceed')}
                                        </button>
                                        <br />
                                        {this.state.checkedIdsWithStateValid.length === 0 && this.state.checkedIdsWithStateRebookingDateReached.length > 0 &&
                                        <button
                                            disabled={this.state.manualAddedIds.length > 0 || this.state.step1AjaxState === 'loading'}
                                            onClick={this.handleSkipProductionReleaseStep1}
                                            type="button"
                                            className="btn btn-primary btn-sm"
                                        >
                                            {Translator.trans('multiactions.action_handler.skip_and_proceed')}
                                        </button>
                                        }
                                    </div>

                                    {this.state.step1AjaxState === 'loading' &&
                                    <img src={LoaderAnimation} alt="Loading" />}

                                    {this.state.step1AjaxState === 'success' &&
                                    <i className="fa fa-check-circle text-success" />}

                                    {this.state.step1AjaxState === 'error' &&
                                    <i className="fas fa-exclamation-triangle text-danger" />}
                                </form>
                                }
                            </div>
                            }

                            {this.state.currentActionType === 'contract_bulk_production_release' && this.state.checkedIdsForDom.length > 0 && this.state.step === 2 &&
                            <div>
                                <div>
                                    {this.state.itemDetails.title}
                                </div>
                                {this.state.step2AjaxState === 'waiting' &&
                                <div className="alert bg-white alert-success h5 m-b-10">
                                    <p className="m-b-0">
                                        {Translator.trans('multiactions.action_handler.please_choose_entry')}
                                    </p>
                                </div>
                                }

                                <Step2LoadingState state={this.state.step2AjaxState} />

                                {this.state.step2AjaxState === 'success' && this.state.checkedIdsForDom.length > 0 &&
                                <div>
                                    <div className="row m-b-10 m-l-0 m-r-0 alert bg-white alert-info  p-3">
                                        {typeof this.state.search.orderer !== 'undefined' && this.state.search.orderer !== null &&
                                        <div className="col ps-1">
                                            <div className="h5 fw-bold">
                                                {Translator.trans('multiactions.action_handler.orderer')}
                                            </div>
                                            <div>
                                                {this.state.search.orderer.firstName ? this.state.search.orderer.firstName : '-'} {this.state.search.orderer.lastName ? this.state.search.orderer.lastName : '-'}
                                            </div>
                                        </div>
                                        }

                                        {typeof this.state.search.shippingPrintAddress !== 'undefined' && this.state.search.shippingPrintAddress !== null &&
                                        <div className="col ps-1">
                                            <div className="h5 fw-bold">
                                                {Translator.trans('multiactions.action_handler.shipping_to')}
                                            </div>
                                            <div>
                                                {this.state.search.shippingPrintAddress.company1 &&
                                                <div> {this.state.search.shippingPrintAddress.company1} </div>}
                                                {this.state.search.shippingPrintAddress.company2 &&
                                                <div> {this.state.search.shippingPrintAddress.company2} </div>}
                                                {this.state.search.shippingPrintAddress.company3 &&
                                                <div> {this.state.search.shippingPrintAddress.company3} </div>}
                                                {this.state.search.shippingPrintAddress.company4 &&
                                                <div> {this.state.search.shippingPrintAddress.company4} </div>}
                                                {this.state.search.shippingPrintAddress.company5 &&
                                                <div> {this.state.search.shippingPrintAddress.company5} </div>}

                                                <div>
                                                    {this.state.search.shippingPrintAddress.street &&
                                                    <span> {this.state.search.shippingPrintAddress.street} </span>}
                                                    {this.state.search.shippingPrintAddress.houseNo &&
                                                    <span> {this.state.search.shippingPrintAddress.houseNo} </span>}
                                                </div>
                                                <div>
                                                    {this.state.search.shippingPrintAddress.postcode &&
                                                    <span> {this.state.search.shippingPrintAddress.postcode} </span>}
                                                    {this.state.search.shippingPrintAddress.city &&
                                                    <span> {this.state.search.shippingPrintAddress.city} </span>}
                                                </div>

                                                {this.state.search.shippingPrintAddress.country &&
                                                <div> {this.state.search.shippingPrintAddress.country} </div>}
                                            </div>
                                        </div>
                                        }

                                        {typeof this.state.search.transferConfiguration !== 'undefined' && this.state.search.transferConfiguration !== null &&
                                        <div className="col ps-1">
                                            <div className="h5 fw-bold">
                                                {Translator.trans('multiactions.action_handler.print_provider')}
                                            </div>
                                            <div>
                                                {this.state.search.transferConfiguration.name ? this.state.search.transferConfiguration.name : '-'}
                                            </div>
                                        </div>
                                        }

                                        <div className="col ps-1">
                                            <div className="h5 fw-bold">
                                                {Translator.trans('multiactions.action_handler.priority')}
                                            </div>
                                            <div>
                                                {this.state.search.priority === 0 &&
                                                <span> {Translator.trans('multiactions.action_handler.regular')} </span>}
                                                {this.state.search.priority === 20 &&
                                                <span> {Translator.trans('multiactions.action_handler.important')} </span>}
                                            </div>
                                        </div>

                                    </div>

                                    <Search
                                        positionAmount={this.state.search.positionAmount}
                                        positionIsTarget={this.state.search.positionIsTarget}
                                        searchRequestUrl={this.state.search.searchRequestUrl}
                                        positionVariantName={this.state.search.positionVariantName}
                                        rebookActionCallBack={this.rebookActionCallBack}
                                    />
                                </div>
                                }
                            </div>
                            }

                            {this.state.currentActionType === 'contract_bulk_production_release' && this.state.checkedIdsForDom.length === 0 && this.state.step === 2 &&
                            <div>
                                <div className="alert bg-white alert-success h5 m-b-10">
                                    <p className="m-b-0">
                                        {Translator.trans('multiactions.action_handler.no_actions_possible')}
                                    </p>
                                </div>
                            </div>
                            }

                        </div>
                    </div>

                </Modal>
                <div className="btn-group dropup" role="group">
                    <button
                        id="btnGroupDrop1-multiaction"
                        type="button"
                        className="btn btn-primary dropdown-toggle"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                    >
                        <i className="fas fa-ellipsis-h" /> {Translator.trans('multiactions.multiactions')}
                    </button>
                    <div className="dropdown-menu" aria-labelledby="btnGroupDrop1-multiaction">
                        {actionList}
                    </div>
                </div>
            </div>
        );
    }
}

/**
 * Let's make shure we get the right prop types.
 */
ActionHandler.propTypes = propTypes;

/**
 * Prepared the class so we can import it in other nice js scripts.
 */
export default ActionHandler;
