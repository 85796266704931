/* eslint-disable react/prop-types */
import React from 'react';
import ReactDOM from 'react-dom';
import 'react-multi-email/dist/style.css';
import ReactMultiEmail from './ReactMultiEmail';

const emailRegex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;

function EmailPills({ inputField }) {
    const continueBtn = document.querySelector('form[name="createReportMailCampaignStep1"] button[type="submit"]');
    const inputFieldArray = inputField.value.split(',');
    const [getEmails, setEmails] = React.useState(
        inputFieldArray.filter(o => o !== '').map(o => o.trim()).length > 0 ? inputFieldArray : []
    );

    const handleContinueBtn = () => {
        if (getEmails.length > 0) {
            continueBtn.disabled = false;
        } else {
            continueBtn.disabled = true;
        }
    };

    React.useEffect(() => {
        inputField.value = getEmails.toString();

        handleContinueBtn();
    });

    React.useEffect(() => {
        handleContinueBtn();
    }, []);

    return (
        <ReactMultiEmail
            emails={getEmails}
            onChange={(_emails) => {
                const cleanMails = _emails.map(m => m.replace('<', '').replace('>', ''));
                setEmails(cleanMails);
            }}
            validateEmail={email => emailRegex.test(email)}
            getLabel={(
                email,
                index,
                removeEmail,
            ) => (
                <div data-tag key={index}>
                    {email}
                    <span data-tag-handle role="button" tabIndex="0" onClick={() => removeEmail(index)}>
                        ×
                    </span>
                </div>
            )
            }
        />

    );
}

document.querySelectorAll('.js-email-pills').forEach((inputField) => {
    const hostEle = window.document.createElement('div');
    inputField.closest('div').appendChild(hostEle);
    ReactDOM.render(<EmailPills inputField={inputField} />, hostEle);
});
