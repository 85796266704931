/* eslint-disable consistent-return */

import dataTablesMainInit from '../../../../LayoutBundle/js/dataTablesMainInit';
import {
    renderButtons,
    renderCheckbox,
    renderDate
} from '../dToutputRenderer';

const $ = window.jQuery;

const Translator = typeof window.Translator !== 'undefined' ? window.Translator : { trans: key => key };
const trans = rootKey => key => Translator.trans(`${rootKey}.${key}`, 'datatable');

const t = trans('order');

$(document).ready(() => {
    const dTsettings = {};

    dTsettings.tableName = 'contract_order_datatable';
    dTsettings.selector = `#${dTsettings.tableName}`;

    if ($(dTsettings.selector).length === 0) return false;

    dTsettings.options = {
        order: [[1, 'desc']],
        orderCellsTop: true,
    };

    dTsettings.extensions = {
        colReorder: {
            fixedColumnsLeft: 1
        },
    };

    dTsettings.columns = {
        columns: [
            {
                title: '<i class="m-l-5 far fa-square"></i>',
                searchable: false,
                orderable: false,
                data: 'id',
                name: 'multi_action_checkbox',
                render: renderCheckbox,
            },
            {
                title: t('title.id'),
                searchable: true,
                orderable: true,
                data: 'id',
                name: 'id',
            },
            {
                title: t('title.created_at'),
                searchable: true,
                orderable: true,
                data: 'created_at',
                name: 'created_at',
                render: renderDate,
            },
            {
                title: t('title.order_position_sources'),
                searchable: true,
                orderable: false,
                data: 'order_position_sources',
                name: 'order_position_sources',
            },
            {
                title: t('title.order_position_count'),
                searchable: true,
                orderable: true,
                data: 'order_position_count',
                name: 'order_position_count',
            },
            {
                searchable: false,
                orderable: false,
                data: 'action_links',
                name: 'action_links',
                render: renderButtons,
            },
        ]
    };

    dTsettings.multiActions = [
        // @todo P2-7015
        // {
        //     actionTitle: 'contract.order.index.action.lock_orders',
        //     actionValidateUrl: 'contract_bulk_lock_orders_validate',
        //     actionProceedUrl: 'contract_bulk_lock_orders_proceed',
        // },
        {
            actionIcon: 'fas fa-forward',
            actionTitle: 'contract.order_positions.table.actions.production_release',
            actionValidateUrl: 'contract_bulk_production_release_on_contract_validate',
            actionProceedUrl: 'contract_bulk_production_release_on_contract',
            actionType: 'contract_bulk_production_release',
        }
    ];

    dataTablesMainInit(dTsettings);
});
