/**
 * Import libraries and dependendies to use in this JS class
 */

const $ = window.jQuery;

export class QuotaCalculation {
    constructor() {
        /**
         * Set the CSS class names for further reference
         */
        this.classQuotaSeasonCollection = '.js-quota-calculation-season-collection';
        this.collectionConfig = {
            allow_duplicate: false,
            allow_up: false,
            allow_down: false,
            custom_add_location: true,
            elements_selector: 'tr.entry',
            elements_parent_selector: '%id% tbody',
            min: 1
        };
    }

    /**
     * Check are classes for code pool available
     */
    init() {
        $(document).ready(() => {
            // check if our js-classes exist in dom. otherwise exit.
            if ($(this.classQuotaSeasonCollection).length > 0) {
                this.addCollection();
            }
        });
    }

    /**
     * Add Collection to QuotaSeason
     */
    addCollection() {
        $(this.classQuotaSeasonCollection).collection(this.collectionConfig);
    }
}

/**
 * Export an instance of this class as default for easy use of this es6 module.
 * If needed one is still able to import the class itself as well, since it's "export"
 * flagged to.
 */
export default new QuotaCalculation();
