import axios from 'axios';
import store from './store/store';
import { dateToAPI } from './components/CardPlanning/dateFormatter';

const Routing = typeof window.Routing !== 'undefined' ? window.Routing : { generate: route => route };

export const subEventDetails = (operation, { event, id = null }) => {
    const state = store.getState();
    const { status, displayMode } = state.actionBar;
    const { name } = state.coreData;
    const { durationType, participationType, timeTables, entranceTimeOffset } = state.planning;
    const { displayName, description } = state.display;
    const { variants } = state.productVariants;

    if (!['get', 'create', 'update', 'delete'].includes(operation)) {
        console.error('Invalide Operation');
        return 'Error - Invalide Operation';
    }

    const mapWording = (o) => {
        if (o === 'create') return 'post';
        if (o === 'update') return 'put';
        return o;
    };

    return axios[mapWording(operation)](
        Routing.generate(
            `sub_event_api_${operation}`,
            {
                event,
                id,
            }
        ), {
            status,
            display_mode: displayMode,
            name,
            duration_type: durationType,
            participation_type: participationType,
            entranceTimeOffset: !isNaN(entranceTimeOffset) || entranceTimeOffset === '' ? parseInt(entranceTimeOffset, 10) : entranceTimeOffset,
            description: description === '<p></p>' ? '' : description,
            display_name: displayName,
            sub_event_variants: variants.map(v => ({
                id: v.id,
                variant_id: v.variant_id,
                gross_amount: v.gross_amount === '' ? 0 : v.gross_amount,
            })),
            sub_event_plannings: timeTables.map(tt => ({
                id: tt.id,
                uuid: tt.uuid,
                enabled: tt.enabled,
                alias: tt.alias ? tt.alias.replace(/\s+/g, ' ').trim() : tt.alias,
                start_date: dateToAPI(tt.date, tt.start, state.timeZone),
                end_date: dateToAPI(tt.date, tt.end, state.timeZone),
                additional_info: tt.additional_info ? tt.additional_info.replace(/\s+/g, ' ').trim() : tt.additional_info,
                quota: tt.quota === '' || tt.quota === ' ' || tt.quota === null ? 0 : parseInt(tt.quota, 10),
                quota_left: tt.quota_left === '' || tt.quota_left === ' ' || tt.quota_left === null ? 0 : parseInt(tt.quota_left, 10),
            })),
        }
    );
};

export const subEventConfig = ({ event }) =>
    axios.get(Routing.generate(
        'sub_event_api_config',
        {
            event,
        }
    ));

export const subEventTableRoute = ({ clientId, event }) =>
    Routing.generate(
        'sub_event_index',
        {
            _cid: clientId,
            _eid: event,
        }
    );

export const errorConsole = {
    response(error) {
        console.info('ERROR - Request made and server responded with error');
        console.error(error.response.data);
        console.error(error.response.status);
        console.error(error.response.headers);
    },
    request(error) {
        console.info('ERROR - The request was made but no response was received');
        console.error(error.request);
    },
    else(error) {
        console.info('ERROR - Something happened in setting up the request that triggered an Error');
        console.error('Error', error.message);
    },
};
