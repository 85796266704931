/**
 *  We use the jQuery instance from the window object here,
 *  because on this instance is the select2 plugin initialize and triggers the change event
 *  to the underline real html select */
const $ = window.jQuery;

export class ShowMessage {
    constructor() {
        /**
         * Set the CSS class names for further reference
         */
        this.idBusinessPartner = '#contract_businessPartnerTab_businessPartnerReplacement';
        this.idSecondaryContactPerson = '#contract_contactPersonTab_secondaryContactPerson';
        this.classChangeInfo = '.js-bpr-changed-info';
    }

    /**
     * Check are id´s of businesspartner field and secondaryContact field available
     */
    init() {
        $(document).ready(() => {
            if ($(this.idBusinessPartner).length > 0 && $(this.idSecondaryContactPerson).length > 0) {
                this.changeAction();
            }
        });
    }

    /**
     * Show InfoMessage after change of value
     */
    changeAction() {
        $(this.idBusinessPartner).on('change', () => {
            $(this.idSecondaryContactPerson).val('').prop('disabled', true).trigger('change');
            $(this.classChangeInfo).show();
        });
    }
}

/**
 * Export an instance of this class as default for easy use of this es6 module.
 * If needed one is still able to import the class itself as well, since it's "export"
 * flagged to.
 */
export default new ShowMessage();
