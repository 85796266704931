$(document).ready(function () {
    const $selector = $(".js-copy");
    $selector.removeClass('disabled');
    $selector.click(function (event) {
        event.preventDefault();
        const $element = $(this);
        copyToClipboard($element.data('content'));
        $element.tooltip("show");
        setTimeout(function(){
            $element.tooltip( 'hide' );
        }, 1500);
    });

});

function copyToClipboard(textToCopy) {
    // navigator clipboard api needs a secure context (https)
    if (navigator.clipboard && window.isSecureContext) {
        // navigator clipboard api method
        return navigator.clipboard.writeText(textToCopy);
    }
    // text area method
    const textArea = document.createElement('textarea');
    textArea.value = textToCopy;
    // make the textarea out of viewport
    textArea.style.position = 'fixed';
    textArea.style.left = '-999999px';
    textArea.style.top = '-999999px';
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    return new Promise((res, rej) => {
        // here the magic happens
        if (document.execCommand('copy')) {
            res();
        } else {
            rej();
        }

        textArea.remove();
    });
}
