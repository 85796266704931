/* eslint-disable consistent-return */

import dataTablesMainInit from '../../../../LayoutBundle/js/dataTablesMainInit';
import {
    renderStatus,
    renderFreeAmounts,
    renderProductVariant,
    renderOrderId,
    renderOrderPositionId,
} from '../dToutputRenderer';

const $ = window.jQuery;

const Translator = typeof window.Translator !== 'undefined' ? window.Translator : { trans: key => key };
const trans = rootKey => key => Translator.trans(`${rootKey}.${key}`, 'datatable');

const t = trans('order_position');

$(document).ready(() => {
    const dTsettings = {};

    dTsettings.tableName = 'contract_order_order_positions_list_datatable';
    dTsettings.selector = `#${dTsettings.tableName}`;

    if ($(dTsettings.selector).length === 0) return false;

    dTsettings.options = {
        order: [[3, 'desc']],
        orderCellsTop: true,
    };

    dTsettings.extensions = {
        colReorder: {},
    };

    dTsettings.columns = {
        columns: [
            {
                title: t('title.id'),
                searchable: true,
                orderable: true,
                data: 'order_position',
                name: 'id',
                render: renderOrderPositionId,
            },
            {
                title: t('title.product_variant'),
                searchable: true,
                orderable: true,
                data: 'product_variant',
                name: 'product_variant',
                render: renderProductVariant,
            },
            {
                title: t('title.amount'),
                searchable: true,
                orderable: true,
                data: 'amount',
                name: 'amount',
            },
            {
                title: t('title.free_amounts'),
                searchable: true,
                orderable: true,
                data: 'free_amounts',
                name: 'free_amounts',
                render: renderFreeAmounts,
            },
            {
                title: t('title.order_id'),
                searchable: true,
                orderable: true,
                data: 'order',
                name: 'order',
                render: renderOrderId,
            },
            {
                title: t('title.status'),
                searchable: true,
                orderable: true,
                data: 'status',
                name: 'status',
                render: renderStatus,
            },
            {
                title: t('title.source'),
                searchable: true,
                orderable: false,
                data: 'source',
                name: 'source',
            },
        ]
    };

    dataTablesMainInit(dTsettings);
});
