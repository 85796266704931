/* eslint-disable consistent-return */

import dataTablesMainInit from '../../../../../LayoutBundle/js/dataTablesMainInit';
import {
    renderTags,
    renderYesNoChoiceSymbol,
    renderStatus,
    renderPrint,
    renderYesNoChoice,
    renderTicketImprints,
    renderFreeAmounts,
    renderButtons,
    renderCheckbox,
    renderProductVariant
} from '../../dToutputRenderer';

const $ = window.jQuery;

const Translator = typeof window.Translator !== 'undefined' ? window.Translator : { trans: key => key };
const trans = rootKey => key => Translator.trans(`${rootKey}.${key}`, 'datatable');

const t = trans('order_position');

$(document).ready(() => {
    const dTsettings = {};

    dTsettings.tableName = 'contract_order_order_position_datatable';
    dTsettings.selector = `#${dTsettings.tableName}`;

    if ($(dTsettings.selector).length === 0) return false;

    dTsettings.options = {
        order: [[2, 'desc']],
        orderCellsTop: true,
    };

    dTsettings.extensions = {
        colReorder: {
            fixedColumnsLeft: 1
        },
    };

    dTsettings.columns = {
        columns: [
            {
                title: '<i class="m-l-5 far fa-square"></i>',
                searchable: false,
                orderable: false,
                data: 'id',
                name: 'multi_action_checkbox',
                render: renderCheckbox,
            },
            {
                title: t('title.tags'),
                searchable: true,
                orderable: false,
                data: 'tags',
                name: 'tags',
                render: renderTags,
            },
            {
                title: t('title.id'),
                searchable: true,
                orderable: true,
                data: 'id',
                name: 'id',
            },
            {
                title: t('title.priority'),
                searchable: true,
                orderable: true,
                data: 'priority',
                name: 'priority',
            },
            {
                title: t('title.rebooking_position'),
                searchable: false,
                orderable: true,
                data: 'rebooking_position',
                name: 'rebooking_position',
                render: renderYesNoChoiceSymbol,
            },
            {
                title: t('title.status'),
                searchable: true,
                orderable: true,
                data: 'status',
                name: 'status',
                render: renderStatus,
            },
            {
                title: t('title.source'),
                searchable: true,
                orderable: false,
                data: 'source',
                name: 'source',
            },
            {
                title: t('title.amount'),
                searchable: true,
                orderable: true,
                data: 'amount',
                name: 'amount',
            },
            {
                title: t('title.free_amounts'),
                searchable: true,
                orderable: true,
                data: 'free_amounts',
                name: 'free_amounts',
                render: renderFreeAmounts,
            },
            {
                title: t('title.product_variant'),
                searchable: true,
                orderable: true,
                data: 'product_variant',
                name: 'product_variant',
                render: renderProductVariant,
            },
            {
                title: t('title.print'),
                searchable: false,
                orderable: true,
                data: 'print',
                name: 'print',
                render: renderPrint,
            },
            {
                title: t('title.ticket_imprints'),
                searchable: true,
                orderable: false,
                data: 'ticket_imprints',
                name: 'ticket_imprints',
                render: renderTicketImprints,
            },
            {
                title: t('title.fair_package'),
                searchable: false,
                orderable: true,
                data: 'fair_package',
                name: 'fair_package',
                render: renderYesNoChoice,
            },
            {
                title: t('title.shipping_type'),
                searchable: true,
                orderable: true,
                data: 'shipping_type',
                name: 'shipping_type',
            },
            {
                searchable: false,
                orderable: false,
                data: 'action_links',
                name: 'action_links',
                render: renderButtons,
            },
        ]
    };

    dTsettings.multiActions = [
        {
            actionIcon: 'fas fa-tags',
            actionTitle: 'contract.order_positions.table.actions.add_tags_to_orders',
            actionValidateUrl: 'contract_bulk_tagorders_validate',
            actionProceedUrl: 'contract_bulk_tagorders_proceed',
        },
        // @todo P2-7015
        // {
        //     actionIcon: 'fas fa-lock',
        //     actionTitle: 'contract.order_positions.table.actions.lock_order_positions',
        //     actionValidateUrl: 'contract_bulk_lock_order_positions_validate',
        //     actionProceedUrl: 'contract_bulk_lock_order_positions_proceed',
        // },
        {
            actionIcon: 'fas fa-lock-open',
            actionTitle: 'contract.order_positions.table.actions.unlock_order_positions',
            actionValidateUrl: 'contract_bulk_unlock_order_positions_validate',
            actionProceedUrl: 'contract_bulk_unlock_order_positions_proceed',
        },
        {
            actionIcon: 'fas fa-ban',
            actionTitle: 'contract.order_positions.table.actions.cancel_order_positions',
            actionValidateUrl: 'contract_bulk_cancel_order_positions_validate',
            actionProceedUrl: 'contract_bulk_cancel_order_positions_proceed',
        },
        {
            actionIcon: 'fas fa-user-edit',
            actionTitle: 'contract.order_positions.table.actions.enable_editing',
            actionValidateUrl: 'contract_bulk_enable_editing_order_positions_validate',
            actionProceedUrl: 'contract_bulk_enable_editing_order_positions_proceed',
        },
        {
            actionIcon: 'fas fa-forward',
            actionTitle: 'contract.order_positions.table.actions.production_release',
            actionValidateUrl: 'contract_bulk_production_release_validate',
            actionProceedUrl: 'contract_bulk_production_release',
            actionType: 'contract_bulk_production_release',
        }
    ];

    dataTablesMainInit(dTsettings);
});
