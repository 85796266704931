import React from 'react';
import PropTypes from 'prop-types';

/**
 * @param string step2AjaxState
 * @returns {XML|null}
 */
const Step2LoadingState = ({ step2AjaxState }) => {
    const Translator = window.Translator;

    if (step2AjaxState !== 'loading') {
        return null;
    }

    return (
        <div>
            <div className="alert bg-white alert-info h5">
                <p>{Translator.trans('multiactions.action_handler.search_is_loading')}</p>
                <img
                    className="d-block"
                    src="../assets/ajax-loader.gif"
                    alt={Translator.trans('multiactions.action_handler.check_action_request')}
                />
            </div>
        </div>
    );
};

Step2LoadingState.propTypes = {
    step2AjaxState: PropTypes.string.isRequired
};

Step2LoadingState.defaultProp = {
    step2AjaxState: ''
};

export default Step2LoadingState;
