/* eslint-disable prefer-arrow-callback */
const Routing = typeof window.Routing !== 'undefined' ? window.Routing : { generate: route => route };

export function renderTransaction(transactionId, type) {
    if (type !== 'display' || transactionId === null) {
        return transactionId;
    }

    const urlParams = new URLSearchParams(window.location.search);
    const cid = urlParams.get('_cid');
    const eid = urlParams.get('_eid');

    const url = Routing.generate('online_shop_transaction_details', {
        id: transactionId,
        _cid: cid,
        _eid: eid
    });

    return `<a href="${url}">${transactionId}</a>`;
}

export function renderDateTime(datetime, type) {
    if (type !== 'display' || new Date(Date.parse(datetime)) === null) {
        return new Date(Date.parse(datetime));
    }

    return new Date(Date.parse(datetime)).toLocaleString('de-DE', {
        dateStyle: 'medium',
        timeStyle: 'medium',
    });
}

export function isTestTransaction(isTest, type) {
    if (type !== 'display' || isTest === null) {
        return '';
    }

    return isTest ?
        '<div class="text-center"><i class="far fa-check-square h-text-lightgray"></i></div>' :
        '<div class="text-center"><i class="far fa-square h-text-lightgray"></i></div>';
}

export function renderCommaSeparatedBreak(val, type) {
    if (type !== 'display' || val === null) {
        return '';
    }
    return val.split(',').map(s => `${s.trim()}`).join('<br>');
}
