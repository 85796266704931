const $ = window.jQuery;
window.syncTableHeaderWidth = () => {
    const selectorHeader = 'table.fixedHeader-floating';
    const selectorTable = '.dataTable';
    const selectorWrapper = '.dataTables_wrapper > .dataTable';
    const selectorExtraScrollbar = '.js-extra-scrollbar';
    const selectorExtraScrollbarSpacer = '.js-extra-scrollbar__spacer';
    const tableWidth = () => $(selectorTable).width() + 15;

    if ($(selectorExtraScrollbar).length === 0 && typeof tableWidth() !== 'undefined') {
        $('.dataTables_length').closest('.row').append(`
            <aside class="js-extra-scrollbar extra-scrollbar" style="overflow-x: auto;">
                <div class="js-extra-scrollbar__spacer" style="width: ${tableWidth()}px; height: 1px;"></div>
            </aside>
        `);
    }

    const addWidth = () => {
        const wrapperWidth = $(selectorWrapper).width();
        $('#fixedHeaderCustomStyles').remove();
        $('head').append(`
        <style id="fixedHeaderCustomStyles">
            ${selectorHeader},
            ${selectorExtraScrollbar}.is-fixed {
                width: ${wrapperWidth}px!important;
            }
        </style>
        `);

        $(selectorExtraScrollbarSpacer).attr('style', `width: ${tableWidth()}px; height: 1px;`);
    };
    addWidth();

    $(window).on('resize', () => {
        addWidth();
    });

    $('body').on('sidebar-width-changed', () => {
        addWidth();
    });

    $(selectorExtraScrollbar).scroll(() => {
        $(selectorWrapper).scrollLeft($(selectorExtraScrollbar).scrollLeft());
    });

    $(selectorWrapper).scroll(() => {
        $(selectorHeader).scrollLeft($(selectorWrapper).scrollLeft());
        $(selectorExtraScrollbar).scrollLeft($(selectorWrapper).scrollLeft());
    });

    $(window).scroll(() => {
        if ($(window).scrollTop() > 186) {
            addWidth();
            $(selectorExtraScrollbar).addClass('is-fixed');
        } else {
            $(selectorExtraScrollbar).removeClass('is-fixed');
        }
    });
};
